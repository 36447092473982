import classNames from 'classnames';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import css from './SpCalendarTypeSelect.module.scss';

type Props = {
  isList: boolean;
  onClickCalendar: () => void;
  onClickList: () => void;
};

const SpCalendarTypeSelect: FC<Props> = ({
  isList,
  onClickCalendar,
  onClickList,
}) => {
  const intl = useIntl();
  return (
    <div className={css.root}>
      <button
        className={classNames(css.button, {
          [css.isSelected]: !isList,
        })}
        disabled={!isList}
        onClick={onClickCalendar}
        type="button"
      >
        {intl.formatMessage({ id: 'merchants.services.calendar' })}
      </button>
      <button
        className={classNames(css.button, {
          [css.isSelected]: isList,
        })}
        disabled={isList}
        onClick={onClickList}
        type="button"
      >
        {intl.formatMessage({ id: 'merchants.services.list' })}
      </button>
    </div>
  );
};

export default SpCalendarTypeSelect;

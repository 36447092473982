interface VacancyStatusInit {
  vacancyCount: number | null | undefined;
  waitingListProvided: boolean;
  full: boolean | null;
  isRegisteredWaitingList: boolean;
}

export enum VacancyStatusEnum {
  WAITING_LIST,
  SOLD_OUT,
  AVAILABLE,
  UNKNOWN,
  REGISTERED_WAITING_LIST,
}

export function localizeKey(e: VacancyStatusEnum): string {
  switch (e) {
    case VacancyStatusEnum.WAITING_LIST:
      return 'waitingList';
    case VacancyStatusEnum.SOLD_OUT:
      return 'soldOut';
    case VacancyStatusEnum.AVAILABLE:
      return 'available';
    case VacancyStatusEnum.UNKNOWN:
      return 'unknown';
    case VacancyStatusEnum.REGISTERED_WAITING_LIST:
      return 'registeredWaitingList';
    default:
      return null as never;
  }
}

export class VacancyStatus {
  public readonly vacancyCount: number | null | undefined;
  public readonly waitingListProvided: boolean;
  public readonly isRegisteredWaitingList: boolean;
  private readonly full: boolean;

  constructor(init: VacancyStatusInit) {
    this.vacancyCount = init.vacancyCount;
    this.waitingListProvided = init.waitingListProvided;
    this.isRegisteredWaitingList = init.isRegisteredWaitingList;
    this.full = init.full ? true : false;
  }

  get vacancyAppearance(): VacancyStatusEnum {
    if (this.isRegisteredWaitingList) {
      return VacancyStatusEnum.REGISTERED_WAITING_LIST;
    }
    if (this.full && this.waitingListProvided) {
      return VacancyStatusEnum.WAITING_LIST;
    }
    if (this.full) {
      return VacancyStatusEnum.SOLD_OUT;
    }
    const count = this.vacancyCount;
    if (!count) {
      return VacancyStatusEnum.UNKNOWN;
    }

    if (count > 0) {
      return VacancyStatusEnum.AVAILABLE;
    } else {
      return VacancyStatusEnum.UNKNOWN;
    }
  }
}

import { MerchantBookingPageApiResponse } from '../../../../../interfaces/merchants/bookings';
import * as IConfirm from '../../../../../interfaces/merchants/bookings/confirm';
import * as ICourse from '../../../../../interfaces/merchants/bookings/courses';
import * as ICustomFields from '../../../../../interfaces/merchants/bookings/custom_fields';
import * as IEvent from '../../../../../interfaces/merchants/bookings/event';
import * as IEvents from '../../../../../interfaces/merchants/bookings/events';
import * as ILesson from '../../../../../interfaces/merchants/bookings/lessons';
import {
  MerchantBookingSelectedStaffApiResponse,
  MerchantBookingStaffApi,
  MerchantBookingStaffApiResponse,
  MerchantBookingStaffImageApi,
} from '../../../../../interfaces/merchants/bookings/staff';
import * as ITimeSlots from '../../../../../interfaces/merchants/bookings/time_slots';
import * as IBooks from '../../../../../interfaces/merchants/resources/books';
import * as IReservation from '../../../../../interfaces/reservations';

const remapOptionsById = (
  list: ICourse.MerchantBookingCourseMetaOption[],
): { [id: string]: IBooks.Option } =>
  list.reduce((acc: { [id: string]: IBooks.Option }, o) => {
    acc[o.id] = {
      id: o.id,
      name: o.name,
      price: o.price,
      duration: o.duration,
      description: o.description,
      sortOrder: o.sort_order,
    };
    return acc;
  }, {});

const toImage = (
  img: MerchantBookingStaffImageApi | ICourse.MerchantBookingCourseImage,
): IBooks.Image => ({
  large: { url: img.large ? img.large.url : undefined },
  square: { url: img.square ? img.square.url : undefined },
  squareFill210210: {
    url: img.square_fill_210_210 ? img.square_fill_210_210.url : undefined,
  },
  thumb: { url: img.thumb ? img.thumb.url : undefined },
  url: img.url,
});

const remapStaffById = (
  list: MerchantBookingStaffApi[],
): { [id: string]: IBooks.RealStaff } =>
  list.reduce((acc, s) => {
    acc[s.canonical_id] = {
      courseIds: s.course_ids,
      description: s.description,
      id: s.canonical_id,
      // URLのクエリで扱う値がDBの生IDのため、reduxで保持するようにする
      query_id: s.id,
      image: toImage(s.image),
      name: s.name,
      workday: s.workday,
    };
    return acc;
  }, {});

export const toSettingStaffList = (
  list: MerchantBookingStaffApi[],
): IBooks.RealStaff[] =>
  list.map((staff) => ({
    courseIds: staff.course_ids,
    description: staff.description,
    email: staff.email,
    id: staff.canonical_id,
    query_id: staff.id,
    image: toImage(staff.image),
    name: staff.name,
    workday: staff.workday,
  }));

export const toSettingSlectedStaff = (
  staff: MerchantBookingStaffApi,
): IBooks.RealStaff => ({
  courseIds: staff.course_ids,
  description: staff.description,
  email: staff.email,
  id: staff.canonical_id,
  query_id: staff.id,
  image: toImage(staff.image),
  name: staff.name,
  workday: staff.workday,
});

const convertState = (state: IEvents.State): IBooks.AvailabilityState => {
  switch (state) {
    case IEvents.State.Available:
      return IBooks.AvailabilityState.Available;
    case IEvents.State.Occupied:
      return IBooks.AvailabilityState.Occupied;
    default:
      throw new Error();
  }
};

const convertAvailability = (
  date: string,
  availability: IEvents.MerchantBookingEventAvailability,
): IBooks.TimeSlotAvailability => ({
  ...availability,
  date,
  state: convertState(availability.state),
});

const toQuestionnaireText = (
  f: ICustomFields.MerchantBookingCustomFieldsQuestionnaire,
): IBooks.QuestionnaireText => ({
  customFieldId: f.id,
  label: f.label,
  required: f.required,
  firstTimeOnly: f.first_time_only,
  type: 'text',
});

const toQuestionnaireTextArea = (
  f: ICustomFields.MerchantBookingCustomFieldsQuestionnaire,
): IBooks.QuestionnaireTextArea => ({
  customFieldId: f.id,
  label: f.label,
  required: f.required,
  firstTimeOnly: f.first_time_only,
  type: 'text_area',
});

const toQuestionnaireSelect = (
  f: ICustomFields.MerchantBookingCustomFieldsQuestionnaire,
): IBooks.QuestionnaireSelect => ({
  customFieldId: f.id,
  label: f.label,
  options: f.options!.map((o) => ({ label: o, value: o })),
  required: f.required,
  firstTimeOnly: f.first_time_only,
  type: 'select',
});

const toQuestionnaireRadio = (
  f: ICustomFields.MerchantBookingCustomFieldsQuestionnaire,
): IBooks.QuestionnaireRadio => ({
  customFieldId: f.id,
  label: f.label,
  options: f.options!.map((o) => ({ label: o, value: o })),
  required: f.required,
  firstTimeOnly: f.first_time_only,
  type: 'radio',
});

const toQuestionnaireCheckbox = (
  f: ICustomFields.MerchantBookingCustomFieldsQuestionnaire,
): IBooks.QuestionnaireCheckbox => ({
  customFieldId: f.id,
  label: f.label,
  options: f.options!.map((o) => ({ label: o, value: o })),
  required: f.required,
  firstTimeOnly: f.first_time_only,
  type: 'checkbox',
});

const CONVERTERS = {
  [ICustomFields.AnswerType.TextField]: toQuestionnaireText,
  [ICustomFields.AnswerType.TextArea]: toQuestionnaireTextArea,
  [ICustomFields.AnswerType.SelectBox]: toQuestionnaireSelect,
  [ICustomFields.AnswerType.RadioButton]: toQuestionnaireRadio,
  [ICustomFields.AnswerType.Checkbox]: toQuestionnaireCheckbox,
};

const ERROR_MAPPING = {
  [IReservation.ErrorType.Blank]: IBooks.ErrorType.Blank,
  [IReservation.ErrorType.Taken]: IBooks.ErrorType.Taken,
  [IReservation.ErrorType.Invalid]: IBooks.ErrorType.Invalid,
  [IReservation.ErrorType.InvalidDomain]: IBooks.ErrorType.InvalidDomain,
};

const toQuestionnaire = (
  f: ICustomFields.MerchantBookingCustomFieldsQuestionnaire,
): IBooks.Questionnaire => {
  const converter = CONVERTERS[f.answer_type];
  if (!converter) {
    throw new Error(
      `cannot handle questionnaire_custom_fields.type: ${f.answer_type}`,
    );
  }
  return converter(f);
};

const toPaymentStep = (resourceData: MerchantBookingPageApiResponse): boolean =>
  resourceData.payment_settings_enabled;

export const toMerchantResource = (
  resourceData: MerchantBookingPageApiResponse,
): IBooks.MerchantResource => ({
  modelType: resourceData.model_type,
  name: resourceData.name,
  nickname: resourceData.nickname,
  status: resourceData.status,
  coverContentsType: resourceData.cover_contents_type,
  primaryImage: resourceData.primary_image,
  coverContent: resourceData.cover_content
    ? {
        url: resourceData.cover_content.url,
        embed: resourceData.cover_content.embed,
        thumbnailUrl: resourceData.cover_content.thumbnail_url,
      }
    : undefined,
  isJsonDescription: resourceData.is_json_description,
  description: resourceData.description,
  descriptionText: resourceData.description_text,
  publicId: resourceData.public_id,
  rate: resourceData.rate,
  rateCount: resourceData.rate_count,
  prepaymentEnabled: resourceData.prepayment_enabled,
  onsitePaymentEnabled: resourceData.onsite_payment_enabled,
  payableWithTicket: resourceData.payable_with_ticket,
  payableWithSubscription: resourceData.payable_with_subscription,
  waitingListProvided: resourceData.waiting_list_provided,
  requireAcceptance: resourceData.require_acceptance,
  staffSelectable: resourceData.staff_selectable,
  paymentStep: toPaymentStep(resourceData),
});

const toSettingBookingTarget = (
  courseData: ICourse.MerchantBookingCourseData,
  selectedCourseData?: ICourse.MerchantBookingSelectedCourseData,
): IBooks.SettingBookingTargets => {
  const options = remapOptionsById(courseData.meta.options);
  return {
    courses: courseData.data.map((c) => ({
      id: c.canonical_id,
      // URLのクエリで扱う値がDBの生IDのため、reduxで保持するようにする
      query_id: c.id,
      name: c.name,
      price: c.price,
      duration: c.duration,
      description: c.description,
      options: c.option_ids
        .map((optionId) => options[optionId])
        .sort((o1, o2) => o1.sortOrder - o2.sortOrder),
      image: toImage(c.image),
      staffIds: c.staff_ids || [],
      acceptRandomAssign: c.accept_random_assign,
    })),
    selectedCourse: selectedCourseData
      ? {
          id: selectedCourseData.data.canonical_id,
          query_id: selectedCourseData.data.id,
          name: selectedCourseData.data.name,
          price: selectedCourseData.data.price,
          duration: selectedCourseData.data.duration,
          description: selectedCourseData.data.description,
          options: selectedCourseData.data.option_ids
            .map((optionId) => options[optionId])
            .sort((o1, o2) => o1.sortOrder - o2.sortOrder),
          image: toImage(selectedCourseData.data.image),
          staffIds: selectedCourseData.data.staff_ids || [],
          acceptRandomAssign: selectedCourseData.data.accept_random_assign,
        }
      : undefined,
  };
};

const toSettingBookingTargetLesson = (
  lessonData: ILesson.MerchantBookingLessonData,
  selectedTimeSlotData?: ITimeSlots.MerchantBookingTimeSlotData,
): IBooks.SettingBookingTargetsLesson => {
  const lessons = lessonData.map((l) => ({
    ...l,
    id: l.canonical_id,
  }));
  const selectedLesson = selectedTimeSlotData
    ? {
        ...selectedTimeSlotData.meta.lesson,
        id: selectedTimeSlotData.meta.lesson.canonical_id,
      }
    : undefined;
  return {
    lessons,
    selectedLesson,
  };
};

export const toSettingUserInfo = (
  customFields: ICustomFields.MerchantBookingCustomFieldsData,
): IBooks.SettingUserInfo => {
  const { survey, resource_customer_fields, questionnaire_custom_fields } =
    customFields;
  const userInfo: IBooks.SettingUserInfo = {};
  if (survey) {
    userInfo.survey = { ...survey };
  }
  if (resource_customer_fields) {
    userInfo.customerFields = {
      fields: resource_customer_fields.fields.map((f) => ({
        defaultValue: f.default_value,
        editable: f.editable,
        name: f.name,
        customLabel: f.custom_label,
        required: f.required,
        validationType: f.validation_type,
      })),
    };
  }
  if (questionnaire_custom_fields) {
    userInfo.questionnaires = questionnaire_custom_fields
      .sort((f1, f2) => f1.order - f2.order)
      .map(toQuestionnaire);
  }
  return userInfo;
};

const attachSurveyError = (
  settings: IBooks.Settings,
  errors?: IReservation.ErrorType[],
): void => {
  if (!errors) {
    return;
  }
  settings.userInfo!.survey!.errors = errors.map((e) => ERROR_MAPPING[e]);
};

const attachResourceCustomerFieldsError = (
  settings: IBooks.Settings,
  errors?: { [name: string]: IBooks.ErrorType[] },
): void => {
  if (!errors) {
    return;
  }
  Object.keys(errors).forEach((k) => {
    const field = settings.userInfo!.customerFields!.fields.find(
      (f) => f.name === k,
    );
    field!.errors = errors[k].map((e) => ERROR_MAPPING[e]);
  });
};

const attachQuestionnaireCustomFieldsError = (
  settings: IBooks.Settings,
  errors?: { [id: string]: IBooks.ErrorType[] },
): void => {
  if (!errors) {
    return;
  }
  Object.keys(errors).forEach((k) => {
    const questionnaire = settings.userInfo!.questionnaires!.find(
      (q) => q.customFieldId.toString() === k,
    );
    questionnaire!.errors = errors[k].map((e) => ERROR_MAPPING[e]);
  });
};

export const toEvents = (
  events: IEvents.MerchantBookingEventsData,
): IBooks.SettingBookingDateTimes['events'] => {
  const dates = Object.keys(events.dates).reduce((acc, date) => {
    const event = events.dates[date];
    const { availabilities } = event;
    acc[date] = {
      availabilities:
        availabilities && event.availabilities
          ? event.availabilities.map((a) => convertAvailability(date, a))
          : [], // 深夜とかになってデータが存在しないとfieldごとないことがある
      class: event.class,
    };
    return acc;
  }, {});
  const checkinTimes = events.checkin_times.map((ct) => ct.value);
  return { checkinTimes, dates };
};

const toSchoolTimeSlot = (
  instructors: { [id: number]: IBooks.Instructor },
  timeSlot: ITimeSlots.MerchantBookingTimeSlot,
): IBooks.SchoolTimeSlot => ({
  id: timeSlot.id,
  lessonId: timeSlot.lesson_id!,
  capacity: timeSlot.capacity,
  vacancy: timeSlot.vacancy,
  accepted: timeSlot.accepted,
  pending: timeSlot.pending,
  instructors: timeSlot.staff_ids.map((id) => instructors[id]),
  date: timeSlot.date,
  startTime: timeSlot.start_time,
  endTime: timeSlot.end_time,
  start: timeSlot.start,
  end: timeSlot.end,
});

export const toEventTimeSlot = (
  timeSlot: ITimeSlots.MerchantBookingTimeSlot,
): IBooks.EventTimeSlot => ({
  id: timeSlot.id,
  vacancy: timeSlot.vacancy,
  accepted: timeSlot.accepted,
  pending: timeSlot.pending,
  date: timeSlot.date,
  startTime: timeSlot.start_time,
  endTime: timeSlot.end_time,
  start: timeSlot.start,
  end: timeSlot.end,
});

export const toSchoolTimeSlots = (
  timeSlots: ITimeSlots.MerchantBookingTimeSlotsData,
): { [date: string]: IBooks.SchoolTimeSlot[] } => {
  // データ構造はstaffと一緒なのでremapするが、ややこしいので以降instructorsとする
  const instructors = remapStaffById(timeSlots.meta.staff!);
  return timeSlots.data.reduce(
    (
      acc: { [date: string]: IBooks.SchoolTimeSlot[] },
      timeSlot: ITimeSlots.MerchantBookingTimeSlot,
    ) => {
      const _timeSlots = acc[timeSlot.date] || [];
      _timeSlots.push(toSchoolTimeSlot(instructors, timeSlot));
      acc[timeSlot.date] = _timeSlots;
      return acc;
    },
    {},
  );
};

export const toEventTimeSlots = (
  timeSlots: ITimeSlots.MerchantBookingTimeSlotsData,
): { [date: string]: IBooks.EventTimeSlot[] } => {
  return timeSlots.data.reduce(
    (
      acc: { [date: string]: IBooks.EventTimeSlot[] },
      timeSlot: ITimeSlots.MerchantBookingTimeSlot,
    ) => {
      const _timeSlots = acc[timeSlot.date] || [];
      _timeSlots.push(toEventTimeSlot(timeSlot));
      acc[timeSlot.date] = _timeSlots;
      return acc;
    },
    {},
  );
};

const toSettingBookingDateTimePicker = (
  eventData: IEvent.MerchantBookingEventData,
  selectedTimeSlotData?: ITimeSlots.MerchantBookingTimeSlotData,
): IBooks.SettingBookingDateTimePicker => {
  const selectedTimeSlot =
    selectedTimeSlotData &&
    !selectedTimeSlotData.meta.reserved &&
    selectedTimeSlotData.data.vacancy > 0
      ? toSchoolTimeSlot(
          remapStaffById(selectedTimeSlotData.meta.staff!),
          selectedTimeSlotData.data,
        )
      : undefined;
  const selectedLessonId = selectedTimeSlotData
    ? selectedTimeSlotData.meta.lesson.canonical_id
    : undefined;
  return {
    selectedTimeSlot,
    selectedLessonId,
    showVacancy: eventData.show_time_slot_vacancy,
    timeSlots: {},
  };
};

const toCreditCardError = (e: stripe.StripeError): IBooks.CreditCardError => {
  switch (e.code) {
    case 'invalid_number':
      return { type: 'invalid_number', field: 'number' };
    case 'invalid_expiry_month':
      return { type: 'invalid_expiry_month', field: 'month' };
    case 'invalid_expiry_year':
      return { type: 'invalid_expiry_year', field: 'year' };
    case 'invalid_cvc':
      return { type: 'invalid_cvc', field: 'cvc' };
    case 'incorrect_number':
      return { type: 'incorrect_number', field: 'number' };
    case 'expired_card':
      return { type: 'expired_card', field: 'exp' };
    case 'incorrect_cvc':
      return { type: 'incorrect_cvc', field: 'cvc' };
    case 'card_declined':
      return { type: 'card_declined', field: 'card' };
    case 'missing':
      return { type: 'missing', field: 'card' };
    case 'processing_error':
      return { type: 'processing_error', field: 'card' };
    case 'script_load_failed':
      return { type: 'script_load_failed', field: 'card' };
    default:
      throw new Error('stripe errored');
  }
};

export const toSettingPayment = (
  paymentMethods: IReservation.PaymentMethod[],
): IBooks.SettingPayment => ({ paymentMethods });

export const toSelectedPaymentMethod = (
  paymentMethod: IReservation.InputPaymentMethod,
  ctok?: string,
): IReservation.InputPaymentMethod => {
  if (ctok && paymentMethod.method === 'credit_card') {
    paymentMethod.ctok = ctok;
  }
  return paymentMethod;
};

const detachSurveyError = (
  settings: IBooks.Settings,
  fieldName: string,
): void => {
  if (fieldName !== 'surveyAnswer') {
    return;
  }
  delete settings.userInfo!.survey!.errors;
};

const detachResourceCustomerFieldsError = (
  settings: IBooks.Settings,
  fieldName: string,
): void => {
  if (fieldName.indexOf('customerFields') !== 0) {
    return;
  }
  const field = settings.userInfo!.customerFields!.fields.find(
    (f) => f.name === fieldName.replace('customerFields.', ''),
  );
  if (field) {
    delete field!.errors;
  }
};

const detachQuestionnaireCustomFieldsError = (
  settings: IBooks.Settings,
  fieldName: string,
): void => {
  if (fieldName.indexOf('questionnaires') !== 0) {
    return;
  }
  const questionnaire = settings.userInfo!.questionnaires!.find(
    (q) =>
      q.customFieldId.toString() === fieldName.replace('questionnaires.', ''),
  );
  if (questionnaire) {
    delete questionnaire!.errors;
  }
};

export const detachErrors = (
  settings: IBooks.Settings,
  fieldName: string,
): IBooks.Settings => {
  const _settings = { ...settings };
  detachSurveyError(_settings, fieldName);
  detachResourceCustomerFieldsError(_settings, fieldName);
  detachQuestionnaireCustomFieldsError(_settings, fieldName);
  return _settings;
};

export const attachErrors = (
  settings: IBooks.Settings,
  errors: IReservation.Errors,
): IBooks.Settings => {
  const _settings = { ...settings };
  attachSurveyError(_settings, errors.survey_answer);
  attachResourceCustomerFieldsError(_settings, errors.resource_customer_fields);
  attachQuestionnaireCustomFieldsError(
    _settings,
    errors.questionnaire_custom_fields,
  );
  return _settings;
};

export const detachPaymentErrors = (
  settings: IBooks.Settings,
): IBooks.Settings => ({
  ...settings,
  payment: {
    ...settings.payment!,
    errors: undefined,
  },
});

export const attachPaymentErrors = (
  settings: IBooks.Settings,
  _error: stripe.StripeError,
): IBooks.Settings => ({
  ...settings,
  payment: {
    ...settings.payment!,
    errors: { creditCard: toCreditCardError(_error) },
  },
});

export const toSettingConfirm = ({
  resource_term,
  reservable_period,
  changeable,
  cancelable,
  online_meeting,
  enabled_remote_lock,
}: IConfirm.MerchantBookingConfirmData): IBooks.SettingConfirmation => ({
  resourceTerm: resource_term,
  reservable_period,
  changeable,
  cancelable: cancelable
    ? cancelable.type === 'accept'
      ? { ...cancelable, refundableDaysLimit: cancelable.refundable_days_limit }
      : { ...cancelable }
    : undefined,
  onlineMeeting: online_meeting,
  enabledRemoteLock: enabled_remote_lock,
});

export const toSettingsModelTypeCourse = ({
  candidateViewType,
  courseData,
  customFieldsData,
  confirmData,
  selectedDate,
  selectedCourseData,
}: {
  candidateViewType: IBooks.CandidateViewType;
  courseData: ICourse.MerchantBookingCourseData;
  customFieldsData: ICustomFields.MerchantBookingCustomFieldsData;
  confirmData: IConfirm.MerchantBookingConfirmData;
  selectedDate?: string;
  selectedCourseData?: ICourse.MerchantBookingSelectedCourseData;
}): IBooks.SettingsModelTypeCourse => {
  const bookingTargets = toSettingBookingTarget(courseData, selectedCourseData);
  const userInfo = toSettingUserInfo(customFieldsData);
  const confirmation = toSettingConfirm(confirmData);
  const completion = {
    reservable_period: confirmation.reservable_period,
    changeable: confirmation.changeable,
    cancelable: confirmation.cancelable,
    onlineMeeting: confirmation.onlineMeeting,
    enabledRemoteLock: confirmation.enabledRemoteLock,
  };

  return {
    modelType: IBooks.ModelType.AvailabilityScheme,
    bookingTargets,
    bookingDateTimes: {
      candidateViewType,
      selectedDate,
      availableDates: { dates: [], time_zone: 'unknown' },
      availableTimes: {
        status: 'inactive',
        availabilities: [],
        time_zone: 'unknown',
      },
      events: { checkinTimes: [], dates: {} },
      firstSelected: undefined,
    },
    userInfo,
    confirmation,
    completion,
  };
};

export const toSettingsModelTypeCourseWithStaff = ({
  candidateViewType,
  courseData,
  staffData,
  customFieldsData,
  confirmData,
  selectedDate,
  selectedCourseData,
  selectedStaffData,
}: {
  candidateViewType: IBooks.CandidateViewType;
  courseData: ICourse.MerchantBookingCourseData;
  staffData: MerchantBookingStaffApiResponse;
  customFieldsData: ICustomFields.MerchantBookingCustomFieldsData;
  confirmData: IConfirm.MerchantBookingConfirmData;
  selectedDate?: string;
  selectedCourseData?: ICourse.MerchantBookingSelectedCourseData;
  selectedStaffData?: MerchantBookingSelectedStaffApiResponse;
}): IBooks.SettingsModelTypeCourseWithStaff => {
  const bookingTargets = toSettingBookingTarget(courseData, selectedCourseData);
  const staffs = remapStaffById(staffData.data);
  const staffList = toSettingStaffList(staffData.data); // for order.
  const selectedStaff = selectedStaffData
    ? toSettingSlectedStaff(selectedStaffData.data)
    : undefined;
  const userInfo = toSettingUserInfo(customFieldsData);
  const confirmation = toSettingConfirm(confirmData);
  const completion = {
    reservable_period: confirmation.reservable_period,
    changeable: confirmation.changeable,
    cancelable: confirmation.cancelable,
    onlineMeeting: confirmation.onlineMeeting,
    enabledRemoteLock: confirmation.enabledRemoteLock,
  };

  return {
    modelType: IBooks.ModelType.AssignScheme,
    bookingTargets: {
      ...bookingTargets,
      staffs,
      staffList,
      selectedStaff,
    },
    bookingDateTimes: {
      availableDates: { dates: [], time_zone: 'unknown' },
      availableTimes: {
        status: 'inactive',
        availabilities: [],
        time_zone: 'unknown',
      },
      firstSelected: IBooks.Switch.Course,
      candidateViewType,
      selectedDate,
      events: { checkinTimes: [], dates: {} },
    },
    userInfo,
    confirmation,
    completion,
  };
};

export const toSettingsModelTypeSchool = ({
  eventData,
  lessonData,
  customFieldsData,
  confirmData,
  selectedTimeSlotData,
}: {
  eventData: IEvent.MerchantBookingEventData;
  lessonData: ILesson.MerchantBookingLessonData;
  customFieldsData: ICustomFields.MerchantBookingCustomFieldsData;
  confirmData: IConfirm.MerchantBookingConfirmData;
  selectedTimeSlotData?: ITimeSlots.MerchantBookingTimeSlotData;
}): IBooks.SettingsModelTypeSchool => {
  const bookingTargets = toSettingBookingTargetLesson(
    lessonData,
    selectedTimeSlotData,
  );
  const bookingDateTimePicker = toSettingBookingDateTimePicker(
    eventData,
    selectedTimeSlotData,
  );
  const userInfo = toSettingUserInfo(customFieldsData);
  const confirmation = toSettingConfirm(confirmData);
  const completion = {
    reservable_period: confirmation.reservable_period,
    changeable: confirmation.changeable,
    cancelable: confirmation.cancelable,
    onlineMeeting: confirmation.onlineMeeting,
    enabledRemoteLock: confirmation.enabledRemoteLock,
  };

  return {
    modelType: IBooks.ModelType.SchoolScheme,
    bookingTargets,
    bookingDateTimePicker,
    userInfo,
    confirmation,
    completion,
  };
};

const toSettingEvent = (
  eventData: IEvent.MerchantBookingEventData,
  selectedTimeSlotData?: ITimeSlots.MerchantBookingTimeSlotData,
): IBooks.SettingEvent => ({
  name: eventData.name,
  description: eventData.description,
  showVacancy: eventData.show_time_slot_vacancy,
  selectSlotsType: eventData.select_slots_type,
  multipleAttendees: { ...eventData.multiple_attendees },
  multipleSelectSlots: eventData.multiple_select_slots,
  attendeesCustomLabel: eventData.attendees_custom_label,
  waitingListProvided: undefined,
  waitingList: [],
  timeSlots: {},
  timeSlotsFlatten: [],
  selectedTimeSlot:
    selectedTimeSlotData &&
    !selectedTimeSlotData.meta.reserved &&
    selectedTimeSlotData.data.vacancy > 0
      ? toEventTimeSlot(selectedTimeSlotData.data)
      : undefined,
});

export const toSettingsModelTypeEvent = ({
  eventData,
  customFieldsData,
  confirmData,
  selectedTimeSlotData,
}: {
  eventData: IEvent.MerchantBookingEventData;
  customFieldsData: ICustomFields.MerchantBookingCustomFieldsData;
  confirmData: IConfirm.MerchantBookingConfirmData;
  selectedTimeSlotData?: ITimeSlots.MerchantBookingTimeSlotData;
}): IBooks.SettingsModelTypeEvent => {
  const event = toSettingEvent(eventData, selectedTimeSlotData);
  const userInfo = toSettingUserInfo(customFieldsData);
  const confirmation = toSettingConfirm(confirmData);
  const completion = {
    reservable_period: confirmation.reservable_period,
    changeable: confirmation.changeable,
    cancelable: confirmation.cancelable,
    onlineMeeting: confirmation.onlineMeeting,
    enabledRemoteLock: confirmation.enabledRemoteLock,
  };

  return {
    modelType: IBooks.ModelType.EventScheme,
    event,
    userInfo,
    confirmation,
    completion,
  };
};

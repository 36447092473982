import React, { ReactNode, FC } from 'react';
import { PreparingMerchantSite } from '../../molecules/PreparingMerchantSite';

type Props = {
  children: ReactNode;
  isUnPublished: boolean;
  isMerchantInfoExist: boolean;
  isProtected?: boolean;
  protectedElement?: ReactNode;
  logoImage: { url: string } | null;
  merchantPublicId: string | null;
  displayName: string | null;
  isCoubicApp: boolean;
};

export const MerchantSite: FC<Props> = ({
  children,
  isUnPublished,
  isMerchantInfoExist,
  isProtected = false,
  protectedElement,
  merchantPublicId,
  displayName,
  logoImage,
  isCoubicApp,
}) => {
  if (isUnPublished && !isMerchantInfoExist)
    return (
      <PreparingMerchantSite
        isCoubicApp={isCoubicApp}
        logoImage={logoImage}
        merchantPublicId={merchantPublicId}
        displayName={displayName}
      />
    );
  if (isProtected) return <>{protectedElement}</>;
  return <>{children}</>;
};

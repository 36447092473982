import { Action as _Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import * as IInputs from '../../../../../interfaces/merchants/resources/books/inputs';

export type Result<R> = ThunkAction<R, IInputs.State, undefined, Actions>;
export type Dispatch = ThunkDispatch<IInputs.State, undefined, Actions>;

export enum Types {
  Init = 'merchant/services/resources/books/inputs/init',
  InputReservationId = 'merchant/services/resources/books/inputs/inputreservationid',
  ClearReservationId = 'merchant/services/resources/books/inputs/clearreservationid',
  InputBookingTarget = 'merchant/services/resources/books/inputs/inputbookingtarget',
  ClearBookingTarget = 'merchant/services/resources/books/inputs/clearbookingtarget',
  InputBookingDateTime = 'merchant/services/resources/books/inputs/inputbookingdatetime',
  InputBookingEventTimeSlot = 'merchant/services/resources/books/inputs/inputbookingeventtimeslot',
  InputBookingSchoolTimeSlot = 'merchant/services/resources/books/inputs/inputbookingschooltimeslot',
  ClearBookingSchoolTimeSlot = 'merchant/services/resources/books/inputs/clearbookingschooltimeslot',
  InputUserInfo = 'merchant/services/resources/books/inputs/inputuserinfo',
  InputPayment = 'merchant/services/resources/books/inputs/inputpayment',
  InputConfirmation = 'merchant/services/resources/books/inputs/inputconfirmation',
  SetGoogleCalendarTemplateUrl = 'merchant/services/resources/books/setGoogleCalendarTemplateUrl',
}

export interface InitAction extends _Action {
  type: Types.Init;
  payload: { merchantId: string; resourceId: string };
}

export interface InputReservationIdAction extends _Action {
  type: Types.InputReservationId;
  payload: { reservationId: number };
}

export interface ClearReservationIdAction extends _Action {
  type: Types.ClearReservationId;
  payload: {};
}

export interface InputBookingTargetAction extends _Action {
  type: Types.InputBookingTarget;
  payload:
    | {
        course: IInputs.Course;
        options?: IInputs.Option[];
        staff?: undefined;
        lesson?: undefined;
      }
    | {
        course: IInputs.Course;
        options?: IInputs.Option[];
        staff: IInputs.Staff;
        lesson?: undefined;
      }
    | {
        course?: undefined;
        options?: undefined;
        staff?: undefined;
        lesson: IInputs.Lesson;
      };
}

export interface ClearBookingTargetAction extends _Action {
  type: Types.ClearBookingTarget;
  payload: {
    clearCourse: boolean;
    clearOptions: boolean;
    clearStaff?: boolean;
  };
}

export interface InputBookingDateTimeAction extends _Action {
  type: Types.InputBookingDateTime;
  payload: {
    timeSlotAvailability: IInputs.TimeSlotAvailability;
  };
}

export interface InputBookingEventTimeSlotAction extends _Action {
  type: Types.InputBookingEventTimeSlot;
  payload: {
    eventTimeSlot: IInputs.EventTimeSlot;
  };
}

export interface InputBookingSchoolTimeSlotAction extends _Action {
  type: Types.InputBookingSchoolTimeSlot;
  payload: {
    schoolTimeSlots: IInputs.SchoolTimeSlot[];
  };
}

export interface ClearBookingSchoolTimeSlotAction extends _Action {
  type: Types.ClearBookingSchoolTimeSlot;
  payload: {};
}

export interface InputUserInfoAction extends _Action {
  type: Types.InputUserInfo;
  payload: { userInfo: IInputs.UserInfo };
}

export interface InputPaymentAction extends _Action {
  type: Types.InputPayment;
  payload: {
    payment: IInputs.Payment;
  };
}

export interface InputConfirmationAction extends _Action {
  type: Types.InputConfirmation;
  payload: {
    termsAccepted: boolean | undefined;
  };
}
export interface SetGoogleCalendarTemplateUrlAction extends _Action {
  type: Types.SetGoogleCalendarTemplateUrl;
  payload: {
    googleCalendarTemplateUrl: string;
  };
}

export type Actions =
  | InitAction
  | InputReservationIdAction
  | ClearReservationIdAction
  | InputBookingTargetAction
  | ClearBookingTargetAction
  | InputBookingDateTimeAction
  | InputBookingEventTimeSlotAction
  | InputBookingSchoolTimeSlotAction
  | ClearBookingSchoolTimeSlotAction
  | InputUserInfoAction
  | InputPaymentAction
  | InputConfirmationAction
  | SetGoogleCalendarTemplateUrlAction;

export default (state: IInputs.State = {}, action: Actions): IInputs.State => {
  switch (action.type) {
    case Types.Init: {
      return { ...action.payload };
    }
    case Types.InputReservationId: {
      const { reservationId } = action.payload;
      return { ...state, reservationId };
    }
    case Types.ClearReservationId: {
      return { ...state, reservationId: undefined };
    }
    case Types.InputBookingTarget: {
      const { course, options, staff, lesson } = action.payload;
      return { ...state, course, options, staff, lesson };
    }
    case Types.ClearBookingTarget: {
      const { clearCourse, clearOptions, clearStaff } = action.payload;
      return {
        ...state,
        course: clearCourse ? undefined : state.course,
        options: clearOptions ? undefined : state.options,
        staff: clearStaff ? undefined : state.staff,
      };
    }
    case Types.InputBookingDateTime: {
      const { timeSlotAvailability } = action.payload;
      return { ...state, timeSlotAvailability };
    }
    case Types.InputBookingEventTimeSlot: {
      const { eventTimeSlot } = action.payload;
      return { ...state, eventTimeSlot };
    }
    case Types.InputBookingSchoolTimeSlot: {
      const { schoolTimeSlots } = action.payload;
      return { ...state, schoolTimeSlots };
    }
    case Types.ClearBookingSchoolTimeSlot: {
      return { ...state, schoolTimeSlots: undefined };
    }
    case Types.InputUserInfo: {
      const { userInfo } = action.payload;
      return { ...state, userInfo };
    }
    case Types.InputPayment: {
      const { payment } = action.payload;
      return { ...state, payment };
    }
    case Types.InputConfirmation: {
      const { termsAccepted } = action.payload;
      return { ...state, termsAccepted };
    }
    case Types.SetGoogleCalendarTemplateUrl: {
      return {
        ...state,
        googleCalendarTemplateUrl: action.payload.googleCalendarTemplateUrl,
      };
    }
    default:
      return state;
  }
};

export enum ViewStyle {
  Calendar,
  List,
}

// SSRで使ったDate値をclientで取り扱うとtoString()されたstring型になるので。
export type DateString = Date | string;

export interface Meta {
  business_hours: Array<{
    weekday: number;
    is_open: boolean;
    opening_hour: string;
    closing_hour: string;
  }>;
  time_zone: string;
}

export interface Service {
  public_id: string;
  all_day: boolean;
  booking_url: string;
  capacity?: number;
  color: string;
  digest: string;
  end: number;
  model_type: string;
  reservable: boolean;
  start: number;
  submit: boolean;
  title: string;
  vacancy?: number;
  waiting_list_provided: boolean;
  full: boolean | null;
  metadata: {
    merchant: {
      id: string;
    };
    resource: {
      id: string;
    };
  };
  is_registered_waiting_list: boolean;
}

interface Range {
  start: DateString;
  end: DateString;
}

export interface Query {
  publicId: string;
  range: Range;
}

export interface ResponseData {
  meta?: Meta;
  data: Service[];
}

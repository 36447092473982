import React, { createContext, useCallback, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useUpdateUserMeLocale, useUserMe } from '../hooks/api/me';
import { setCookie } from '../utils/cookie';
import I18n, { coubicLocale } from '../utils/i18n';
import { setCoubicLocaleRequestHeader } from '../utils/secured-axios';

export interface LocaleContextProps {
  locale: string;
  children: React.ReactNode;
}

export interface LocaleContextState {
  currentLocale: coubicLocale;
  changeLocale: (l: string) => void;
}

export const LocaleContext = createContext<LocaleContextState>({
  currentLocale: I18n.defaultLocale,
  changeLocale: () => undefined,
});

export const LocaleContextProvider = ({
  locale,
  children,
}: LocaleContextProps) => {
  const currentLocale = useMemo(() => I18n.validLocale(locale), [locale]);

  const { isLoggedIn } = useUserMe();
  const { trigger: updateUserMeLocale } = useUpdateUserMeLocale();

  const changeLocale = useCallback(
    async (l: string) => {
      const locale = I18n.validLocale(l);

      setCookie('hl', locale);

      setCoubicLocaleRequestHeader(locale);

      if (isLoggedIn) {
        await updateUserMeLocale(locale);
      }

      location.reload();
    },
    [updateUserMeLocale, isLoggedIn],
  );

  const value = useMemo(() => {
    return {
      changeLocale,
      currentLocale,
    };
  }, [changeLocale, currentLocale]);

  return (
    <LocaleContext.Provider value={value}>
      <IntlProvider
        locale={currentLocale}
        messages={I18n.messages(currentLocale)}
        textComponent="span"
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

// then make a consumer which will surface it
export const LocaleContextConsumer = LocaleContext.Consumer;

import { useMemo } from 'react';
import useSWR from 'swr';
import { PaymentMethodFirstTimeFreeCoupon } from '../../../interfaces/reservations';
import { fetchReservationPaymentMethods } from '../../../redux/actions/merchants/resources/books/api';
import { ReduxState } from '../../../redux/reducers';
import { getMerchantResourcesBooksInputs } from '../../../redux/selectors/merchants/resources/books/inputs';
import { useShallowEqualSelector } from '../../redux';
import { useBookingCalendarMeta } from '../booking-calendar-meta';
import { fetchMerchantCoupons } from '../../../infrastructure/api/coupon';

export const useMerchantCoupons = (params: { merchantPublicId: string }) => {
  const { merchantPublicId } = params;
  const swrKey = `/merchants/${merchantPublicId}/vouchers`;

  const fetcher = async () => {
    const data = await fetchMerchantCoupons(merchantPublicId);
    return data;
  };
  const { data, error, isValidating } = useSWR(swrKey, fetcher);

  return {
    data,
    error,
    isValidating,
  };
};

const useCouponBookingPageMapping = (merchantPublicId: string) => {
  const { data } = useBookingCalendarMeta(merchantPublicId);

  return useMemo(() => {
    // key: coupon  public_id, value: booking_page ids
    const couponBookingPageIdsMap: { [staffId: string]: string[] } = {};
    const coupons: { name: string; publicId: string }[] = [];
    data?.booking_pages.forEach((bookingPage) => {
      bookingPage.assigned_vouchers.forEach((voucher) => {
        if (couponBookingPageIdsMap[voucher.public_id]) {
          couponBookingPageIdsMap[voucher.public_id].push(
            bookingPage.public_id,
          );
        } else {
          coupons.push({
            name: voucher.name,
            publicId: voucher.public_id,
          });
          couponBookingPageIdsMap[voucher.public_id] = [bookingPage.public_id];
        }
      });
    });

    return {
      couponBookingPageIdsMap,
      coupons,
    };
  }, [data]);
};

export const useCouponBookingPageIds = (params: {
  merchantPublicId: string;
  couponPublicId: string;
}) => {
  const { merchantPublicId, couponPublicId } = params;
  const { couponBookingPageIdsMap } =
    useCouponBookingPageMapping(merchantPublicId);
  return couponBookingPageIdsMap[couponPublicId];
};

export const useAvailableFirstTimeFreeCoupon = () => {
  const { inputs } = useShallowEqualSelector((state: ReduxState) => ({
    inputs: getMerchantResourcesBooksInputs(
      state.merchantsResourcesBooksInputs,
    ),
  }));

  const swrKey = inputs.reservationId
    ? `/reservations/${inputs.reservationId}/payment_methods`
    : null;

  const fetcher = async () => {
    const data = await fetchReservationPaymentMethods(
      inputs.merchantId ?? '',
      inputs.reservationId ?? 0,
    );
    const firstTimeFreeCouponPaymentMethod = data.payment_methods.find(
      (method) => method.method === 'first_time_free_voucher',
    ) as PaymentMethodFirstTimeFreeCoupon | undefined;
    const couponInfo =
      firstTimeFreeCouponPaymentMethod?.products.length !== 0
        ? firstTimeFreeCouponPaymentMethod?.products[0]
        : undefined;

    return couponInfo
      ? {
          publicId: couponInfo.public_id,
          name: couponInfo.name,
          paymentMethod: firstTimeFreeCouponPaymentMethod,
        }
      : undefined;
  };

  const { data, error, isValidating } = useSWR(swrKey, fetcher);

  return {
    data,
    error,
    isValidating,
  };
};

import axios, { AxiosInstance } from 'axios';

import {
  BookingPageBookable,
  BookingPageList,
} from '../../domain/entities/BookingPage';
import { MerchantBookingPageApiResponse } from '../../interfaces/merchants/bookings';
import { MerchantBookingConfirmData } from '../../interfaces/merchants/bookings/confirm';
import { MerchantBookingCourseData } from '../../interfaces/merchants/bookings/courses';
import { MerchantBookingStaffApiResponse } from '../../interfaces/merchants/bookings/staff';
import { apiRoot } from '../api';
import securedAxios, { newSecuredAxios } from '../../utils/secured-axios';

/**
 * NOTE: use coubic v2 api
 */

const searchQueryString = (page?: string, via?: string) => {
  const pageParam = page ? `page=${page}` : '';
  const viaParam = via ? `via=${via}` : '';
  if (pageParam && viaParam) {
    return `?${pageParam}&${viaParam}`;
  }
  if (pageParam && !viaParam) {
    return `?${pageParam}`;
  }
  if (!pageParam && viaParam) {
    return `?${viaParam}`;
  }
  return '';
};

export class BookingPageRepository {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async Find(
    merchantId: string,
    resourcePublicId: string,
  ): Promise<MerchantBookingPageApiResponse> {
    const path = `${apiRoot()}/merchants/${merchantId}/booking_pages/${resourcePublicId}`;

    return this.api.get(path).then((response) => {
      return response.data;
    });
  }

  public async Search(
    merchantId: string,
    page?: string,
    via?: string,
  ): Promise<BookingPageList | undefined> {
    const queryString = searchQueryString(page, via);
    const path = `${apiRoot()}/merchants/${merchantId}/booking_pages${queryString}`;
    const response = await this.api.get(path);
    return response.status === 200
      ? new BookingPageList(response.data)
      : undefined;
  }

  public async Bookable(merchantId: string, bookingPagePublicId: string) {
    const path = `${apiRoot()}/merchants/${merchantId}/booking_pages/${bookingPagePublicId}/bookable`;

    try {
      const response = await this.api.get(path);

      const bookable =
        response.status === 200
          ? new BookingPageBookable(response.data)
          : undefined;
      const siteUnpublishedHeader =
        response.headers['x-coubic-site-unpublished'];
      const isUnpublishedSite = siteUnpublishedHeader
        ? Boolean(siteUnpublishedHeader)
        : false;
      return {
        bookable,
        isUnpublishedSite,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const siteUnpublishedHeader =
          error.response?.headers['x-coubic-site-unpublished'];
        const isUnpublishedSite = siteUnpublishedHeader
          ? Boolean(siteUnpublishedHeader)
          : false;
        return {
          merchant: undefined,
          isUnpublishedSite,
        };
      } else {
        throw error;
      }
    }
  }

  public async SearchStaff(
    publicId: string,
    resourcePublicId: string,
  ): Promise<MerchantBookingStaffApiResponse> {
    const path = `${apiRoot()}/merchants/${publicId}/booking_pages/${resourcePublicId}/staff`;

    return this.api.get(path).then((response) => {
      return response.data;
    });
  }

  public async SearchCourse(
    publicId: string,
    resourcePublicId: string,
  ): Promise<MerchantBookingCourseData> {
    const path = `${apiRoot()}/merchants/${publicId}/booking_pages/${resourcePublicId}/courses`;

    return this.api.get(path).then((response) => {
      return response.data;
    });
  }

  public async GetBookingCalendarMeta(publicId: string): Promise<CalendarMeta> {
    const path = `${apiRoot()}/merchants/${publicId}/booking_calendar_meta`;

    return this.api.get(path).then((response) => {
      return new CalendarMeta(response.data);
    });
  }

  public async PasswordVerify(
    publicId: string,
    resourcePublicId: string,
    password: string,
  ): Promise<boolean> {
    const path = `${apiRoot()}/merchants/${publicId}/booking_pages/${resourcePublicId}/verify`;

    return this.api
      .post(path, {
        password,
      })
      .then((response) => {
        return response.status === 200;
      })
      .catch((err) => {
        if (axios.isAxiosError(err) && err.response?.status === 401) {
          return false;
        }
        return Promise.reject(err);
      });
  }

  public async ConfirmData(
    merchantPublicId: string,
    resourcePublicId: string,
  ): Promise<MerchantBookingConfirmData> {
    const path = `${apiRoot()}/merchants/${merchantPublicId}/booking_pages/${resourcePublicId}/confirm`;

    return this.api.get(path).then((response) => {
      return response.data;
    });
  }
}

type CalendarBookingPageMeta = {
  name: string;
  publicId: string;
};

export class CalendarMeta {
  public readonly bookingPages: CalendarBookingPageMeta[];

  constructor(init: {
    booking_pages: Array<{ name: string; public_id: string }>; // これがAPIレスポンスの構造
  }) {
    this.bookingPages = init.booking_pages.map((e) => {
      return { name: e.name, publicId: e.public_id };
    });
  }
}

export default new BookingPageRepository(securedAxios);

// SSRでAPIリクエストを行いたい場合、session情報を明示的に載せる必要があるため、
// こっちのメソッドから生成されたインスタンスを利用する
// まだ必要な箇所が限定的なので自前実装で対応しているが、ゆくゆくは
// https://github.com/maticzav/nookies
// みたいなライブラリを利用して、あまり意識しなくてもこの辺が達成される方向に持っていきたい
export const BookingPageRepositoryForSSR = (
  session: string,
  token: string,
  requestId: string,
) => {
  return new BookingPageRepository(
    newSecuredAxios({ session, token, requestId }),
  );
};

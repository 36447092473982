import * as IInputs from '../../../../../interfaces/merchants/resources/books/inputs';
import * as IReserve from '../../../../../interfaces/reservations';

export const toQuestionnaireValue = (
  value: IInputs.QuestionnaireValueCandidate,
): string | string[] =>
  typeof value === 'string'
    ? value
    : Object.keys(value).filter((k) => value[k]);

const isArrayOfString = (value: string | string[]): value is string[] =>
  Array.isArray(value)
    ? value.every((item) => typeof item === 'string')
    : false;

export const toQuestionnaireAttributes = (
  id: string,
  value: string | string[],
): IReserve.QuestionnaireAttribute | undefined => {
  if (isArrayOfString(value)) {
    if (value.length !== 0) {
      return {
        questionnaire_custom_field_id: id,
        value,
        value_type: 'array_type' as const,
      };
    } else {
      return undefined;
    }
  } else {
    return value ? { questionnaire_custom_field_id: id, value } : undefined;
  }
};

export const withEventTimeSlot = (
  eventTimeSlot: IInputs.EventTimeSlot,
): IReserve.Input => ({
  time_slot_ids: eventTimeSlot.timeSlots.map((t) => t.id.toString()),
  num_attendees: eventTimeSlot.attendees,
});

export const withTimeSlotAvailability = (
  timeSlotAvailability: IInputs.TimeSlotAvailability,
): IReserve.Input => {
  const { date, value } = timeSlotAvailability;
  return {
    preferred_date: date,
    preferred_time: value,
  };
};

export const withSchoolTimeSlot = (
  schoolTimeSlots: IInputs.SchoolTimeSlot[],
): IReserve.Input => ({
  time_slot_ids: schoolTimeSlots.map((t) => t.id.toString()),
});

export const withUserInfo = (userInfo: IInputs.UserInfo): IReserve.Input => {
  const struct: IReserve.Input = {};

  const { customerFields, surveyAnswer, questionnaires } = userInfo;

  const contact = {};
  if (customerFields) {
    Object.keys(customerFields).forEach((k) => {
      contact[k] = customerFields[k];
    });
  }

  struct.contact = contact;

  if (surveyAnswer) {
    struct.survey_answer = surveyAnswer;
  }

  if (questionnaires) {
    struct.questionnaires_attributes = [];

    Object.keys(questionnaires).forEach((id) => {
      const item = toQuestionnaireAttributes(
        id,
        toQuestionnaireValue(questionnaires[id]),
      );
      if (item) {
        struct.questionnaires_attributes?.push(item);
      }
    });
  }

  return struct;
};

const toPaymentMethod = (
  payment: IInputs.Payment,
  options: {
    final: boolean;
    email: string;
  },
): IReserve.InputPaymentMethod => {
  const { email, final } = options;
  if (payment.paymentMethod.method === 'onsite') {
    return { method: 'onsite' };
  }
  if (payment.paymentMethod.method === 'bank_transfer') {
    return { method: 'bank_transfer' };
  }
  if (payment.paymentMethod.method === 'subscription') {
    return {
      method: 'subscription',
      customer_subscription_id: payment.subscriptionSelection!,
    };
  }
  if (payment.paymentMethod.method === 'ticket') {
    return { method: 'ticket', product_id: payment.ticketSelection! };
  }
  if (payment.paymentMethod.method === 'first_time_free_voucher') {
    return {
      method: 'first_time_free_voucher',
      voucher_public_id: payment.firstTimeFreeCouponPublicId ?? '',
      email,
    };
  }
  if (!payment.cardSelection) {
    // カード選びがない => 利用歴がなく新しいカード生成
    return {
      ctok: final ? payment.token! : '',
      method: 'credit_card',
    };
  }
  if (payment.cardSelection === 'another') {
    // カード利用歴があるが、新しく生成する
    return {
      ctok: final ? payment.token! : '',
      method: 'credit_card',
      restore_credit_card: false,
    };
  }
  if (payment.cardSelection === 'used') {
    // カード利用歴があり、以前のカードを利用する
    return {
      method: 'credit_card',
      restore_credit_card: true,
    };
  }
  throw new Error(`cannot create payment: payment ${payment}`);
};

export const withPayment = (
  payment: IInputs.Payment,
  options: { email: string },
): IReserve.Input => ({
  payment_method: toPaymentMethod(payment, {
    final: false,
    email: options.email,
  }),
});

export const withFinalize = (
  termsAccepted: boolean | undefined,
  selectedPaymentMethod: IReserve.InputPaymentMethod | undefined,
): IReserve.Input => ({
  terms_accepted: termsAccepted,
  payment_method: selectedPaymentMethod,
});

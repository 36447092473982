import * as React from 'react';
import { v4 } from 'uuid';

class StringUtils {
  public static nl2brString(text: string): string {
    const regex = /(\n)/g;

    return text
      .split(regex)
      .map((line) => {
        if (line.match(regex)) {
          return `<br />`;
        }
        return line;
      })
      .join('');
  }

  public static simpleFormat(text: string) {
    const regex = /\r?\n/g;

    return (
      <React.Fragment>
        {text.split(regex).map((p: string) => (
          <p key={v4()}>{p ? p : <br key={v4()} />}</p>
        ))}
      </React.Fragment>
    );
  }
}

export default StringUtils;

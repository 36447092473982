import React, {
  FC,
  ReactNode,
  DetailedHTMLProps,
  SelectHTMLAttributes,
} from 'react';
import styles from './style.module.scss';

type SelectProps = DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

type Props = SelectProps & {
  children: ReactNode;
};

// 汎用的に使えるコンポーネントだが、カレンダーでしか使われていないスタイルなので一旦ここに置く
export const SelectBox: FC<Props> = ({ children, ...props }) => {
  return (
    <select {...props} className={styles.select}>
      {children}
    </select>
  );
};

export interface MerchantBookingCustomFieldsSurvey {
  question: string;
  required: boolean;
}

export enum AnswerType {
  TextField = 'text_field',
  TextArea = 'text_area',
  Checkbox = 'checkbox',
  SelectBox = 'select_box',
  RadioButton = 'radio_button',
}

export enum ValidationType {
  None = 'none',
  Alphanumeric = 'alphanumeric',
  Numeric = 'numeric',
  Phone = 'phone',
  Email = 'email',
  Katakana = 'katakana',
  Regex = 'regex',
}

export interface MerchantBookingCustomFieldsQuestionnaire {
  id: number;
  answer_type: AnswerType;
  label: string;
  options?: string[];
  order: number;
  required: boolean;
  first_time_only: boolean;
}

export interface MerchantBookingCustomFieldsResource {
  name: string;
  default_value?: string;
  editable: boolean;
  custom_label?: string;
  required: boolean;
  validation_type: ValidationType;
}

export interface MerchantBookingCustomFieldsData {
  survey?: MerchantBookingCustomFieldsSurvey;
  resource_customer_fields?: {
    fields: MerchantBookingCustomFieldsResource[];
  };
  questionnaire_custom_fields?: MerchantBookingCustomFieldsQuestionnaire[];
}

import React, { FC } from 'react';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';

import { ReservationFlowReservablePeriod } from 'src/interfaces/reservation_flow';
import { RSDescriptionList } from 'src/reservation-page/description-list/RSDescriptionList';
import { RSDescriptionListItem } from 'src/reservation-page/description-list/RSDescriptionListItem';
import { getDayWithSuffix } from 'src/utils/intl';

export interface CautionListProps {
  merchantTimeZone: string;
  reservablePeriod: ReservationFlowReservablePeriod;
  changeableUntil: number | null;
  cancelableUntil: number | null;
  isPrepaymentEnabled: boolean;
}

export const CautionList: FC<CautionListProps> = ({
  merchantTimeZone,
  reservablePeriod,
  changeableUntil,
  cancelableUntil,
  isPrepaymentEnabled,
}) => {
  const intl = useIntl();

  return (
    <RSDescriptionList>
      {(() => {
        const { start_period } = reservablePeriod;
        if (start_period === null) {
          return null;
        }

        if (start_period.type === 'no_setup') {
          return null;
        }

        switch (start_period.type) {
          case 'datetime': {
            if (start_period.datetime === null) {
              return null;
            }

            return (
              <RSDescriptionListItem
                term={intl.formatMessage({
                  defaultMessage: '予約の受付開始',
                  id: 'I2kVrW',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                })}
                description={
                  <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
                    {intl.formatMessage(
                      {
                        defaultMessage: '{dateTimeString}から',
                        id: 'f0MJrP',
                        description:
                          'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                      },
                      {
                        dateTimeString: intl.formatDate(start_period.datetime, {
                          dateStyle: 'long',
                          timeStyle: 'short',
                          timeZone: merchantTimeZone,
                        }),
                      },
                    )}
                  </div>
                }
              />
            );
          }
          case 'relative_month': {
            if (start_period.relative_month === null) {
              return null;
            }

            const { base_months, seconds_from_base_months } =
              start_period.relative_month;

            // 日付計算を行うため年の最初からの経過秒数を取得
            const dateForCalc = dayjs()
              .tz(merchantTimeZone)
              .startOf('year')
              .add(seconds_from_base_months, 'seconds');

            const baseMonthString = (() => {
              if (base_months === 0) {
                return intl.formatMessage({
                  defaultMessage: '当月',
                  id: 'BxSpIW',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                });
              }

              if (base_months === 1) {
                return intl.formatMessage({
                  defaultMessage: '前月',
                  id: 'u8w/x2',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                });
              }

              return intl.formatMessage(
                {
                  defaultMessage: '{baseMonths}ヶ月前',
                  id: 'bjPuHA',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                },
                {
                  baseMonths: base_months,
                },
              );
            })();

            return (
              <RSDescriptionListItem
                term={intl.formatMessage({
                  defaultMessage: '予約の受付開始',
                  id: 'I2kVrW',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                })}
                description={
                  <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
                    {intl.formatMessage(
                      {
                        defaultMessage:
                          '{baseMonthString}の{dayCountString} {timeString}から',
                        id: 'QMS8Eu',
                        description:
                          'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                      },
                      {
                        baseMonthString,
                        dayCountString: getDayWithSuffix(
                          dateForCalc.date(),
                          intl,
                        ),
                        timeString: intl.formatDate(dateForCalc.toDate(), {
                          timeStyle: 'short',
                          timeZone: merchantTimeZone,
                        }),
                      },
                    )}
                  </div>
                }
              />
            );
          }
          case 'relative_day': {
            if (start_period.relative_day === null) {
              return null;
            }

            const { base_days, seconds_from_base_days } =
              start_period.relative_day;

            // 日付計算を行うため年の最初からの経過秒数を取得
            const dateForCalc = dayjs()
              .tz(merchantTimeZone)
              .startOf('year')
              .add(seconds_from_base_days, 'seconds');

            const baseDayString = (() => {
              if (base_days === 0) {
                return intl.formatMessage({
                  defaultMessage: '当日',
                  id: 'o0ohup',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                });
              }

              return intl.formatMessage(
                {
                  defaultMessage: '{baseDays}日前',
                  id: 'TeMBuJ',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                },
                {
                  baseDays: base_days,
                },
              );
            })();

            return (
              <RSDescriptionListItem
                term={intl.formatMessage({
                  defaultMessage: '予約の受付開始',
                  id: 'I2kVrW',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                })}
                description={
                  <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
                    {intl.formatMessage(
                      {
                        defaultMessage: '{baseDayString}の{timeString}から',
                        id: '+hzc0d',
                        description:
                          'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                      },
                      {
                        baseDayString,
                        timeString: intl.formatDate(dateForCalc.toDate(), {
                          timeStyle: 'short',
                          timeZone: merchantTimeZone,
                        }),
                      },
                    )}
                  </div>
                }
              />
            );
          }
        }
      })()}

      {(() => {
        const { end_period } = reservablePeriod;
        if (end_period === null) {
          return null;
        }

        if (end_period.type === 'no_setup') {
          return null;
        }

        switch (end_period.type) {
          case 'datetime': {
            if (end_period.datetime === null) {
              return null;
            }

            return (
              <RSDescriptionListItem
                term={intl.formatMessage({
                  defaultMessage: '予約の受付締切',
                  id: '61vFgb',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                })}
                description={
                  <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
                    {intl.formatMessage(
                      {
                        defaultMessage: '{dateTimeString}まで',
                        id: 'RNE6Nx',
                        description:
                          'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                      },
                      {
                        dateTimeString: intl.formatDate(end_period.datetime, {
                          dateStyle: 'long',
                          timeStyle: 'short',
                          timeZone: merchantTimeZone,
                        }),
                      },
                    )}
                  </div>
                }
              />
            );
          }
          case 'relative_month': {
            if (end_period.relative_month === null) {
              return null;
            }

            const { base_months, seconds_from_base_months } =
              end_period.relative_month;

            // 日付計算を行うため年の最初からの経過秒数を取得
            const dateForCalc = dayjs()
              .tz(merchantTimeZone)
              .startOf('year')
              .add(seconds_from_base_months, 'seconds');

            const baseMonthString = (() => {
              if (base_months === 0) {
                return intl.formatMessage({
                  defaultMessage: '当月',
                  id: 'BxSpIW',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                });
              }

              if (base_months === 1) {
                return intl.formatMessage({
                  defaultMessage: '前月',
                  id: 'u8w/x2',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                });
              }

              return intl.formatMessage(
                {
                  defaultMessage: '{baseMonths}ヶ月前',
                  id: 'bjPuHA',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                },
                {
                  baseMonths: base_months,
                },
              );
            })();

            return (
              <RSDescriptionListItem
                term={intl.formatMessage({
                  defaultMessage: '予約の受付締切',
                  id: '61vFgb',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                })}
                description={
                  <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
                    {intl.formatMessage(
                      {
                        defaultMessage:
                          '{baseMonthString}の{dayCountString} {timeString}',
                        id: '38cIym',
                        description:
                          'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                      },
                      {
                        baseMonthString,
                        dayCountString: getDayWithSuffix(
                          dateForCalc.date(),
                          intl,
                        ),
                        timeString: intl.formatDate(dateForCalc.toDate(), {
                          timeStyle: 'short',
                          timeZone: merchantTimeZone,
                        }),
                      },
                    )}
                  </div>
                }
              />
            );
          }
          case 'relative_day': {
            if (end_period.relative_day === null) {
              return null;
            }

            const { base_days, seconds_from_base_days } =
              end_period.relative_day;

            // 日付計算を行うため年の最初からの経過秒数を取得
            const dateForCalc = dayjs()
              .tz(merchantTimeZone)
              .startOf('year')
              .add(seconds_from_base_days, 'seconds');

            const baseDayString = (() => {
              if (base_days === 0) {
                return intl.formatMessage({
                  defaultMessage: '当日',
                  id: 'o0ohup',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                });
              }

              return intl.formatMessage(
                {
                  defaultMessage: '{baseDays}日前',
                  id: 'TeMBuJ',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                },
                {
                  baseDays: base_days,
                },
              );
            })();

            return (
              <RSDescriptionListItem
                term={intl.formatMessage({
                  defaultMessage: '予約の受付締切',
                  id: '61vFgb',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                })}
                description={
                  <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
                    {intl.formatMessage(
                      {
                        defaultMessage: '{baseDayString}の{timeString}まで',
                        id: 'LFOcVV',
                        description:
                          'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                      },
                      {
                        baseDayString,
                        timeString: intl.formatDate(dateForCalc.toDate(), {
                          timeStyle: 'short',
                          timeZone: merchantTimeZone,
                        }),
                      },
                    )}
                  </div>
                }
              />
            );
          }
          case 'relative': {
            if (end_period.relative === null) {
              return null;
            }

            const { seconds_from_base_checkin } = end_period.relative;

            // 単位が日の場合
            if (seconds_from_base_checkin > 3600) {
              return (
                <RSDescriptionListItem
                  term={intl.formatMessage({
                    defaultMessage: '予約の受付締切',
                    id: '61vFgb',
                    description:
                      'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                  })}
                  description={
                    <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
                      {intl.formatMessage(
                        {
                          defaultMessage: '{hours}時間前まで',
                          id: 'Uu1H/D',
                          description:
                            'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                        },
                        {
                          hours: seconds_from_base_checkin / 3600,
                        },
                      )}
                    </div>
                  }
                />
              );
            }

            return (
              <RSDescriptionListItem
                term={intl.formatMessage({
                  defaultMessage: '予約の受付締切',
                  id: '61vFgb',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                })}
                description={
                  <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
                    {intl.formatMessage(
                      {
                        defaultMessage: '{minutes}分前まで',
                        id: 'bID6Db',
                        description:
                          'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                      },
                      {
                        minutes: seconds_from_base_checkin / 60,
                      },
                    )}
                  </div>
                }
              />
            );
          }
        }
      })()}

      <RSDescriptionListItem
        term={intl.formatMessage({
          defaultMessage: '日程変更',
          id: 'g5kYVT',
          description:
            'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
        })}
        description={(() => {
          if (changeableUntil === null) {
            return (
              <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
                {intl.formatMessage({
                  defaultMessage: '日程変更を受け付けておりません。',
                  id: 'if3mLl',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                })}
              </div>
            );
          }

          if (changeableUntil === 0) {
            return (
              <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
                {intl.formatMessage({
                  defaultMessage: '予約日時まで',
                  id: '375mOQ',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                })}
              </div>
            );
          }

          // 単位が日の場合
          if (changeableUntil >= 86400) {
            return (
              <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
                {intl.formatMessage(
                  {
                    defaultMessage: '予約日時の{days}日前まで',
                    id: 'B1Xk7s',
                    description:
                      'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                  },
                  {
                    days: changeableUntil / 86400,
                  },
                )}
              </div>
            );
          }

          return (
            <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
              {intl.formatMessage(
                {
                  defaultMessage: '予約日時の{hours}時間前まで',
                  id: 'qxFPeF',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                },
                {
                  hours: changeableUntil / 3600,
                },
              )}
            </div>
          );
        })()}
      />

      <RSDescriptionListItem
        term={intl.formatMessage({
          defaultMessage: 'キャンセル',
          id: 'XhhDmh',
          description:
            'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
        })}
        description={(() => {
          if (cancelableUntil === null) {
            return (
              <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
                {intl.formatMessage({
                  defaultMessage: 'キャンセルを受け付けておりません。',
                  id: 'ZfEXkd',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                })}
              </div>
            );
          }

          if (cancelableUntil === 0) {
            return (
              <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
                {intl.formatMessage({
                  defaultMessage: '予約日時まで',
                  id: '375mOQ',
                  description:
                    'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                })}
              </div>
            );
          }

          // 単位が日の場合
          if (cancelableUntil >= 86400) {
            return (
              <div>
                <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
                  {intl.formatMessage(
                    {
                      defaultMessage: '予約日時の{days}日前まで',
                      id: 'B1Xk7s',
                      description:
                        'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                    },
                    {
                      days: cancelableUntil / 86400,
                    },
                  )}
                </div>
                {isPrepaymentEnabled && (
                  <div className="rs-font text-13 font-normal text-gray-1000 pc:text-12">
                    {intl.formatMessage({
                      defaultMessage:
                        '※ただし、事前決済の場合、キャンセル期限を過ぎていなくても、予約から 25日 が過ぎるとキャンセルできません',
                      id: 'FemnPc',
                      description:
                        'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                    })}
                  </div>
                )}
              </div>
            );
          }

          return (
            <div>
              <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
                {intl.formatMessage(
                  {
                    defaultMessage: '予約日時の{hours}時間前まで',
                    id: 'qxFPeF',
                    description:
                      'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                  },
                  {
                    hours: cancelableUntil / 3600,
                  },
                )}
              </div>
              {isPrepaymentEnabled && (
                <div className="rs-font text-13 font-normal text-gray-1000 pc:text-12">
                  {intl.formatMessage({
                    defaultMessage:
                      '※ただし、事前決済の場合、キャンセル期限を過ぎていなくても、予約から 25日 が過ぎるとキャンセルできません',
                    id: 'FemnPc',
                    description:
                      'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList/index.tsx',
                  })}
                </div>
              )}
            </div>
          );
        })()}
      />
    </RSDescriptionList>
  );
};

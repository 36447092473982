import React from 'react';
import css from './SiteFooter.module.scss';

const SiteFooter: React.FC = () => {
  return (
    <div className={css.SiteFooter}>
      <div className={css.SiteFooter__Inner}>
        <div className={css.SiteFooter__Section}>
          <div className={css.SiteFooter__NavBox}>
            <nav className={css.SiteFooter__Nav}>
              <p className={css.SiteFooter__NavTitle}>関連サービス</p>
              <ul className={css.SiteFooter__NavList}>
                <li className={css.SiteFooter__NavListItem}>
                  <a
                    href="https://stores.jp/ec?source=internal&medium=referral&campaign=re2ec_footer"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={css.SiteFooter__NavListLink}
                  >
                    STORES（ネットショップ）
                  </a>
                </li>
                <li className={css.SiteFooter__NavListItem}>
                  <a
                    href="https://coiney.com?source=internal&medium=referral&campaign=re2pa_footer"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={css.SiteFooter__NavListLink}
                  >
                    STORES 決済（キャッシュレス決済）
                  </a>
                </li>
                <li className={css.SiteFooter__NavListItem}>
                  <a
                    href="https://stores.jp/regi?source=internal&medium=referral&campaign=re2rg_footer"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={css.SiteFooter__NavListLink}
                  >
                    STORES レジ（POSレジ）
                  </a>
                </li>
                <li className={css.SiteFooter__NavListItem}>
                  <a
                    href="https://stores.jp/brandedapp"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={css.SiteFooter__NavListLink}
                  >
                    STORES ブランドアプリ (店舗アプリ作成)
                  </a>
                </li>
              </ul>
            </nav>
            <nav className={css.SiteFooter__Nav}>
              <p className={css.SiteFooter__NavTitle}>お役立ちコンテンツ</p>
              <ul className={css.SiteFooter__NavList}>
                <li className={css.SiteFooter__NavListItem}>
                  <a
                    href="https://officialmag.stores.jp"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={css.SiteFooter__NavListLink}
                  >
                    STORES Magazine
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className={css.SiteFooter__Section}>
          <div className={css.SiteFooter__BottomNav}>
            <nav className={css.SiteFooter__Nav}>
              <ul className={css.SiteFooter__BottomNavList}>
                <li className={css.SiteFooter__BottomNavListItem}>
                  <a
                    href="https://st.inc"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={css.SiteFooter__NavListLink}
                  >
                    運営会社
                  </a>
                </li>
                <li className={css.SiteFooter__BottomNavListItem}>
                  <a
                    href="https://jobs.st.inc"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={css.SiteFooter__NavListLink}
                  >
                    採用情報
                  </a>
                </li>
                <li className={css.SiteFooter__BottomNavListItem}>
                  <a
                    href="https://st.inc/news"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={css.SiteFooter__NavListLink}
                  >
                    お知らせ
                  </a>
                </li>
              </ul>
            </nav>
            <p className={css.SiteFooter__Copyright}>STORES, Inc.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteFooter;

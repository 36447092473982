import { SWRResponse, Key } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { AxiosError } from 'axios';

import { Response$getReservationFlowVersion$Status$200 } from '../../../../openapi/generated/apiClient';
import { useClientSideApiClient } from '../../../../openapi/axiosClient';

export type UseGetReservationFlowMeValue = SWRResponse<
  Response$getReservationFlowVersion$Status$200['application/json'],
  AxiosError
>;

export const getReservationFlowVersionKey: (
  merchant_public_id: string,
  resource_public_id: string,
) => Key = (merchant_public_id, resource_public_id) => {
  return {
    path: `/api/reservation_flow/merchants/{merchant_public_id}/resources/{resource_public_id}/reservation_flow_version`,
    parameter: {
      merchant_public_id,
      resource_public_id,
    },
  };
};

export const useGetReservationFlowVersion: (
  merchant_public_id: string,
  resource_public_id?: string, // resource_public_id が確定するまで存在しないケース（カレンダー）があるため、optional にして不要な fetch をしないようにする
) => UseGetReservationFlowMeValue = (
  merchant_public_id,
  resource_public_id,
) => {
  const client = useClientSideApiClient();
  const key = resource_public_id
    ? getReservationFlowVersionKey(merchant_public_id, resource_public_id)
    : null;

  const fetcher: () => Promise<
    Response$getReservationFlowVersion$Status$200['application/json']
  > = async () => {
    if (!resource_public_id) {
      // この場合 key が null を返し fetch されないはず。想定外の動作としてエラーを投げる
      throw new Error('resource_public_id is required');
    }

    return client.getReservationFlowVersion({
      parameter: {
        merchant_public_id: merchant_public_id,
        resource_public_id: resource_public_id,
      },
    });
  };

  // 切り替え時に不意にリダイレクトするのを防ぐためrevalidateしない
  // 未ログイン時などに401エラーが発生する可能性があるため、shouldRetryOnErrorをfalseに設定する
  return useSWRImmutable(key, fetcher, {
    shouldRetryOnError: false,
  });
};

import Head from 'next/head';
import * as React from 'react';
import { PaginationEntity } from '../../interfaces/explore/PaginationEntity';
import { GA4Tag } from '../utils/GTMUtils';

interface Props {
  title: string;
  description?: string;
  thumbnailUrl: string;
  keywords: string;
  currentUrl: string;
  pagination?: PaginationEntity;
  noIndex: boolean;
  opgDisabled: boolean;
  googleAnalyticsFourTrackingId?: string;
  faviconImageUrl?: string;
}

// 環境ごとに変わるものだが、本番環境以外では重要ではないので定数で書いている
export const COUBIC_ORIGIN = 'https://coubic.com';

/**
 * @deprecated
 * 新予約フローなど新しいページはこちらを使う
 * src/components/layouts/MetaTag/MetaTag.tsx
 */
const MetaTags = (initProps: Partial<Props>) => {
  const defaultProps: Props = {
    title: 'Coubic (クービック) | 人気サービスの検索・予約サイト',
    description:
      '月間250万人以上が利用する検索・予約サイト「クービック」では、フィットネスジム・ヨガ・ピラティス・リラクなど様々なサービスを簡単に予約できます。事業者様は無料でサービス情報を掲載できると共に、180以上の業種に対応した予約システムを7日間無料でご利用頂けます。',
    thumbnailUrl:
      'https://assets.coubic.com/images/shared/merchant_categories/4/600x400.png',
    keywords: '予約, クービック',
    currentUrl: COUBIC_ORIGIN,
    noIndex: false,
    opgDisabled: false,
  };

  const {
    title,
    description,
    thumbnailUrl,
    keywords,
    currentUrl,
    pagination,
    noIndex,
    opgDisabled,
    googleAnalyticsFourTrackingId,
    faviconImageUrl,
  } = {
    ...defaultProps,
    ...initProps,
  };

  const canonicalUrl = currentUrl
    .replace(/\?page=1$/g, '')
    .replace(/\/\?/g, '?')
    .replace(/\/$/, '');
  const baseUrl = currentUrl.replace(/\?.*$/, '');
  const robotsContent = noIndex ? 'noindex, follow, noarchive' : 'noarchive';

  const escapedDescription = description?.replace(/\s+/g, ' ');

  return (
    <>
      {googleAnalyticsFourTrackingId && (
        <GA4Tag
          googleAnalyticsFourTrackingId={googleAnalyticsFourTrackingId}
          pageTitle={title}
        />
      )}
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <title>{title}</title>
        <meta content={robotsContent} name="robots" />
        <meta name="keywords" content={keywords} />
        <meta name="description" content={escapedDescription} />
        {!opgDisabled && (
          <>
            <meta property="og:site_name" content={title} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={escapedDescription} />
            <meta property="og:image" content={thumbnailUrl} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={escapedDescription} />
            <meta name="twitter:image" content={thumbnailUrl} />
          </>
        )}
        <link href={canonicalUrl} rel="canonical" />
        <link
          rel="icon"
          type="imae/x-icon"
          href={faviconImageUrl ?? '/static/favicon.ico'}
        />
        <link
          rel="shortcut icon"
          href={faviconImageUrl ?? '/static/favicon.ico'}
        />
        {pagination && pagination.currentPage < pagination.totalPages && (
          <link
            rel="next"
            href={`${baseUrl}?page=${pagination.currentPage + 1}`}
          />
        )}
        {pagination && pagination.currentPage !== 1 && (
          <link
            rel="prev"
            href={`${baseUrl}?page=${pagination.currentPage - 1}`}
          />
        )}
      </Head>
    </>
  );
};

export default MetaTags;

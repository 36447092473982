import { useEffect, useRef } from 'react';
import { useUserMe } from '../api/me';
import * as gtag from '../../utils/gtag';

declare global {
  interface Window {
    // for GTM
    gtmDataLayer: Record<string, unknown>[];

    // for GA4
    dataLayer: Record<string, unknown>[];
  }
}

export const usePushMerchantDataLayerEffect = (params: {
  visit_merchant_id?: number;
}) => {
  const { user, isUserLoading } = useUserMe();
  const loginMerchantId = user?.role === 'admin' ? user.id : undefined;

  useEffect(() => {
    if (isUserLoading) return;
    window.gtmDataLayer = window.gtmDataLayer || [];
    window.gtmDataLayer.push({
      login_user_id: user?.id,
      visit_merchant_id: params.visit_merchant_id,
      login_merchant_id: loginMerchantId,
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      login_user_id: user?.id,
      visit_merchant_id: params.visit_merchant_id,
      login_merchant_id: loginMerchantId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isUserLoading]);
};

export const usePushPurchaseEventToDataLayer = (params: {
  productId: string;
  customerId?: string;
}) => {
  const isPurchaseEventSent = useRef<boolean>(false);

  useEffect(() => {
    if (!isPurchaseEventSent.current) {
      gtag.purchaseEvent({
        step: 'completion',
        productPublicId: params.productId,
        customerPublicId: params.customerId,
      });
      isPurchaseEventSent.current = true;
    }
  });
};

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

// https://github.com/heyinc/rsv-issues/issues/16967
const HIDE_LOGO_TARGET_MERCHANT = ['changeclothes']; // フッターのロゴを隠す場合はここにpublic_idを追加

export const useHideStoresLogo = () => {
  const [isHiddenStoresLogo, setIsHiddenStoresLogo] = useState(true); // 初期ローディング時にfalseだと一瞬見えてしまうため最初はtrue
  const router = useRouter();

  useEffect(() => {
    if ('publicId' in router.query && router.query.publicId) {
      const isMatchSpecificMerchant = HIDE_LOGO_TARGET_MERCHANT.includes(
        router.query.publicId.toString(),
      );

      setIsHiddenStoresLogo(isMatchSpecificMerchant);
    } else {
      setIsHiddenStoresLogo(false);
    }
  }, [router]);

  return { isHiddenStoresLogo };
};

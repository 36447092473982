import * as React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { filterXSS } from 'xss';
import StringUtils from '../utils/StringUtils';

const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis);

// SingleLineTruncateは responsiveHOC()(LineEllipsis) をそのまま使う
const SingleLineTruncate = responsiveHOC()(LinesEllipsis);

interface MultipleLineProps extends React.HTMLProps<HTMLDivElement> {
  maxLine: number;
  children?: never;
  text: string;
  onReflow?: ({ clamped, text }: { clamped?: boolean; text?: string }) => void;
}

// MultipleLineTruncateは渡された文字列をエスケープした後に、改行を<br>タグに変換して表示します
const MultipleLineTruncate = ({
  className,
  maxLine,
  onReflow,
  text,
}: MultipleLineProps) => {
  // 先にエスケープしてしまう
  const escaped = filterXSS(text);
  // 改行を<br>に変更
  const unsafeHTML = StringUtils.nl2brString(escaped);

  return (
    <ResponsiveHTMLEllipsis
      className={className}
      unsafeHTML={unsafeHTML}
      maxLine={maxLine}
      ellipsis="..."
      basedOn="letters"
      onReflow={onReflow}
    />
  );
};

export { MultipleLineTruncate, SingleLineTruncate };

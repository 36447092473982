import { useCallback } from 'react';
import useSWR, { SWRResponse, Key, useSWRConfig } from 'swr';
import { AxiosError } from 'axios';

import {
  Parameter$getReservationFlowEventSchemePaymentMethodList,
  Response$getReservationFlowEventSchemePaymentMethodList$Status$200,
} from '../../../../openapi/generated/apiClient';
import { useClientSideApiClient } from '../../../../openapi/axiosClient';

export interface UseGetReservationFlowEventSchemePaymentMethodListParams {
  parameter: Parameter$getReservationFlowEventSchemePaymentMethodList;
}

export type UseGetReservationFlowEventSchemePaymentMethodListValue =
  SWRResponse<
    Response$getReservationFlowEventSchemePaymentMethodList$Status$200['application/json'],
    AxiosError
  >;

export const getGetReservationFlowEventSchemePaymentMethodListKey: (params: {
  parameter: Parameter$getReservationFlowEventSchemePaymentMethodList;
}) => Key = ({ parameter }) => {
  return {
    path: '/api/reservation_flow/merchants/{merchant_public_id}/event_scheme/resources/{resource_public_id}/payment_method_list',
    parameter,
  };
};

export const useGetReservationFlowEventSchemePaymentMethodList: (
  params: UseGetReservationFlowEventSchemePaymentMethodListParams,
) => UseGetReservationFlowEventSchemePaymentMethodListValue = ({
  parameter,
}) => {
  const client = useClientSideApiClient();
  const key = getGetReservationFlowEventSchemePaymentMethodListKey({
    parameter,
  });

  const fetcher: () => Promise<
    Response$getReservationFlowEventSchemePaymentMethodList$Status$200['application/json']
  > = async () => {
    try {
      const result =
        await client.getReservationFlowEventSchemePaymentMethodList({
          parameter,
        });

      return result;
    } catch (error) {
      throw error;
    }
  };

  return useSWR(key, fetcher);
};

export interface UseRevalidateGetReservationFlowEventSchemePaymentMethodListParams {
  parameter: Parameter$getReservationFlowEventSchemePaymentMethodList;
}

export interface UseRevalidateGetReservationFlowEventSchemePaymentMethodListValue {
  revalidateGetReservationFlowEventSchemePaymentMethodList: () => Promise<void>;
}

export const useRevalidateGetReservationFlowEventSchemePaymentMethodList: (
  params: UseRevalidateGetReservationFlowEventSchemePaymentMethodListParams,
) => UseRevalidateGetReservationFlowEventSchemePaymentMethodListValue = ({
  parameter,
}) => {
  const { mutate } = useSWRConfig();

  const revalidateGetReservationFlowEventSchemePaymentMethodList =
    useCallback(async () => {
      await mutate(
        getGetReservationFlowEventSchemePaymentMethodListKey({ parameter }),
      );
    }, [parameter, mutate]);

  return { revalidateGetReservationFlowEventSchemePaymentMethodList };
};

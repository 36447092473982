import { useEffect, useRef, useState } from 'react';

export const useMediaQuery: (query: string) => boolean = (query) => {
  const supportsMatchMedia =
    typeof window !== 'undefined' && typeof window.matchMedia === 'function';
  const [matches, setMatches] = useState(() =>
    supportsMatchMedia ? window.matchMedia(query).matches : false,
  );

  const ref = useRef(query);
  useEffect(() => {
    ref.current = query;
  });
  const prevQuery = ref.current;

  useEffect(() => {
    if (!supportsMatchMedia) {
      return;
    }

    const mq = window.matchMedia(query);
    const onChange = (evt: MediaQueryListEvent) => {
      setMatches(evt.matches);
    };

    mq.addEventListener('change', onChange);

    // 引数に変更あった際にも更新
    if (prevQuery !== query) {
      setMatches(window.matchMedia(query).matches);
    }

    return () => {
      mq.removeEventListener('change', onChange);
    };
  }, [supportsMatchMedia, query, prevQuery]);

  return matches;
};

import nookies from 'nookies';
import { NextPageContext } from 'next';

const path = '/';

export const getCookie = (key?: string, ctx?: NextPageContext) =>
  key ? nookies.get(ctx)[key] : undefined;

export const setCookie = (
  key: string,
  value: string | number,
  ctx?: NextPageContext | null,
  options?: { maxAgeInDays?: number },
) => {
  const maxAgeInDays = options?.maxAgeInDays || 7;

  nookies.set(ctx, key, value.toString(), {
    maxAge: maxAgeInDays * 24 * 60 * 60, // 7 days
    path,
  });
};

export const removeCookie = (key: string, ctx?: NextPageContext) => {
  nookies.destroy(ctx, key, { path });
};

import classNames from 'classnames';
import dynamic from 'next/dynamic';
import React from 'react';
import MerchantReservationButton from './components/MerchantReservationButton';

import css from './MerchantHeader.module.scss';
import { MerchantFavoriteButtonProps } from '../FavoriteButton';
import { MerchantDetail } from '../../../interfaces/merchants';
import { useUserMe } from '../../../hooks/api/me';

const MerchantFavoriteButton = dynamic<MerchantFavoriteButtonProps>(
  import('../FavoriteButton').then((c) => c.MerchantFavoriteButton),
  {
    loading: () => null,
    ssr: false,
  },
);

const bookingButtonIsActiveBy = (
  merchant: MerchantDetail,
  bookingPageIsBookable?: boolean,
) => {
  // explore only merchant.
  if (
    merchant.merchant_setting.is_explore_only &&
    merchant.merchant_setting.explore_only_merchant_reservation_page_url !== ''
  ) {
    return true;
  }
  // bookable merchant on coubic.
  if (bookingPageIsBookable) {
    return true;
  }
  // others.
  return false;
};

export interface MerchantHeaderProps {
  merchantDetail: MerchantDetail;
  bookingPageIsBookable?: boolean;
  showCoverImage?: boolean;
}

interface Props extends MerchantHeaderProps {
  className?: string;
}

const MerchantHeader = ({
  className,
  merchantDetail,
  bookingPageIsBookable,
  showCoverImage,
}: Props) => {
  const classes = classNames(css.MerchantHeader, className);
  const { isLoggedIn } = useUserMe();
  const bookingButtonIsActive = bookingButtonIsActiveBy(
    merchantDetail,
    bookingPageIsBookable,
  );

  const MerchantHeaderWithCoverImage = (
    <header className={classes}>
      <div
        className={css.MerchantHeaderWithCoverImage__Background}
        style={{
          backgroundImage: `url(${merchantDetail.cover_image.url})`,
        }}
      />
      <div className={css.MerchantHeaderWithCoverImage__SummaryBg}>
        <div className={css.MerchantHeaderWithCoverImage__Summary}>
          <a
            className={css.MerchantHeaderWithCoverImage__SummaryLink}
            href={`/${merchantDetail.public_id}`}
          >
            {merchantDetail.show_profile_image_on_site && (
              <div
                className={css.MerchantHeaderWithCoverImage__SummaryThumbnail}
              >
                <img
                  className={css.MerchantHeaderWithCoverImage__ThumbnailImage}
                  src={merchantDetail.thumbnail.c120x120.url}
                  alt="merchant-thumbnail"
                />
              </div>
            )}
            <div className={css.MerchantHeaderWithCoverImage__SummaryText}>
              <h1 className={css.MerchantHeaderWithCoverImage__Name}>
                {merchantDetail.display_name}
              </h1>
            </div>
          </a>
          <div className={css.MerchantHeaderWithCoverImage__SummaryRight}>
            {bookingButtonIsActive && (
              <div className={css.MerchantHeaderWithCoverImage__SummaryBook}>
                <MerchantReservationButton merchant={merchantDetail} />
              </div>
            )}
            <div className={css.MerchantHeaderWithCoverImage__SummaryFavorite}>
              <MerchantFavoriteButton
                publicId={merchantDetail.public_id}
                isLoggedIn={isLoggedIn}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );

  const MerchantHeaderWithoutCoverImage = (
    <header className={classes}>
      <div className={css.MerchantHeaderWithoutCoverImage__SummaryBg}>
        <div className={css.MerchantHeaderWithoutCoverImage__Summary}>
          <a
            className={css.MerchantHeaderWithoutCoverImage__SummaryLink}
            href={`/${merchantDetail.public_id}`}
          >
            {merchantDetail.show_profile_image_on_site && (
              <div
                className={
                  css.MerchantHeaderWithoutCoverImage__SummaryThumbnail
                }
              >
                <img
                  className={
                    css.MerchantHeaderWithoutCoverImage__ThumbnailImage
                  }
                  src={merchantDetail.thumbnail.c120x120.url}
                  alt="merchant-thumbnail"
                />
              </div>
            )}
            <div className={css.MerchantHeaderWithoutCoverImage__SummaryText}>
              <h1 className={css.MerchantHeaderWithoutCoverImage__Name}>
                {merchantDetail.display_name}
              </h1>
            </div>
          </a>
          <div className={css.MerchantHeaderWithoutCoverImage__SummaryRight}>
            {bookingButtonIsActive && (
              <div className={css.MerchantHeaderWithoutCoverImage__SummaryBook}>
                <MerchantReservationButton merchant={merchantDetail} />
              </div>
            )}
            <div
              className={css.MerchantHeaderWithoutCoverImage__SummaryFavorite}
            >
              <MerchantFavoriteButton
                publicId={merchantDetail.public_id}
                isLoggedIn={isLoggedIn}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );

  return showCoverImage
    ? MerchantHeaderWithCoverImage
    : MerchantHeaderWithoutCoverImage;
};

export default MerchantHeader;

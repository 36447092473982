import axios from 'axios';
import { apiRoot } from '../../utils/apiroot';
import securedAxios, { newSecuredAxios } from '../../utils/secured-axios';
import { MerchantDetail } from '../../interfaces/merchants';
import { MerchantReviewsResponse } from '../../interfaces/merchants/reviews';
import {
  MerchantProduct,
  MerchantProductIndex,
} from '../../interfaces/merchants/products';
import { MerchantBlogIndex } from '../../interfaces/merchants/blogs';
import User, { UserRole } from '../../domain/entities/User';
import { coubicLocale } from '../../utils/i18n';

export const fetchMerchantDetail = async ({
  session,
  token,
  requestId,
  merchantPublicId,
}: {
  session: string;
  token: string;
  requestId: string;
  merchantPublicId: string;
}) => {
  const client = newSecuredAxios({ session, token, requestId });
  try {
    const res = await client.get<MerchantDetail | undefined>(
      `${apiRoot()}/merchants/${merchantPublicId}`,
    );
    const merchant = res.status === 200 ? res.data : undefined;
    const siteUnpublishedHeader = res.headers['x-coubic-site-unpublished'];
    const isUnpublishedSite = siteUnpublishedHeader
      ? Boolean(siteUnpublishedHeader)
      : false;
    return {
      merchant,
      isUnpublishedSite,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // axiosで4xx系はthrowしないようにした方が良いが、影響範囲が大きいのでerrorからresponse headerを取り出している
      const siteUnpublishedHeader =
        error.response?.headers['x-coubic-site-unpublished'];
      const isUnpublishedSite = siteUnpublishedHeader
        ? Boolean(siteUnpublishedHeader)
        : false;
      return {
        merchant: undefined,
        isUnpublishedSite,
      };
    } else {
      throw error;
    }
  }
};

export const fetchMerchantReviewsForSSR = async ({
  session,
  token,
  requestId,
  merchantPublicId,
  page,
  perPage,
}: {
  session: string;
  token: string;
  requestId: string;
  merchantPublicId: string;
  page: number;
  perPage: number;
}) => {
  const client = newSecuredAxios({ session, token, requestId });
  try {
    const { data } = await client.get<MerchantReviewsResponse>(
      `${apiRoot()}/merchants/${merchantPublicId}/reviews?page=${page}&per_page=${perPage}`,
    );

    return data;
  } catch (error) {
    return undefined;
  }
};

export const fetchMerchantReviews = async (
  merchantPublicId: string,
  page: number,
  perPage: number,
) => {
  const { data } = await securedAxios.get<MerchantReviewsResponse>(
    `${apiRoot()}/merchants/${merchantPublicId}/reviews?page=${page}&per_page=${perPage}`,
  );

  return data;
};

export const fetchMerchantProducts = async ({
  session,
  token,
  requestId,
  merchantPublicId,
  page,
  perPage,
}: {
  session: string;
  token: string;
  requestId: string;
  merchantPublicId: string;
  page: number;
  perPage: number;
}) => {
  const client = newSecuredAxios({ session, token, requestId });
  const { data } = await client.get<MerchantProductIndex>(
    `${apiRoot()}/merchants/${merchantPublicId}/products?page=${page}&per_page=${perPage}`,
  );

  return data;
};

export const fetchProductDetail = async ({
  session,
  token,
  requestId,
  merchantPublicId,
  productPublicId,
}: {
  session: string;
  token: string;
  requestId: string;
  merchantPublicId: string;
  productPublicId: string;
}) => {
  const client = newSecuredAxios({ session, token, requestId });
  const { data } = await client.get<MerchantProduct>(
    `${apiRoot()}/merchants/${merchantPublicId}/products/${productPublicId}`,
  );

  return data;
};

export const fetchMerchantBlogs = async ({
  session,
  token,
  requestId,
  merchantPublicId,
  blogPageNumber,
}: {
  session: string;
  token: string;
  requestId: string;
  merchantPublicId: string;
  blogPageNumber: string;
}) => {
  const client = newSecuredAxios({ session, token, requestId });
  const { data } = await client.get<MerchantBlogIndex>(
    `${apiRoot()}/merchants/${merchantPublicId}/posts?page=${blogPageNumber}`,
  );

  return data;
};

export type PublicStaff = {
  canonical_id: string;
  name: string;
  image_url: string;
  sort_order: number;
  updated_at: number;
};

type VoucherAssignedResource = {
  public_id: string;
  name: string;
};

export type BookingCalendarMeta = {
  public_id: string;
  name: string;
  assigned_staff_members: PublicStaff[];
  assigned_vouchers: VoucherAssignedResource[];
};

export interface MeResponse {
  credit_card?: {
    canonical_id: string;
    tail_digits: string;
    expire_month: string;
    expire_year: string;
  };
  display_name: string;
  email: string;
  firstname: string;
  id: number;
  is_email_verified: boolean;
  lastname: string;
  phone_number?: string;
  public_id: string;
  thumbnail: {
    c120x120: { url: string };
    p1280x1280: { url: string };
    square: { url: string };
    thumb: { url: string };
  };
  role: UserRole;
  locale: coubicLocale;
  supervisor_proxy_signed_in: boolean;
}

export const fetchMeUser = async () => {
  try {
    const res = await securedAxios.get<MeResponse>(`${apiRoot()}/users/me`);
    const me = res.data;
    // UserContextのUser型に合わせる
    return new User({
      id: me.id,
      displayName: me.display_name,
      publicId: me.public_id,
      thumbnailUrl: {
        c120x120: me.thumbnail.c120x120.url,
        p1280x1280: me.thumbnail.p1280x1280.url,
        square: me.thumbnail.square.url,
        thumb: me.thumbnail.thumb.url,
      },
      email: me.email,
      role: me.role,
    });
  } catch (error) {
    return undefined;
  }
};

export const fetchCsrfToken = async () => {
  const { data } = await securedAxios.get<{ csrfToken: string }>('/_/csrf');
  return data.csrfToken;
};

export { apiRoot };

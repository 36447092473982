import classNames from 'classnames';
import * as React from 'react';
import { useIntl } from 'react-intl';

import Image400 from '../../../public/static/error/400.png';
import Image401 from '../../../public/static/error/401.png';
import Image403 from '../../../public/static/error/403.png';
import Image404 from '../../../public/static/error/404.png';
import Image50x from '../../../public/static/error/50x.png';
import BaseLayout from '../../components/layouts/BaseLayout';
import css from './Error.module.scss';
import { useCoubicApp } from '../../hooks/useCoubicApp';

interface Props {
  className?: string;
  statusCode: number;
}

const ErrorTemplate = ({ className, statusCode }: Props) => {
  const intl = useIntl();
  const classes = classNames(css.Error, className);
  const { isCoubicApp } = useCoubicApp();

  return (
    <BaseLayout isCoubicApp={isCoubicApp}>
      <div className={classes}>
        <div className={css.Error__Message}>
          <p className={css.Error__SubTitle}>
            {intl.formatMessage({ id: 'errors.title' })}
          </p>
          <p className={css.Error__Title}>{statusCode}</p>
          <p className={css.Error__Text}>
            {intl.formatMessage({
              id: `errors.message${statusCode}`,
            })}
          </p>
          <figure className={css.Error__Image}>
            {statusCode === 400 && (
              <img src={Image400} alt={`${statusCode} Error`} />
            )}
            {statusCode === 401 && (
              <img src={Image401} alt={`${statusCode} Error`} />
            )}
            {statusCode === 403 && (
              <img src={Image403} alt={`${statusCode} Error`} />
            )}
            {statusCode === 404 && (
              <img src={Image404} alt={`${statusCode} Error`} />
            )}
            {statusCode >= 500 && (
              <img src={Image50x} alt={`${statusCode} Error`} />
            )}
          </figure>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ErrorTemplate;

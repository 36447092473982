import classNames from 'classnames';
import { camelCase, upperFirst } from 'lodash-es';
import * as React from 'react';
import { useIntl } from 'react-intl';

import Tag from '../../../../../atoms/Tag';

import css from './MerchantTag.module.scss';

interface Props {
  status: string;
}

const MerchantTag = ({ status }: Props) => {
  const intl = useIntl();
  const classes = (courseStatus: string) =>
    classNames(
      css.MerchantTag,
      css[`is${upperFirst(camelCase(courseStatus))}`],
    );

  return (
    <Tag className={classes(status)}>
      {intl.formatMessage({
        id: `merchants.bookingPages.status.${camelCase(status)}`,
      })}
    </Tag>
  );
};

export default MerchantTag;

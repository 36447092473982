import { AnyAction } from 'redux';
import { MerchantsState } from '../../types/merchants';

export const INIT = 'merchant/init';
export const LIKE = 'merchant/like';
export const DISLIKE = 'merchant/dislike';
export const LOADING = 'merchant/loading';

const initialState: MerchantsState = {
  loading: false,
  merchants: [],
};

export default (
  state: MerchantsState = initialState,
  action: AnyAction,
): MerchantsState => {
  switch (action.type) {
    case INIT: {
      const { publicId, liked, favoriteCount } = action.payload;
      const isExist = !!state.merchants.find(
        (merchant) => merchant.publicId === publicId,
      );

      // 新規追加
      if (!isExist) {
        return {
          ...state,
          loading: false,
          merchants: [
            ...state.merchants,
            {
              publicId,
              liked,
              favoriteCount,
            },
          ],
        };
      }

      // 既存のmerchantは更新
      return {
        ...state,
        loading: false,
        merchants: state.merchants.map((merchant) => {
          if (merchant.publicId !== publicId) return merchant;
          return {
            ...merchant,
            liked,
            favoriteCount,
          };
        }),
      };
    }
    case LIKE: {
      const { publicId } = action.payload;
      const merchant = state.merchants.find(
        (merchant) => merchant.publicId === publicId,
      );
      if (!merchant) return state;

      const favoriteCount = merchant.favoriteCount + 1;
      return {
        ...state,
        loading: false,
        merchants: state.merchants.map((merchant) => {
          if (merchant.publicId !== publicId) return merchant;
          return {
            ...merchant,
            liked: true,
            favoriteCount,
          };
        }),
      };
    }
    case DISLIKE: {
      const { publicId } = action.payload;
      const merchant = state.merchants.find(
        (merchant) => merchant.publicId === publicId,
      );
      if (!merchant) return state;

      const favoriteCount = merchant.favoriteCount - 1;
      return {
        ...state,
        loading: false,
        merchants: state.merchants.map((merchant) => {
          if (merchant.publicId !== publicId) return merchant;
          return {
            ...merchant,
            liked: false,
            favoriteCount,
          };
        }),
      };
    }
    case LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    default:
      return state;
  }
};

export interface UserInit {
  id: number;
  displayName: string;
  publicId: string;
  thumbnailUrl: {
    c120x120: string;
    p1280x1280: string;
    square: string;
    thumb: string;
  };
  email: string;
  role: UserRole;
}

export type UserRole = 'end_user' | 'staff' | 'admin' | 'org_admin';

export default class User {
  public readonly id: number;
  public readonly displayName: string;
  public readonly publicId: string;
  public readonly email: string;
  public readonly thumbnailUrl: {
    c120x120: string;
    p1280x1280: string;
    square: string;
    thumb: string;
  };
  public readonly role: UserRole;

  constructor(init: UserInit) {
    this.id = init.id;
    this.displayName = init.displayName;
    this.publicId = init.publicId;
    this.thumbnailUrl = init.thumbnailUrl;
    this.role = init.role;
    this.email = init.email;
  }
}

interface UserSnakeInit {
  id: number;
  display_name: string;
  public_id: string;
  thumbnail: {
    c120x120: { url: string };
    p1280x1280: { url: string };
    square: { url: string };
    thumb: { url: string };
  };
  role: string;
  email: string;
}

export function initializeFromSnake(init: UserSnakeInit): User {
  return new User({
    id: init.id,
    displayName: init.display_name,
    publicId: init.public_id,
    thumbnailUrl: {
      c120x120: init.thumbnail.c120x120.url,
      p1280x1280: init.thumbnail.p1280x1280.url,
      square: init.thumbnail.square.url,
      thumb: init.thumbnail.thumb.url,
    },
    email: init.email,
    role: init.role as UserRole,
  });
}

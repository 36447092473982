import { useEffect, useMemo, useState } from 'react';
import { checkIsCoubicApp } from '../utils/mobileApp';
import nookies from 'nookies';

// 予約者アプリを判定する
export const useCoubicApp = () => {
  const [userAgent, setUserAgent] = useState<{
    coubicUAType?: string;
    user_agent?: string;
  }>({});

  const cookies = nookies.get();
  useEffect(
    () =>
      setUserAgent({
        coubicUAType: cookies.coubicUAType,
        user_agent: cookies.user_agent,
      }),
    [cookies.coubicUAType, cookies.user_agent],
  );

  const isCoubicApp = useMemo(
    () => checkIsCoubicApp(userAgent.coubicUAType, userAgent.user_agent),
    [userAgent.coubicUAType, userAgent.user_agent],
  );

  return { isCoubicApp };
};

import { InferGetServerSidePropsType, NextPage } from 'next';
import React, { useEffect, FC } from 'react';
import { useIntl } from 'react-intl';

import JsonLd from '../../../components/layouts/JsonLd';
import MetaTags, { COUBIC_ORIGIN } from '../../../components/layouts/MetaTags';
import MerchantCalendarPage from '../../../templates/merchants/services/IndexTemplate/MerchantCalendarPage';
import { getCategory } from '../../../infrastructure/api/backend/explore_api';
import { CategoryEntity } from '../../../interfaces/explore/CategoryEntity';
import { MerchantDetail } from '../../../interfaces/merchants';
import { init } from '../../../redux/actions/merchants/services';

import { fetchMerchantDetail } from '../../../infrastructure/api';
import { injectAppContextGetServerSideProps } from '../../_app';
import ErrorTemplate from '../../../templates/ErrorTemplate';
import { MerchantSite } from '../../../components/MerchantSite';
import { usePushMerchantDataLayerEffect } from '../../../hooks/gtm';
import { useAppDispatch } from '../../../redux';

interface Props {
  merchantPublicId: string;
  merchantDetail?: MerchantDetail;
  category?: CategoryEntity;
  isUnpublishedSite: boolean;
  isCoubicApp: boolean;
}

const CalendarComponent: FC<Props> = ({
  merchantDetail,
  category,
  isCoubicApp,
}) => {
  const intl = useIntl();
  if (merchantDetail && category) {
    return (
      <>
        <MetaTags
          title={intl.formatMessage(
            { id: 'merchants.services.index.meta.title' },
            { displayName: merchantDetail.display_name },
          )}
          description={
            merchantDetail.seo_setting?.description ||
            merchantDetail.description
          }
          keywords={merchantDetail.seo_setting?.keyword || ''}
          thumbnailUrl={merchantDetail.cover_image.url || ''}
          currentUrl={`${COUBIC_ORIGIN}/${merchantDetail.public_id}/services`}
          noIndex={merchantDetail.seo_setting?.no_index}
          googleAnalyticsFourTrackingId={
            merchantDetail.available_google_analytics_four_tracking_id
          }
          faviconImageUrl={merchantDetail.favicon_image?.url}
        />
        <JsonLd merchant={merchantDetail} />
        <MerchantCalendarPage
          merchantDetail={merchantDetail}
          isCoubicApp={isCoubicApp}
        />
      </>
    );
  }
  return <ErrorTemplate statusCode={404} />;
};

const MerchantServices: NextPage<
  InferGetServerSidePropsType<typeof getServerSideProps>
> = (props: Props) => {
  const dispatch = useAppDispatch();
  const { merchantPublicId, merchantDetail, isUnpublishedSite } = props;
  useEffect(() => {
    dispatch(init(merchantPublicId, merchantDetail?.time_zone ?? 'Asia/Tokyo'));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  usePushMerchantDataLayerEffect({ visit_merchant_id: merchantDetail?.id });

  return (
    <MerchantSite
      isUnPublished={isUnpublishedSite}
      isMerchantInfoExist={!!merchantDetail}
      logoImage={merchantDetail?.logo_image || null}
      merchantPublicId={merchantDetail?.public_id || null}
      displayName={merchantDetail?.display_name || null}
      isCoubicApp={props.isCoubicApp}
    >
      <CalendarComponent {...props} />
    </MerchantSite>
  );
};

export const getServerSideProps = injectAppContextGetServerSideProps<Props>(
  async (ctx) => {
    const { query, token, session, requestId, isCoubicApp } = ctx;
    const merchantPublicId = String(query.publicId);

    const merchantDetailResponse = await fetchMerchantDetail({
      session,
      token,
      requestId,
      merchantPublicId,
    });
    const { merchant, isUnpublishedSite } = merchantDetailResponse;

    const category = merchant
      ? await getCategory(merchant.category_id)
      : undefined;
    const props: Props = {
      merchantPublicId,
      merchantDetail: merchant,
      category,
      isUnpublishedSite,
      isCoubicApp,
    };
    return { props };
  },
);

export default MerchantServices;

import React, { useCallback, useEffect, useState, FC } from 'react';

import { SpMerchantCalendarBody } from '../../../../molecules/merchant/calendar/sp/SpMerchantCalendarBody/SpMerchantCalendarBody';
import { ViewStyle } from '../../../../redux/types/merchants/services';
import { Meta, ServiceMap } from '../../../../interfaces/merchants/services';
import { useStaffBookingPageMapping } from '../../../../hooks/api/booking-calendar-meta';
import {
  ActionsCreators as actions,
  load,
} from '../../../../redux/actions/merchants/services';
import {
  doneLoading,
  hasPrevDate,
} from '../../../../molecules/merchant/calendar/calendarHelper';
import { useFilterSpCalendarBookingPages } from '../../../../hooks/sp-calendar';
import { SpAllBookingPagesCalendarHeaderOrganisim } from '../SpAllBookingPagesCalendarHeader';
import { useAppDispatch } from '../../../../redux';

type Props = {
  merchantPublicId: string;
  merchantTimeZone: string;
  baseDate: string;
  fetchedUntil: string;
  viewStyle: ViewStyle;
  meta?: Meta;
  services: ServiceMap;
};

export const SpAllBookingPagesCalendar: FC<Props> = ({
  baseDate,
  fetchedUntil,
  viewStyle,
  meta,
  services,
  merchantPublicId,
  merchantTimeZone,
}) => {
  const dispatch = useAppDispatch();
  // if baseDate is changed, load new services.
  useEffect(() => {
    load(merchantPublicId, baseDate, fetchedUntil).then(
      (ac) => ac && dispatch(ac),
    );
  }, [dispatch, baseDate, fetchedUntil, merchantPublicId]);

  const scrollNext = useCallback(() => {
    dispatch(actions.scrollNext());
  }, [dispatch]);

  const isList = viewStyle === ViewStyle.List;
  const hasPrev = hasPrevDate(baseDate);

  // body
  const isDoneLoading = doneLoading(baseDate, fetchedUntil);

  const { staffBookingPageIdsMap } =
    useStaffBookingPageMapping(merchantPublicId);

  const [selectedBookingPagePublicId, setSelectedBookingPagePublicId] =
    useState<string | undefined>(undefined);

  const [selectedStaffId, setSelectedStaffId] = useState<string | undefined>(
    undefined,
  );

  const onSelectStaff = useCallback(
    (staffId: string) => {
      setSelectedStaffId(staffId);
    },
    [setSelectedStaffId],
  );

  const selectedStaffAssignedBookingPageIds = selectedStaffId
    ? staffBookingPageIdsMap[selectedStaffId]
    : undefined;

  const filterFnByStaff = useFilterSpCalendarBookingPages(
    selectedStaffAssignedBookingPageIds,
  );
  const filterFnByBookingPage = useFilterSpCalendarBookingPages(
    selectedBookingPagePublicId ? [selectedBookingPagePublicId] : undefined,
  );

  const filteredByStaff = filterFnByStaff(services);
  const targetBookingPages = filterFnByBookingPage(filteredByStaff);

  return (
    <>
      <SpAllBookingPagesCalendarHeaderOrganisim
        merchantPublicId={merchantPublicId}
        baseDate={baseDate}
        isList={isList}
        hasPrev={hasPrev}
        onSelectStaff={onSelectStaff}
        onSelectBookingPage={setSelectedBookingPagePublicId}
      />
      <SpMerchantCalendarBody
        merchantPublicId={merchantPublicId}
        isList={isList}
        doneLoading={isDoneLoading}
        fetchNext={scrollNext}
        baseDate={baseDate}
        meta={meta}
        services={targetBookingPages}
        timeZone={merchantTimeZone}
      />
    </>
  );
};

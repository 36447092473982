import React, {
  DetailedHTMLProps,
  AnchorHTMLAttributes,
  forwardRef,
  useMemo,
} from 'react';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import classnames from 'classnames';

import styles from './index.module.css';

export type RSButtonAnchorProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  variant?: 'large' | 'basic' | 'outline' | 'destructive';
  isBlock?: boolean;
  isDisabled?: boolean;
};

export const RSButtonAnchor = forwardRef<
  HTMLAnchorElement,
  RSButtonAnchorProps
>(
  (
    {
      variant = 'basic',
      isBlock = false,
      isDisabled = false,
      children,
      ...props
    },
    ref,
  ) => {
    const { isFocusVisible, focusProps } = useFocusRing();

    const color = '#0066FF';

    const wrapperHeightClasses = useMemo(() => {
      switch (variant) {
        case 'large':
          return ['h-56'];
        case 'basic':
        case 'outline':
        case 'destructive':
          return ['h-48', 'pc:h-44'];
      }
    }, [variant]);

    const buttonSizeClasses = useMemo(() => {
      switch (variant) {
        case 'large':
          return ['px-[20px]'];
        case 'basic':
        case 'outline':
        case 'destructive':
          return ['px-2'];
      }
    }, [variant]);

    const buttonColorClasses = useMemo(() => {
      switch (variant) {
        case 'large':
          return [
            isDisabled && 'bg-gray-10',
            !isDisabled ? 'text-white' : 'text-gray-200',
            isDisabled && 'border-gray-10',
          ];
        case 'basic':
          return [
            isDisabled && 'bg-gray-10',
            !isDisabled ? 'text-white' : 'text-gray-200',
            isDisabled && 'border-gray-10',
          ];
        case 'outline':
          return [
            !isDisabled ? 'bg-white' : 'bg-gray-10',
            isDisabled && 'text-gray-200',
            !isDisabled ? 'border-gray-200' : 'border-gray-10',
          ];
        case 'destructive':
          return [
            !isDisabled ? 'bg-white' : 'bg-gray-10',
            !isDisabled ? 'text-red-700' : 'text-gray-200',
            !isDisabled ? 'border-gray-200' : 'border-gray-10',
          ];
      }
    }, [variant, isDisabled]);

    const isButtonBackgroundColorPrimary = useMemo(() => {
      if (isDisabled) {
        return false;
      }

      if (variant !== 'large' && variant !== 'basic') {
        return false;
      }

      return true;
    }, [variant, isDisabled]);

    const isButtonTextColorPrimary = useMemo(() => {
      if (isDisabled) {
        return false;
      }

      if (variant !== 'outline') {
        return false;
      }

      return true;
    }, [variant, isDisabled]);

    const isButtonBorderColorPrimary = useMemo(() => {
      if (isDisabled) {
        return false;
      }

      if (variant !== 'large' && variant !== 'basic') {
        return false;
      }

      return true;
    }, [variant, isDisabled]);

    return (
      <>
        <div
          className={classnames(
            'wrapper',
            isFocusVisible && 'focus-visible',
            !isBlock ? 'inline-block' : ['block', 'w-full'],
            'relative',
            wrapperHeightClasses,
            'rounded-15',
            'after:transition',
            // cursor-event-noneとcursor-not-allowedを同じ要素で利用できないため
            !isDisabled ? 'cursor-pointer' : 'cursor-not-allowed',
          )}
        >
          <a
            {...mergeProps(props, focusProps)}
            ref={ref}
            className={classnames(
              'button',
              isButtonBackgroundColorPrimary && 'bg-primary',
              isButtonTextColorPrimary && 'text-primary',
              isButtonBorderColorPrimary && 'border-primary',
              'relative',
              buttonSizeClasses,
              'box-border',
              'w-full',
              'h-full',
              'flex',
              'justify-center',
              'items-center',
              'border',
              'border-solid',
              'rounded-15',
              'outline-none',
              'focus:outline-none',
              buttonColorClasses,
              'no-underline',
              'z-1',
              'transition',
              !isDisabled && styles['button--default'],
              isDisabled && 'pointer-events-none',
            )}
            tabIndex={!isDisabled ? undefined : -1}
          >
            <div className="rs-font whitespace-nowrap text-16 font-bold pc:text-15">
              {children}
            </div>
          </a>
        </div>
        <style jsx>{`
          .wrapper::after {
            content: '';
            box-sizing: border-box;
            position: absolute;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            top: -4px;
            left: -4px;
            border-style: solid;
            border-width: 2px;
            border-color: transparent;
            border-radius: 18px;
          }

          .wrapper.focus-visible::after {
            border-color: ${color};
          }

          .button.bg-primary {
            background-color: ${color};
          }

          .button.text-primary {
            color: ${color};
          }

          .button.border-primary {
            border-color: ${color};
          }
        `}</style>
      </>
    );
  },
);

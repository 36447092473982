import React, { FC } from 'react';
import { clsx } from 'clsx';
import { useIntl } from 'react-intl';

import WarningIcon from '@heyinc/stand-ui-icons/svg/warning.svg';

import { RSButton } from 'src/reservation-page/RSButton';
import { useAuth } from 'src/hooks/api/auth';
import { useUserMe } from 'src/hooks/api/me';

export const SupervisorProxyHeader: FC = () => {
  const intl = useIntl();
  const { logout } = useAuth();
  const { meResponseData } = useUserMe();

  if (!meResponseData || !meResponseData.supervisor_proxy_signed_in)
    return null;

  return (
    <div
      className={clsx('h-64', 'w-full', 'flex', 'items-center', 'bg-red-700')}
    >
      <div
        className={clsx(
          'ml-2',
          'pc:ml-0',
          'pc:flex',
          'pc:flex-1',
          'pc:justify-start',
        )}
      />
      <div
        className={clsx(
          'flex',
          'flex-shrink',
          'justify-center',
          'items-center',
          'mr-2',
        )}
      >
        <WarningIcon
          className={clsx('mr-1', 'fill-white')}
          width="28"
          height="28"
          viewBox="0 0 48 48"
        />
        <div>
          <p className={clsx('text-15', 'font-bold', 'text-white', 'm-0')}>
            {intl.formatMessage({
              id: 'supervisorProxyHeader.proxyLogin',
              defaultMessage: '代理ログイン中',
            })}
          </p>
          <p className={clsx('text-13', 'font-normal', 'text-white', 'm-0')}>
            (display_name: {meResponseData?.display_name})
          </p>
        </div>
      </div>
      <div
        className={clsx(
          'my-auto',
          'flex',
          'flex-1',
          'flex-shrink-0',
          'justify-end',
        )}
      >
        <div className={clsx('ml-auto', 'mr-2')}>
          <RSButton
            variant="destructive"
            onClick={() => {
              logout().then(
                () =>
                  (location.href = `/signin/user?return_to=${location.pathname}`),
              );
            }}
          >
            {intl.formatMessage({
              id: 'supervisorProxyHeader.logout',
              defaultMessage: 'ログアウト',
            })}
          </RSButton>
        </div>
      </div>
    </div>
  );
};

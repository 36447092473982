import { apiRoot } from '../../../utils/apiroot';
import securedAxios, { newSecuredAxios } from '../../../utils/secured-axios';
import { BookingPageTagName } from '../../../domain/entities/BookingPage';
import { MerchantBookingPrimaryImage } from '../../../interfaces/merchants/bookings';

export type VoucherAssignedBookingPage = {
  public_id: string;
  name: string;
  description_text?: string;
  tag_names: BookingPageTagName[];
  lowest_price?: number;
  primary_image?: MerchantBookingPrimaryImage;
};

export type MerchantVoucher = {
  public_id: string;
  name: string;
  assigned_booking_pages: VoucherAssignedBookingPage[];
};

// clientでは使われていないのでSSR用のみを用意
export const fetchCouponDetailForSSR = async ({
  session,
  token,
  requestId,
  merchantPublicId,
  couponPublicId,
}: {
  session: string;
  token: string;
  requestId: string;
  merchantPublicId: string;
  couponPublicId: string;
}) => {
  const client = newSecuredAxios({ session, token, requestId });
  try {
    const path = `${apiRoot()}/merchants/${merchantPublicId}/vouchers/${couponPublicId}`;
    const { data } = await client.get<MerchantVoucher>(path);

    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchMerchantCoupons = async (merchantPublicId: string) => {
  const path = `${apiRoot()}/merchants/${merchantPublicId}/vouchers`;
  const { data } = await securedAxios.get<{
    vouchers: MerchantVoucher[];
  }>(path);
  return data.vouchers;
};

export const fetchMerchantCouponsForSSR = async ({
  session,
  token,
  requestId,
  merchantPublicId,
}: {
  session: string;
  token: string;
  requestId: string;
  merchantPublicId: string;
}) => {
  const client = newSecuredAxios({ session, token, requestId });
  try {
    const path = `${apiRoot()}/merchants/${merchantPublicId}/vouchers`;
    const { data } = await client.get<{
      vouchers: MerchantVoucher[];
    }>(path);
    return data.vouchers;
  } catch (error) {
    throw error;
  }
};

import Script from 'next/script';
import * as React from 'react';
import { GTM_CONFIGS } from '../../utils/gtag';

declare global {
  interface Window {
    ga4PageTitle: string;
  }
}

export const GTMContainer = () => (
  // NOTE: gtag.js に渡す data layer と区別するため、名称を gtmDataLayer とする
  <>
    {Object.values(GTM_CONFIGS).map((config) => {
      const script = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_auth=${config.auth}&gtm_preview=${config.preview}&gtm_cookies_win=x';
      f.parentNode.insertBefore(j,f);})(window,document,'script','gtmDataLayer','${config.containerId}');
      `;
      return <script dangerouslySetInnerHTML={{ __html: script }} />;
    })}
  </>
);

export const GTMConfig = () => {
  const script = `
    window.gtmDataLayer = window.gtmDataLayer || [];
    function gtag(){gtmDataLayer.push(arguments);}
  `;
  return <script dangerouslySetInnerHTML={{ __html: script }} />;
};

export const GTMNoScript = () => (
  <>
    {Object.values(GTM_CONFIGS).map((config) => (
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${config.containerId}&gtm_auth=${config.auth}&gtm_preview=${config.preview}&gtm_cookies_win=x`}
          height={0}
          width={0}
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    ))}
  </>
);

interface GA4TagProps {
  pageTitle: string;
  googleAnalyticsFourTrackingId?: string;
}

export const GA4Tag = ({
  pageTitle,
  googleAnalyticsFourTrackingId,
}: GA4TagProps) => {
  React.useEffect(() => {
    // NOTE: ページタイトルを戻り値のjsに埋め込む場合はエスケープが必要なため、グローバル定数に設定して直接読み込ませる
    window.ga4PageTitle = pageTitle;
  });

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsFourTrackingId}`}
      />
      <Script id="google-analytics-four-container">
        {`
          window.dataLayer = window.dataLayer || [];
          function ga4tag(){dataLayer.push(arguments);}
          const ga4ConfigParams = {page_title:window.ga4PageTitle}

          const href = window.location.href;
          const url = new URL(href);

          const clientId = url.searchParams.get("gaClientId");
          const sessionId = url.searchParams.get("gaSessionId");
          if (clientId) { ga4ConfigParams['client_id'] = clientId; }
          if (sessionId) { ga4ConfigParams['session_id'] = sessionId; }

          ga4tag('js', new Date());
          ga4tag('config','${googleAnalyticsFourTrackingId}',ga4ConfigParams);
        `}
      </Script>
    </>
  );
};

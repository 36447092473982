import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';

export interface RSDetailHeaderProps {
  title?: ReactNode;
  leftButton?: ReactNode;
  rightButton?: ReactNode;
  isNoBorder?: boolean;
}

export const RSDetailHeader: FC<RSDetailHeaderProps> = ({
  title,
  leftButton,
  rightButton,
  isNoBorder,
}) => {
  return (
    <div
      className={classnames(
        'relative',
        'box-border',
        !isNoBorder ? 'h-[59px]' : 'h-[56px]',
        'w-full',
        'bg-white',
        'px-1',
        !isNoBorder &&
          'border-b-[3px] border-l-0 border-r-0 border-t-0 border-solid border-gray-10',
      )}
    >
      <div className="box-border flex h-full w-full justify-between">
        <div className="box-border flex h-full basis-[104px] items-center justify-start">
          {leftButton && <div>{leftButton}</div>}
        </div>
        <div className="box-border flex h-full flex-auto items-center justify-center truncate px-2">
          {title && (
            <div className="rs-font box-border truncate text-center text-16 font-normal leading-[1.4] text-gray-1000 pc:text-15 pc:leading-[1.4]">
              {title}
            </div>
          )}
        </div>
        <div className="box-border flex h-full basis-[104px] items-center justify-end">
          {rightButton && <div>{rightButton}</div>}
        </div>
      </div>
    </div>
  );
};

import React, { useCallback, FC } from 'react';

import CalendarHeader from '../../../../molecules/merchant/calendar/sp/SpMerchantCalendarHeader/SpAllBookingPagesCalendarHeader';
import { useStaffBookingPageMapping } from '../../../../hooks/api/booking-calendar-meta';
import { ActionsCreators as actions } from '../../../../redux/actions/merchants/services';
import { ViewStyle } from '../../../../redux/types/merchants/services';
import { useAppDispatch } from '../../../../redux';

type Props = {
  merchantPublicId: string;
  baseDate: string;
  isList: boolean;
  hasPrev: boolean;
  onSelectStaff: (staffId: string) => void;
  onSelectBookingPage: (publicId: string) => void;
};

export const SpAllBookingPagesCalendarHeaderOrganisim: FC<Props> = ({
  merchantPublicId,
  baseDate,
  isList,
  hasPrev,
  onSelectStaff,
  onSelectBookingPage,
}) => {
  const { staffMembers, bookingPages } =
    useStaffBookingPageMapping(merchantPublicId);
  const dispatch = useAppDispatch();

  const onClickCalendar = useCallback(() => {
    dispatch(actions.switchViewStyle(ViewStyle.Calendar));
  }, [dispatch]);

  const onClickList = useCallback(() => {
    dispatch(actions.switchViewStyle(ViewStyle.List));
  }, [dispatch]);

  const moveNext = useCallback(() => {
    dispatch(actions.moveNext());
  }, [dispatch]);

  const movePrev = useCallback(() => {
    dispatch(actions.movePrev());
  }, [dispatch]);

  return (
    <CalendarHeader
      baseDate={baseDate}
      isList={isList}
      hasPrev={hasPrev}
      onClickCalendar={onClickCalendar}
      onClickList={onClickList}
      onClickNext={moveNext}
      onClickPrev={movePrev}
      onSelectStaff={onSelectStaff}
      staffMembers={staffMembers}
      bookingPages={bookingPages}
      onSelectBookingPage={onSelectBookingPage}
    />
  );
};

import useSWR from 'swr';
import implBookingPageRepository from '../../../infrastructure/repositories/BookingPageRepository';

const usePerformableStaffMembers = (args?: {
  merchantPublicId: string;
  bookingPageId: string;
}) => {
  const merchantPublicId = args?.merchantPublicId;
  const bookingPageId = args?.bookingPageId;

  const swrKey = `/merchants/${merchantPublicId}/booking_pages/${bookingPageId}/staff`;

  const fetcher = async () => {
    if (!merchantPublicId || !bookingPageId) {
      throw 'never call';
    }

    return implBookingPageRepository.SearchStaff(
      merchantPublicId,
      bookingPageId,
    );
  };

  const { data } = useSWR(args ? swrKey : undefined, fetcher);

  return { data };
};

export default usePerformableStaffMembers;

import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

export interface PaymentMethodsListProps {
  enablePaymentMethods: (
    | 'onsite'
    | 'bank_transfer'
    | 'credit_card'
    | 'subscription'
    | 'ticket_book'
  )[];
  isPriceUnique: boolean;
  lowestPrice: number | null;
}

export const PaymentMethodsList: FC<PaymentMethodsListProps> = ({
  enablePaymentMethods,
  isPriceUnique,
  lowestPrice,
}) => {
  const intl = useIntl();

  const paymentMethodsString = useMemo(() => {
    return enablePaymentMethods
      .map((paymentMethod) => {
        switch (paymentMethod) {
          case 'onsite':
            return intl.formatMessage({
              defaultMessage: '現地決済',
              id: 'dEQM0F',
              description:
                'src/templates/merchants/resources/book/components/resource-detail-modal/PaymentMethodsList/index.tsx',
            });
          case 'bank_transfer':
            return intl.formatMessage({
              defaultMessage: '銀行振込',
              id: 'rcx3Lm',
              description:
                'src/templates/merchants/resources/book/components/resource-detail-modal/PaymentMethodsList/index.tsx',
            });
          case 'credit_card':
            return intl.formatMessage({
              defaultMessage: 'クレジットカード決済',
              id: 'WtaZf9',
              description:
                'src/templates/merchants/resources/book/components/resource-detail-modal/PaymentMethodsList/index.tsx',
            });
          case 'subscription':
            return intl.formatMessage({
              defaultMessage: '月謝',
              id: '5owDpM',
              description:
                'src/templates/merchants/resources/book/components/resource-detail-modal/PaymentMethodsList/index.tsx',
            });
          case 'ticket_book':
            return intl.formatMessage({
              defaultMessage: '回数券',
              id: 'dB6XAk',
              description:
                'src/templates/merchants/resources/book/components/resource-detail-modal/PaymentMethodsList/index.tsx',
            });
        }
      })
      .join('・');
  }, [intl, enablePaymentMethods]);

  return (
    <div className="m-0 box-border border-x-0 border-y border-solid border-gray-50 bg-white">
      <div className="box-border border-b border-l-0 border-r-0 border-t-0 border-solid border-gray-50  p-1.5 last-of-type:border-b-0">
        {(() => {
          if (lowestPrice === null) {
            return null;
          }

          return (
            <div className="rs-font mb-[2px] text-16 font-bold text-gray-1000 pc:text-15">
              {(() => {
                if (!isPriceUnique) {
                  return intl.formatMessage(
                    {
                      defaultMessage: '{priceString}~',
                      id: 've1gC9',
                      description:
                        'src/templates/merchants/resources/book/components/resource-detail-modal/PaymentMethodsList/index.tsx',
                    },
                    {
                      priceString: intl.formatNumber(lowestPrice, {
                        style: 'currency',
                        currency: 'JPY',
                      }),
                    },
                  );
                }

                return intl.formatNumber(lowestPrice, {
                  style: 'currency',
                  currency: 'JPY',
                });
              })()}
            </div>
          );
        })()}
        <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
          {paymentMethodsString}
        </div>
      </div>
    </div>
  );
};

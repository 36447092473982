import { useCallback } from 'react';
import useSWR, { SWRResponse, Key, useSWRConfig } from 'swr';
import { AxiosError } from 'axios';

import {
  Parameter$getReservationFlowEventSchemeResource,
  Response$getReservationFlowEventSchemeResource$Status$200,
} from '../../../../openapi/generated/apiClient';
import { useClientSideApiClient } from '../../../../openapi/axiosClient';

export interface UseGetReservationFlowEventSchemeResourceParams {
  parameter: Parameter$getReservationFlowEventSchemeResource;
}

export type UseGetReservationFlowEventSchemeResourceValue = SWRResponse<
  Response$getReservationFlowEventSchemeResource$Status$200['application/json'],
  AxiosError
>;

export const getGetReservationFlowEventSchemeResourceKey: (params: {
  parameter: Parameter$getReservationFlowEventSchemeResource;
}) => Key = ({ parameter }) => {
  return {
    path: '/api/reservation_flow/merchants/{merchant_public_id}/event_scheme/resources/{resource_public_id}',
    parameter,
  };
};

export const useGetReservationFlowEventSchemeResource: (
  params: UseGetReservationFlowEventSchemeResourceParams,
) => UseGetReservationFlowEventSchemeResourceValue = ({ parameter }) => {
  const client = useClientSideApiClient();
  const key = getGetReservationFlowEventSchemeResourceKey({ parameter });

  const fetcher: () => Promise<
    Response$getReservationFlowEventSchemeResource$Status$200['application/json']
  > = async () => {
    try {
      const result = await client.getReservationFlowEventSchemeResource({
        parameter,
      });

      return result;
    } catch (error) {
      throw error;
    }
  };

  // 保護されたページなど401エラーが発生する可能性があるため、shouldRetryOnErrorをfalseに設定する
  return useSWR(key, fetcher, { shouldRetryOnError: false });
};

export interface UseRevalidateGetReservationFlowEventSchemeResourceParams {
  parameter: Parameter$getReservationFlowEventSchemeResource;
}

export interface UseRevalidateGetReservationFlowEventSchemeResourceValue {
  revalidateGetReservationFlowEventSchemeResource: () => Promise<void>;
}

export const useRevalidateGetReservationFlowEventSchemeResource: (
  params: UseRevalidateGetReservationFlowEventSchemeResourceParams,
) => UseRevalidateGetReservationFlowEventSchemeResourceValue = ({
  parameter,
}) => {
  const { mutate } = useSWRConfig();

  const revalidateGetReservationFlowEventSchemeResource =
    useCallback(async () => {
      await mutate(getGetReservationFlowEventSchemeResourceKey({ parameter }));
    }, [parameter, mutate]);

  return { revalidateGetReservationFlowEventSchemeResource };
};

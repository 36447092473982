import { CreditCard } from '../interfaces/creditCard';

export interface StripeResponse {
  error?: stripe.StripeError;
  token?: string;
}

export const callStripeApi = (
  creditCard: CreditCard,
  jcbEnabled: boolean,
): Promise<StripeResponse> => {
  return new Promise((resolve) => {
    Stripe.createToken(
      {
        cvc: creditCard.securityCode,
        exp_month: parseInt(creditCard.month!, 10),
        exp_year: parseInt(creditCard.year!, 10),
        number: creditCard.cardNumber!,
      },
      (status, response) => {
        if (status === 200) {
          const brand = response.card.brand;
          if (isJCBBrand(brand) && !jcbEnabled) {
            resolve({ error: invalidCardBrandError });
          } else {
            resolve({ token: response.id });
          }
        } else {
          resolve({ error: response.error });
        }
      },
    );
  });
};

// JCBが使用できないマーチャントでJCBのカードが入力されたときにこのレスポンスを返す。
// 現状エラーメッセージの出しわけもstripeのレスポンスに依存しているので、stripeのレスポンスをハードコードしている。
// エラーレスポンスをラップしてメッセージを出し分けるように変更したい...
export const invalidCardBrandError = {
  code: 'card_declined',
  decline_code: 'card_not_supported',
  doc_url: 'https://stripe.com/docs/error-codes/card-declined',
  message: 'Your card is not supported.',
  param: 'number',
  type: 'card_error',
};

type StripeCardBrands =
  | 'Visa'
  | 'American Express'
  | 'MasterCard'
  | 'Discover'
  | 'JCB'
  | 'Diners Club'
  | 'Unknown'
  | undefined;

export const isJCBBrand = (brand: StripeCardBrands) => {
  const jcbBrands = ['JCB', 'Diners Club', 'Discover'];
  return jcbBrands.includes(brand as string);
};

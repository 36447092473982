import React, {
  DetailedHTMLProps,
  ButtonHTMLAttributes,
  forwardRef,
  useMemo,
} from 'react';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import classnames from 'classnames';
import SpinnerIcon from '@heyinc/stand-ui-icons/svg/spinner.svg';

import styles from './index.module.css';

export type RSDetailHeaderButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  variant?: 'close' | 'ok' | 'okText' | 'destruction';
  isLoading?: boolean;
};

export const RSDetailHeaderButton = forwardRef<
  HTMLButtonElement,
  RSDetailHeaderButtonProps
>(({ variant = 'close', isLoading = false, children, ...props }, ref) => {
  const { disabled } = props;
  const { isFocusVisible, focusProps } = useFocusRing();

  const color = '#0066FF';
  const colorTint = '#F1F7FF';

  const wrapperClasses = useMemo(() => {
    switch (variant) {
      case 'close':
        return ['min-w-[80px]'];
      case 'ok':
        return ['min-w-[80px]'];
      case 'okText':
        return [];
      case 'destruction':
        return ['min-w-[80px]'];
    }
  }, [variant]);

  const buttonSizeClasses = useMemo(() => {
    switch (variant) {
      case 'close':
        return ['px-2'];
      case 'ok':
        return ['px-2'];
      case 'okText':
        return ['px-1.5'];
      case 'destruction':
        return ['px-2'];
    }
  }, [variant]);

  const buttonColorClasses = useMemo(() => {
    // closeとdestructionはdisabledのスタイルを持っていないが、
    // isLoadingとdisabledを合わせて利用する可能性があるため不自然でない色を指定している。
    switch (variant) {
      case 'close':
        return ['bg-gray-10', 'text-gray-1000', 'border-gray-10'];
      case 'ok':
        return [
          (disabled || isLoading) && 'bg-gray-10',
          !disabled && !isLoading ? 'text-white' : 'text-gray-200',
          (disabled || isLoading) && 'border-gray-10',
        ];
      case 'okText':
        return [
          'bg-white',
          (disabled || isLoading) && 'text-gray-200',
          'border-white',
        ];
      case 'destruction':
        return [
          (disabled || isLoading) && 'bg-gray-10',
          (disabled || isLoading) && 'border-gray-10',
        ];
    }
  }, [variant, disabled, isLoading]);

  const isButtonBackgroundColorPrimary = useMemo(() => {
    if (disabled || isLoading) {
      return false;
    }

    if (variant !== 'ok') {
      return false;
    }

    return true;
  }, [variant, disabled, isLoading]);

  const isButtonBackgroundColorPrimaryTint = useMemo(() => {
    if (disabled || isLoading) {
      return false;
    }

    if (variant !== 'destruction') {
      return false;
    }

    return true;
  }, [variant, disabled, isLoading]);

  const isButtonTextColorPrimary = useMemo(() => {
    if (disabled || isLoading) {
      return false;
    }

    if (variant !== 'okText' && variant !== 'destruction') {
      return false;
    }

    return true;
  }, [variant, disabled, isLoading]);

  const isButtonBorderColorPrimary = useMemo(() => {
    if (disabled || isLoading) {
      return false;
    }

    if (variant !== 'ok' && variant !== 'destruction') {
      return false;
    }

    return true;
  }, [variant, disabled, isLoading]);

  return (
    <>
      <div
        className={classnames(
          'wrapper',
          isFocusVisible && 'focus-visible',
          'inline-block',
          'relative',
          'box-border',
          wrapperClasses,
          'h-[40px]',
          'rounded-[12px]',
          'after:transition',
        )}
      >
        <button
          {...mergeProps(props, focusProps)}
          ref={ref}
          className={classnames(
            'button',
            isButtonBackgroundColorPrimary && 'bg-primary',
            isButtonBackgroundColorPrimaryTint && 'bg-primary-tint',
            isButtonTextColorPrimary && 'text-primary',
            isButtonBorderColorPrimary && 'border-primary',
            'relative',
            'box-border',
            buttonSizeClasses,
            'w-full',
            'h-full',
            'flex',
            'justify-center',
            'items-center',
            'border',
            'border-solid',
            'rounded-[12px]',
            'outline-none',
            'focus:outline-none',
            buttonColorClasses,
            'z-1',
            'transition',
            !disabled ? 'cursor-pointer' : 'cursor-not-allowed',
            !disabled && !isLoading && styles['button--default'],
          )}
        >
          {isLoading && (
            <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
              <div className={styles['icon-wrapper']}>
                <SpinnerIcon
                  width="24"
                  height="24"
                  viewBox="0 0 48 48"
                  className="fill-gray-200"
                />
              </div>
            </div>
          )}
          <div
            className={classnames(
              'rs-font',
              'text-16',
              'pc:text-15',
              'font-bold',
              'whitespace-nowrap',
              isLoading && 'invisible',
            )}
          >
            {children}
          </div>
        </button>
      </div>
      <style jsx>{`
        .wrapper::after {
          content: '';
          box-sizing: border-box;
          position: absolute;
          width: calc(100% + 8px);
          height: calc(100% + 8px);
          top: -4px;
          left: -4px;
          border-style: solid;
          border-width: 2px;
          border-color: transparent;
          border-radius: 16px;
        }

        .wrapper.focus-visible::after {
          border-color: ${color};
        }

        .button.bg-primary {
          background-color: ${color};
        }

        .button.bg-primary-tint {
          background-color: ${colorTint};
        }

        .button.text-primary {
          color: ${color};
        }

        .button.border-primary {
          border-color: ${color};
        }
      `}</style>
    </>
  );
});

RSDetailHeaderButton.displayName = 'RSDetailHeaderButton';

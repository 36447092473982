import * as React from 'react';
import { useIntl } from 'react-intl';
import { LocaleContext } from '../../../contexts/LocaleContext';
import { useHideStoresLogo } from '../../../hooks/useHideStoresLogo';
import css from './SiteFooter.module.scss';

const SiteFooter = () => {
  const intl = useIntl();
  const localeCtx = React.useContext(LocaleContext);
  const intlPref = 'components.common.layouts.merchantGlobalFooter.siteFooter.';

  const { isHiddenStoresLogo } = useHideStoresLogo();

  return (
    <div className={css.SiteFooter}>
      <div className={css.SiteFooter__Inner}>
        <span className={css.SiteFooter__Copyright}>
          {!isHiddenStoresLogo && 'STORES, Inc.'}
        </span>
        <div className={css.SiteFooter__Links}>
          <a href="/terms" className={css.SiteFooter__Link}>
            {intl.formatMessage({ id: intlPref + 'links.terms' })}
          </a>
          <a href="/user_terms" className={css.SiteFooter__Link}>
            {intl.formatMessage({ id: intlPref + 'links.user_terms' })}
          </a>
          <a
            href="https://id.stores.jp/privacy_policy"
            className={css.SiteFooter__Link}
          >
            {intl.formatMessage({ id: intlPref + 'links.policyForMerchant' })}
          </a>
          <a
            href="https://stores.jp/reserve/privacy"
            className={css.SiteFooter__Link}
          >
            {intl.formatMessage({ id: intlPref + 'links.policyForEndUser' })}
          </a>
        </div>
        <div className={css.SiteFooter__Language}>
          <span>{intl.formatMessage({ id: intlPref + 'language' })}: </span>
          <select
            className={css.SiteFooter__LanguageSelect}
            onChange={(e) => {
              localeCtx.changeLocale(e.target.value);
            }}
            value={localeCtx.currentLocale}
          >
            <option value="ja">日本語</option>
            <option value="en">English</option>
            <option value="ko">한국어</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default SiteFooter;

import { useRouter } from 'next/router';
import React from 'react';
import { useIntl } from 'react-intl';
import { MerchantDetail } from '../../../../../interfaces/merchants';
import Button, { ButtonSize } from '../../../../../atoms/buttons/Button';
import { reservationUrlBy } from '../../../../../helpers/merchants/helper';

import css from './MerchantReservationButton.module.scss';

interface OwnProps {
  merchant: MerchantDetail;
}

type Props = OwnProps;

const MerchantReservationButton = ({ merchant }: Props) => {
  const router = useRouter();
  const intl = useIntl();
  const reservationUrl = reservationUrlBy(merchant, router);
  return (
    <Button
      className_Deprecated={css.MerchantReservationButton}
      buttonSize={ButtonSize.Medium}
      href={reservationUrl.url}
      targetBlank={reservationUrl.targetBlank}
    >
      {intl.formatMessage({
        id: 'merchants.merchantReservationButton.submit',
      })}
    </Button>
  );
};

export default MerchantReservationButton;

export enum State {
  Available = 'available',
  Occupied = 'occupied',
}

export interface MerchantBookingEventAvailability {
  time: number;
  value: string;
  state: State;
}

export interface MerchantBookingEvent {
  class: string;
  availabilities?: MerchantBookingEventAvailability[];
}

export interface MerchantBookingCheckinTime {
  time: number;
  value: string;
}

export interface MerchantBookingEvents {
  checkin_times: MerchantBookingCheckinTime[];
  dates: { [date: string]: MerchantBookingEvent };
}

export type MerchantBookingEventsData = MerchantBookingEvents;

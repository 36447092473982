import dayjs from 'dayjs';

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import 'dayjs/locale/ja';
import 'dayjs/locale/en';

export const initializeDayjs = () => {
  /* eslint-disable import/no-named-as-default-member */
  dayjs.extend(timezone);
  dayjs.extend(utc);

  dayjs.extend(customParseFormat);
  dayjs.extend(isoWeek);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isBetween);
  dayjs.extend(localizedFormat);
  /* eslint-enable import/no-named-as-default-member */
};

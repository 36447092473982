import { useEffect } from 'react';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { apiRoot, MeResponse } from '../../../infrastructure/api';
import securedAxios from '../../../utils/secured-axios';
import axios, { AxiosResponse } from 'axios';
import User from '../../../domain/entities/User';
import { coubicLocale } from '../../../utils/i18n';
import { useClientSideApiClient } from 'src/openapi/axiosClient';

export const userMeSwrKey = '/users/me';

export const useUserMeFetcher: () => Promise<
  AxiosResponse<MeResponse>
> = async () => {
  try {
    const res = await securedAxios.get<MeResponse>(`${apiRoot()}/users/me`);
    return res;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 401) {
      return err.response;
    }

    throw err;
  }
};

export const useUserMe = () => {
  const swrKey = userMeSwrKey;

  const {
    data: meResponse,
    error,
    isValidating,
    isLoading,
    mutate,
  } = useSWR(swrKey, useUserMeFetcher);

  useEffect(() => {
    const pageshowListener = () => {
      if (
        (
          performance.getEntriesByType(
            'navigation',
          ) as PerformanceNavigationTiming[]
        )[0]?.type === 'back_forward'
      ) {
        mutate();
      }
    };
    window.addEventListener('pageshow', pageshowListener);

    return () => {
      window.removeEventListener('pageshow', pageshowListener);
    };
  }, [mutate]);

  const meResponseData =
    meResponse?.status === 401 ? undefined : meResponse?.data;

  // TODO: Userクラス消す
  const user =
    meResponseData &&
    new User({
      id: meResponseData.id,
      displayName: meResponseData.display_name,
      publicId: meResponseData.public_id,
      thumbnailUrl: {
        c120x120: meResponseData.thumbnail.c120x120.url,
        p1280x1280: meResponseData.thumbnail.p1280x1280.url,
        square: meResponseData.thumbnail.square.url,
        thumb: meResponseData.thumbnail.thumb.url,
      },
      email: meResponseData.email,
      role: meResponseData.role,
    });

  return {
    meResponseData,
    error,
    isValidating,
    mutate,
    isUserLoading: isLoading,
    isLoggedIn: !!meResponseData,
    user,
  };
};

const updateUserMeLocaleSwrKey = '/users/me/locale';

export const useUpdateUserMeLocale = () => {
  const client = useClientSideApiClient();

  const { trigger } = useSWRMutation(
    updateUserMeLocaleSwrKey,
    async (_url, { arg }: { arg: coubicLocale }) =>
      await client.updateUsersMeLocale({ requestBody: { locale: arg } }),
  );

  return { trigger };
};

import { BookingPageTagName } from '../../../domain/entities/BookingPage';

export enum MerchantBookingStatus {
  Unpublished = 'unpublished',
  Closed = 'closed',
  FullyBooked = 'fully_booked',
  Accepting = 'accepting',
}

export const BookingPageScheme = {
  EVENT: 'EVENT_SCHEME',
  REQUEST: 'REQUEST_SCHEME',
  AVAILABILITY: 'AVAILABILITY_SCHEME',
  ASSIGN: 'ASSIGN_SCHEME',
  SCHOOL: 'SCHOOL_SCHEME',
} as const;

export interface MerchantBookingPrimaryImage {
  meta: {
    width: number;
    height: number;
  };
  thumb?: {
    url: string;
  };
  square?: {
    url: string;
  };
  c800x420?: {
    url: string;
  };
  p800x600?: {
    url: string;
  };
  p1280x1280?: {
    url: string;
  };
}

export const NO_CAPACITY = 'no_capacity';
export const HIDDEN = 'hidden';
type NoCapacity = typeof NO_CAPACITY;
type Hidden = typeof HIDDEN;

type VacancyVisibility = NoCapacity | Hidden;

export const UPLOAD_IMAGE = 'upload_image';
export const EMBED = 'embed';
type UploadImage = typeof UPLOAD_IMAGE;
type Embed = typeof EMBED;
type CoverContentsType = UploadImage | Embed;

export interface MerchantBookingPhotoGalleryImage {
  image: {
    url: string | undefined;
    thumb: { url: string | undefined };
    square: { url: string | undefined };
    large: { url: string | undefined };
    c800x420: { url: string | undefined };
    p800x600: { url: string | undefined };
    p1280x1280: { url: string | undefined };
  };
  width: number;
  height: number;
  file_size: number;
  created_at: number;
  updated_at: number;
}

interface TimeSlot {
  lesson_id?: string;
  capacity: number;
  vacancy: number;
  accepted: number;
  pending: number;
  recurrence_key: string;
  start_at: number;
  end_at: number;
}

interface MapWidget {
  address_city: string;
  href: string;
  img_src: string;
  latitude: string;
  longitude: string;
}

type CandidateViewType = 'list' | 'calendar' | 'board';

interface MerchantBookingCoverContent {
  url: string;
  embed: string;
  thumbnail_url: string;
}

export type MerchantBookingPageApiResponse = {
  status: MerchantBookingStatus;
  prepayment_enabled: boolean;
  onsite_payment_enabled: boolean;
  payable_with_ticket: boolean;
  payable_with_subscription: boolean;
  payment_settings_enabled: boolean;
  require_acceptance: boolean;
  cover_contents_type: CoverContentsType;
  vacancy_visibility: VacancyVisibility;
  password_protected: boolean;
  waiting_list_provided: boolean;
  time_slots: TimeSlot[];
  photo_gallery_images: MerchantBookingPhotoGalleryImage[];
  map_widget: MapWidget;
  view_type: CandidateViewType;
  require_customer_login: boolean;
  staff_selectable: boolean;
  created_at: 1538793237;
  updated_at: 1538793370;
  published_at: 1538793237;
  is_json_description: boolean;
  description?: string;
  description_text?: string;
  cover_content: MerchantBookingCoverContent;
  name: string;
  model_type: string;
  nickname: string;
  primary_image?: MerchantBookingPrimaryImage;
  public_id: string;
  rate_count: number;
  rate?: number;
  lowest_price?: number;
  unique_pricing: boolean;
  tag_names: BookingPageTagName[];
};

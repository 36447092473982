import classNames from 'classnames';
import * as React from 'react';
import css from './BaseLayout.module.scss';
import GlobalFooter from './GlobalFooter';
import GlobalNav from './GlobalNav/GlobalNav';

export interface Props extends React.HTMLProps<{}> {
  isCoubicApp: boolean;
}

const BaseLayout = (props: Props) => {
  return (
    <div className={classNames(css.BaseLayout, props.className)}>
      {!props.isCoubicApp && (
        <GlobalNav
          logoImage={null}
          merchantPublicId={null}
          displayName={null}
        />
      )}

      {props.children}
      {!props.isCoubicApp && (
        <div className={css.BaseLayout__Footer}>
          <GlobalFooter />
        </div>
      )}
    </div>
  );
};

export default BaseLayout;

export type GtmConfig = {
  auth: string;
  containerId: string;
  preview: string;
};

const googleTagEnv = process.env.NEXT_PUBLIC_GOOGLE_TAG_ENV;

export const GTM_CONFIGS = ((): { [_: string]: GtmConfig } => {
  switch (googleTagEnv) {
    case 'production':
      return {
        coubicFrontend: {
          auth: 'CXsKrbmeizWaaDt3OQhenA',
          containerId: 'GTM-MHRX774',
          preview: 'env-2',
        },
        coubicFrontend2: {
          auth: 'ppK0fcQWXybVZcdICBq2MA',
          containerId: 'GTM-WS64FTM',
          preview: 'env-2',
        },
      };
    case 'mirror':
      return {
        coubicFrontendStaging: {
          auth: '60N0lrbFuaMSjWJtbZuzxw',
          containerId: 'GTM-PNBPHKZ',
          preview: 'env-2',
        },
        coubicFrontendStaging2: {
          auth: 'qj-_qgA8YLhy5RXC7R7BsA',
          containerId: 'GTM-TST7Z7M',
          preview: 'env-2',
        },
      };
    case 'staging':
      return {
        coubicFrontendStaging: {
          auth: '60N0lrbFuaMSjWJtbZuzxw',
          containerId: 'GTM-PNBPHKZ',
          preview: 'env-2',
        },
        coubicFrontendStaging2: {
          auth: 'qj-_qgA8YLhy5RXC7R7BsA',
          containerId: 'GTM-TST7Z7M',
          preview: 'env-2',
        },
      };
    case 'sandbox':
      return {
        coubicFrontendStaging: {
          auth: '60N0lrbFuaMSjWJtbZuzxw',
          containerId: 'GTM-PNBPHKZ',
          preview: 'env-2',
        },
        coubicFrontendStaging2: {
          auth: 'qj-_qgA8YLhy5RXC7R7BsA',
          containerId: 'GTM-TST7Z7M',
          preview: 'env-2',
        },
      };
    default:
      return {};
  }
})();

export type BookingEventArguments = {
  step: 'completion';
  resourcePublicId: string;
  courseCanonicalId?: number;
  courseName?: string;
  reservationPublicId: number;
};
export const bookingEvent = ({
  step,
  resourcePublicId,
  courseCanonicalId,
  courseName,
  reservationPublicId,
}: BookingEventArguments) => {
  // GTM(Google Tag Manager)にイベントを送信
  if (window.gtag) {
    window.gtag('event', 'booking_rendered', {
      booking_step: step,
      res_id: resourcePublicId,
      rv_id: reservationPublicId,
      menu_id: courseCanonicalId,
      menu_name: courseName,
    });
  }

  if (window.ga4tag) {
    const uri = new URL(window.location.href);

    // GA4 用のカスタムイベントのキー名称はマーチャントのオーナーが
    // アナリティクス管理の設定時に見ることのあるものなので、resourceという名前の露出を避けた名称にしています
    window.ga4tag('event', 'booking', {
      booking_step: step,
      reservation_page_id: resourcePublicId,
      menu_id: courseCanonicalId,
      menu_name: courseName,
      reservation_id: reservationPublicId,
      URL: uri.href,
    });
  }
};

export type PurchaseEventArgument = {
  step: 'completion';
  productPublicId: string;
  customerPublicId: string | undefined;
};
export const purchaseEvent = ({
  step,
  productPublicId,
  customerPublicId,
}: PurchaseEventArgument) => {
  if (window.gtag) {
    window.gtag('event', 'purchase_rendered', {
      purchase_step: step,
      product_id: productPublicId,
      customer_id: customerPublicId,
    });
  }

  if (window.ga4tag) {
    const uri = new URL(window.location.href);

    window.ga4tag('event', 'purchase', {
      purchase_step: step,
      product_id: productPublicId,
      customer_id: customerPublicId,
      URL: uri.href,
    });
  }
};

type EventTypeReservationEventArgument = {
  reservationPublicId: string;
  customerPublicId: string;
};
export const eventTypeReservationEvent = ({
  reservationPublicId,
  customerPublicId,
}: EventTypeReservationEventArgument) => {
  if (window.gtag) {
    window.gtag('event', 'm3_reservation', {
      reservationPublicId,
      customer_id: customerPublicId,
    });
  }

  if (window.ga4tag) {
    window.ga4tag('event', 'm3_reservation', {
      reservationPublicId,
      customer_id: customerPublicId,
    });
  }
};

type EventTypeProductPurchaseEventArgument = {
  type: 'subscription' | 'ticket_book';
  purchasedPublicId: string;
  customerPublicId: string;
};
export const eventTypeProductPurchaseEvent = ({
  type,
  purchasedPublicId,
  customerPublicId,
}: EventTypeProductPurchaseEventArgument) => {
  if (window.gtag) {
    window.gtag('event', 'm3_product_purchase', {
      type,
      purchasedPublicId,
      customer_id: customerPublicId,
    });
  }

  if (window.ga4tag) {
    window.ga4tag('event', 'm3_product_purchase', {
      type,
      purchasedPublicId,
      customer_id: customerPublicId,
    });
  }
};

type EventTypeReservationStartEventArgument = {
  loggedIn?: boolean;
};
export const eventTypeReservationStartEvent = ({
  loggedIn,
}: EventTypeReservationStartEventArgument) => {
  if (window.gtag) {
    window.gtag('event', 'm3_reservation_start', {
      loggedIn,
    });
  }

  if (window.ga4tag) {
    window.ga4tag('event', 'm3_reservation_start', {
      loggedIn,
    });
  }
};

type CourseTypeReservationEventArgument = {
  reservationPublicId: string;
  customerPublicId: string;
};
export const courseTypeReservationEvent = ({
  reservationPublicId,
  customerPublicId,
}: CourseTypeReservationEventArgument) => {
  if (window.gtag) {
    window.gtag('event', 'course_type_reservation', {
      reservationPublicId,
      customer_id: customerPublicId,
    });
  }

  if (window.ga4tag) {
    window.ga4tag('event', 'course_type_reservation', {
      reservationPublicId,
      customer_id: customerPublicId,
    });
  }
};

type CourseTypeProductPurchaseEventArgument = {
  type: 'subscription' | 'ticket_book';
  purchasedPublicId: string;
  customerPublicId: string;
};
export const courseTypeProductPurchaseEvent = ({
  type,
  purchasedPublicId,
  customerPublicId,
}: CourseTypeProductPurchaseEventArgument) => {
  if (window.gtag) {
    window.gtag('event', 'course_type_product_purchase', {
      type,
      purchasedPublicId,
      customer_id: customerPublicId,
    });
  }

  if (window.ga4tag) {
    window.ga4tag('event', 'course_type_product_purchase', {
      type,
      purchasedPublicId,
      customer_id: customerPublicId,
    });
  }
};

type CourseTypeReservationStartEventArgument = {
  loggedIn?: boolean;
};
export const courseTypeReservationStartEvent = ({
  loggedIn,
}: CourseTypeReservationStartEventArgument) => {
  if (window.gtag) {
    window.gtag('event', 'course_type_reservation_start', {
      loggedIn,
    });
  }

  if (window.ga4tag) {
    window.ga4tag('event', 'course_type_reservation_start', {
      loggedIn,
    });
  }
};

import classNames from 'classnames';
import React, { HTMLProps } from 'react';

import css from './Tag.module.scss';

interface Props extends HTMLProps<{}> {
  color?: TagColor;
}

export enum TagColor {
  Default = 'default',
  Station = 'station', // deprecated
  Gray = 'gray',
}

const Tag: React.FC<Props> = ({ color = TagColor.Default, ...props }) => {
  const classes = classNames(
    css.Tag,
    {
      [css.isGray]: color === TagColor.Station,
    },
    {
      [css.isGray]: color === TagColor.Gray,
    },
    props.className,
  );

  return <span className={classes}>{props.children}</span>;
};

export default Tag;

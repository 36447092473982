import { useCallback } from 'react';
import useSWR, { SWRResponse, Key, useSWRConfig } from 'swr';
import { AxiosError } from 'axios';

import {
  Parameter$getReservationFlowCourseSchemeResource,
  Response$getReservationFlowCourseSchemeResource$Status$200,
} from 'src/openapi/generated/apiClient';
import { useClientSideApiClient } from 'src/openapi/axiosClient';

export interface UseGetReservationFlowCourseSchemeResourceParams {
  parameter: Parameter$getReservationFlowCourseSchemeResource;
}

export type UseGetReservationFlowCourseSchemeResourceValue = SWRResponse<
  Response$getReservationFlowCourseSchemeResource$Status$200['application/json'],
  AxiosError
>;

export const getGetReservationFlowCourseSchemeResourceKey: (params: {
  parameter: Parameter$getReservationFlowCourseSchemeResource;
}) => Key = ({ parameter }) => {
  return {
    path: '/api/reservation_flow/merchants/{merchant_public_id}/course_scheme/resources/{resource_public_id}',
    parameter,
  };
};

export const useGetReservationFlowCourseSchemeResource: (
  params: UseGetReservationFlowCourseSchemeResourceParams,
) => UseGetReservationFlowCourseSchemeResourceValue = ({ parameter }) => {
  const client = useClientSideApiClient();
  const key = getGetReservationFlowCourseSchemeResourceKey({ parameter });

  const fetcher: () => Promise<
    Response$getReservationFlowCourseSchemeResource$Status$200['application/json']
  > = async () => {
    try {
      const result = await client.getReservationFlowCourseSchemeResource({
        parameter,
      });

      return result;
    } catch (error) {
      throw error;
    }
  };

  // 保護されたページなど401エラーが発生する可能性があるため、shouldRetryOnErrorをfalseに設定する
  return useSWR(key, fetcher, { shouldRetryOnError: false });
};

export interface UseRevalidateGetReservationFlowEventSchemeResourceParams {
  parameter: Parameter$getReservationFlowCourseSchemeResource;
}

export interface UseRevalidateGetReservationFlowCourseSchemeResourceValue {
  revalidateGetReservationFlowCourseSchemeResource: () => Promise<void>;
}

export const useRevalidateGetReservationFlowCourseSchemeResource: (
  params: UseRevalidateGetReservationFlowEventSchemeResourceParams,
) => UseRevalidateGetReservationFlowCourseSchemeResourceValue = ({
  parameter,
}) => {
  const { mutate } = useSWRConfig();

  const revalidateGetReservationFlowCourseSchemeResource =
    useCallback(async () => {
      await mutate(getGetReservationFlowCourseSchemeResourceKey({ parameter }));
    }, [parameter, mutate]);

  return { revalidateGetReservationFlowCourseSchemeResource };
};

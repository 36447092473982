import { useState } from 'react';

export interface UseReactModalAnimationWorkaroundValue {
  afterOpenModal: () => void;
  afterClose: () => void;
  wasOpen: boolean;
}

// このcustom hookはreact-modalのSafariでのアニメーションの問題を解決するためにあります。
// https://stackoverflow.com/questions/65072058/react-modal-animation-doesnt-work-in-safari

export const useReactModalAnimationWorkaround: (
  initialValue: boolean,
) => UseReactModalAnimationWorkaroundValue = (initialValue) => {
  const [wasOpen, setWasOpen] = useState(initialValue);

  const afterOpenModal = () => {
    setTimeout(() => {
      setWasOpen(true);
    }, 0);
  };

  const afterClose = () => {
    setTimeout(() => {
      setWasOpen(false);
    }, 0);
  };

  return { afterOpenModal, afterClose, wasOpen };
};

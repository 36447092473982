import { useCallback } from 'react';
import { ServiceMap } from '../../interfaces/merchants/services';

export const useFilterSpCalendarBookingPages = (bookingPageIds?: string[]) => {
  return useCallback(
    // bookingPagesMapがカレンダーの日付をkeyにして予約ページ一覧を持ったobject
    (bookingPagesMap: ServiceMap) => {
      if (!bookingPageIds) {
        return bookingPagesMap;
      }

      const filteredBookingPagesMap: ServiceMap = {};
      for (const [calendarDate, bookingPages] of Object.entries(
        bookingPagesMap,
      )) {
        bookingPages.forEach((bookingPage) => {
          if (bookingPageIds?.includes(bookingPage.publicId)) {
            if (filteredBookingPagesMap[calendarDate]) {
              filteredBookingPagesMap[calendarDate].push(bookingPage);
            } else {
              filteredBookingPagesMap[calendarDate] = [bookingPage];
            }
          }
        });
      }
      return filteredBookingPagesMap;
    },
    [bookingPageIds],
  );
};

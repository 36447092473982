import { Reducer, Action as _Action } from 'redux';

export const AUTHENTICATE = 'authenticate';
export const DEAUTHENTICATE = 'deauthenticate';

export enum Types {
  Authenticate = 'authenticate',
  Deauthenticate = 'deauthenticate',
}

export interface AuthenticateAction extends _Action {
  type: Types.Authenticate;
  payload: {
    token: string;
  };
}

export interface DeauthenticateAction extends _Action {
  type: Types.Deauthenticate;
}

export interface State {
  loaded: boolean;
  loggedIn: boolean;
}

const initialState = {
  loaded: false,
  loggedIn: false,
};

const reducer: Reducer<State, AuthenticateAction | DeauthenticateAction> = (
  state = initialState,
  action: AuthenticateAction | DeauthenticateAction,
) => {
  switch (action.type) {
    case AUTHENTICATE:
      return { loaded: true, loggedIn: !!action.payload };
    case DEAUTHENTICATE:
      return { loaded: true, loggedIn: false };
    default:
      return state;
  }
};

export default reducer;

import { Action as _Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import * as IPurchases from '../../../../../interfaces/merchants/products/purchases';
import * as IConfirm from '../../../../../interfaces/merchants/products/purchases/confirm';
import * as ICustomFields from '../../../../../interfaces/merchants/products/purchases/custom_fields';
import * as IUseCase from '../../../../../interfaces/merchants/products/purchases/use_case';
import * as ICustomer from '../../../../../interfaces/customer';

import * as $ from './helper';
import { FirstCharge } from '../../../../../interfaces/merchants/products/firstCharge';
import { Order } from '../../../../../interfaces/merchants/products/orders';

export type Result<R> = ThunkAction<R, IPurchases.State, undefined, Actions>;
export type Dispatch = ThunkDispatch<IPurchases.State, undefined, Actions>;

export enum Types {
  Init = 'merchant/services/products/purchases/init',
  SetStep = 'merchant/services/products/purchases/setstep',
  SetPostable = 'merchant/services/products/purchases/setpostable',
  SetStripeErrors = 'merchant/services/products/purchases/setpaymenterrors',
  SetCustomFields = 'merchant/services/products/purchases/setcustomfields',
  SetCustomerErrors = 'merchant/services/products/purchases/setcustomererrors',
  SetCustomFieldsAndErrors = 'merchant/services/products/purchases/setcustomfieldsanderrors',
  SetEntireErrors = 'merchant/services/products/purchases/setentirederrors',
  SetPurchasedUser = 'merchant/services/products/purchases/setpurchaseduser',
  SetLastOrder = 'merchant/services/products/purchases/setlastorder',
  ClearCustomerError = 'merchant/services/products/purchases/clearcustomererrors',
}

export interface InitAction extends _Action {
  type: Types.Init;
  payload: {
    publicId: string;
    productId: string;
    loggedIn: boolean;
    subscribed: boolean;
    productData: IPurchases.MerchantProductData;
    dataSet: DataSet;
    jcbEnabled: boolean;
    isEmailVerified: boolean;
    firstCharge: FirstCharge | null;
    availableMonthsPeriod?: number;
  };
}

export interface SetStepAction extends _Action {
  type: Types.SetStep;
  payload: { step: number; renew?: boolean };
}

export interface SetPostableAction extends _Action {
  type: Types.SetPostable;
  payload: {
    postable: boolean;
  };
}

export interface SetStripeErrorsAction extends _Action {
  type: Types.SetStripeErrors;
  payload: { errors: stripe.StripeError };
}

export interface SetCustomFieldsAction extends _Action {
  type: Types.SetCustomFields;
  payload: {
    customFields: ICustomFields.MerchantPurchaseCustomFieldsData;
  };
}

export interface SetCustomerErrorsAction extends _Action {
  type: Types.SetCustomerErrors;
  payload: {
    errors: IPurchases.PurchasingCustomerCustomFieldsError;
  };
}

export interface SetCustomFieldsAndErrorsAction extends _Action {
  type: Types.SetCustomFieldsAndErrors;
  payload: {
    customFields: ICustomFields.MerchantPurchaseCustomFieldsData;
    errors: IPurchases.PurchasingCustomerCustomFieldsError;
  };
}

export interface SetEntireErrorsAction extends _Action {
  type: Types.SetEntireErrors;
  payload: {
    errors: IPurchases.EntireErrorType[];
  };
}

export interface SetPurchasedUserAction extends _Action {
  type: Types.SetPurchasedUser;
  payload: {
    verifiedUser: boolean;
    customer?: ICustomer.CustomerResponse;
  };
}

export interface SetLatestOrderAction extends _Action {
  type: Types.SetLastOrder;
  payload: {
    lastOrder?: Order;
  };
}

export interface ClearCustomerErrorsAction extends _Action {
  type: Types.ClearCustomerError;
  payload: {
    id: number;
  };
}

export type Actions =
  | InitAction
  | SetStepAction
  | SetPostableAction
  | SetStripeErrorsAction
  | SetCustomFieldsAction
  | SetCustomerErrorsAction
  | SetCustomFieldsAndErrorsAction
  | SetEntireErrorsAction
  | SetPurchasedUserAction
  | SetLatestOrderAction
  | ClearCustomerErrorsAction;

export type DataSet = DataSetSubscription | DataSetTicketBook;

interface DataSetSubscription {
  schemeType: IPurchases.SchemeType.Subscription;
  customFieldsData: ICustomFields.MerchantPurchaseCustomFieldsData;
  confirmData: IConfirm.MerchantPurchaseConfirmData;
  useCaseData: IUseCase.MerchantPurchasedUseCaseData;
}

interface DataSetTicketBook {
  schemeType: IPurchases.SchemeType.TicketBook;
  customFieldsData: ICustomFields.MerchantPurchaseCustomFieldsData;
  confirmData: IConfirm.MerchantPurchaseConfirmData;
  useCaseData: IUseCase.MerchantPurchasedUseCaseData;
}

const PAYLOAD_TO_STATE_CONVERTERS: {
  [schemeType in IPurchases.SchemeType]: (
    dataSet: DataSet,
  ) => IPurchases.Settings | null;
} = {
  [IPurchases.SchemeType.Subscription]: (dataSet: DataSetSubscription) =>
    $.toSettingsSchemeTypeSubscription(dataSet),
  [IPurchases.SchemeType.TicketBook]: (dataSet: DataSetTicketBook) =>
    $.toSettingsSchemeTypeTicketBook(dataSet),
};

const initialState: IPurchases.State = {
  settings: null,
  step: 0,
  postable: false,
  publicId: '',
  productId: '',
  merchantProduct: null,
  jcbEnabled: false,
  firstCharge: null,
  lastOrder: null,
};

export default (
  state: IPurchases.State = initialState,
  action: Actions,
): IPurchases.State => {
  switch (action.type) {
    case Types.Init: {
      const {
        publicId,
        productId,
        productData,
        dataSet,
        loggedIn,
        subscribed,
        jcbEnabled,
        isEmailVerified,
        firstCharge,
      } = action.payload;
      const converter = PAYLOAD_TO_STATE_CONVERTERS[dataSet.schemeType];
      if (!converter) {
        throw new Error(
          `please defined a converter for modelType: "${dataSet.schemeType}"`,
        );
      }

      const merchantProduct = $.toMerchantProduct(productData);

      return {
        merchantProduct,
        publicId,
        productId,
        settings: converter(dataSet),
        step: $.toStep({
          schemeType: dataSet.schemeType,
          loggedIn,
          subscribed,
          isEmailVerified,
        }),
        postable: true,
        jcbEnabled,
        firstCharge,
        lastOrder: null,
      };
    }
    case Types.SetStep: {
      return {
        ...state,
        step: action.payload.step,
        renew: action.payload.renew,
      };
    }
    case Types.SetPostable: {
      return {
        ...state,
        postable: action.payload.postable,
      };
    }
    case Types.SetStripeErrors: {
      return {
        ...state,
        settings: $.attachPaymentErrors(state.settings!, action.payload.errors),
      };
    }
    case Types.SetCustomFields: {
      const settings = state.settings;
      const customer = $.toSettingCustomer(action.payload.customFields);
      return {
        ...state,
        settings: {
          ...settings!,
          customer,
        },
      };
    }
    case Types.SetCustomerErrors: {
      return {
        ...state,
        settings: $.attachCustomerErrors(
          state.settings!,
          action.payload.errors,
        ),
      };
    }
    case Types.SetCustomFieldsAndErrors: {
      const settings = state.settings;
      const customer = $.toSettingCustomerAndErrors(
        action.payload.customFields,
        action.payload.errors,
      );
      return {
        ...state,
        settings: {
          ...settings!,
          customer,
        },
      };
    }
    case Types.SetEntireErrors: {
      const settings = state.settings;
      return {
        ...state,
        settings: {
          ...settings!,
          completion: {
            ...settings!.completion,
            entireErrors: action.payload.errors,
          },
        },
      };
    }
    case Types.SetPurchasedUser: {
      const settings = state.settings;
      return {
        ...state,
        settings: {
          ...settings!,
          completion: {
            ...settings!.completion,
            verifiedUser: action.payload.verifiedUser,
            customerPublicId: action.payload.customer
              ? action.payload.customer.public_id
              : undefined,
          },
        },
      };
    }
    case Types.SetLastOrder: {
      return {
        ...state,
        lastOrder: action.payload.lastOrder || null,
      };
    }
    case Types.ClearCustomerError: {
      return {
        ...state,
        settings: $.dettachCustomerErrors(state.settings!, action.payload.id),
      };
    }
    default:
      return state;
  }
};

import classNames from 'classnames';
import React, { ReactNode, useCallback } from 'react';

import css from './Button.module.scss';

interface Props {
  disabled?: boolean;
  href?: string; // href propsが存在するときは <a> tagがレンダリングされます。
  onClick?: (event: React.MouseEvent) => void;
  isFull?: boolean;
  isGhost?: boolean;
  buttonSize?: ButtonSize;
  buttonColor?: ButtonColor;
  targetBlank?: boolean;

  // 非推奨. まずは割れ窓をなくしたく、Propsの型を制限した https://github.com/heyinc/rsv-frontend/pull/1139
  className_Deprecated?: string;
  forceATag?: boolean;
  children?: ReactNode;
}

export enum ButtonSize {
  XSmall = 'xsmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',
}

export enum ButtonColor {
  Primary = 'primary',
  Biz = 'biz',
  White = 'white',
  WhiteBiz = 'whiteBiz',
}

const Button: React.FC<Props> = ({
  buttonColor = ButtonColor.Primary,
  buttonSize = ButtonSize.Small,
  isGhost = false,
  isFull = false,
  disabled = false,
  forceATag = false,
  targetBlank = false,
  children,
  className_Deprecated,
  href,
  onClick,
}) => {
  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (disabled) {
        event.preventDefault();
        return;
      }

      if (onClick) {
        onClick(event);
      }
    },
    [onClick, disabled],
  );

  const makeClasses = useCallback((): string => {
    return classNames(
      css.Button,
      { [css.isFull]: isFull },
      { [css.isGhost]: isGhost },
      { [css.isXSmall]: buttonSize === ButtonSize.XSmall },
      { [css.isMedium]: buttonSize === ButtonSize.Medium },
      { [css.isLarge]: buttonSize === ButtonSize.Large },
      { [css.isXLarge]: buttonSize === ButtonSize.XLarge },
      { [css.isBiz]: buttonColor === ButtonColor.Biz },
      { [css.isWhite]: buttonColor === ButtonColor.White },
      { [css.isWhiteBiz]: buttonColor === ButtonColor.WhiteBiz },
      className_Deprecated,
    );
  }, [isFull, isGhost, buttonSize, buttonColor, className_Deprecated]);

  const classes = makeClasses();

  if (forceATag || href) {
    return (
      <a
        className={classes}
        onClick={handleClick}
        href={href}
        target={targetBlank ? '_blank' : undefined}
      >
        {children}
      </a>
    );
  } else {
    return (
      <button className={classes} disabled={disabled} onClick={handleClick}>
        {children}
      </button>
    );
  }
};

export default Button;

import classNames from 'classnames';
import React, { HTMLProps, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import css from './GlobalNav.module.scss';
import GlobalNavModalMenu from './GlobalNavModalMenu';
import UserMenu from './UserMenu';
import { useAuth } from '../../../hooks/api/auth';
import { useUserMe } from '../../../hooks/api/me';
import Link from 'next/link';
import { RSButton } from 'src/reservation-page/RSButton';

interface Props extends HTMLProps<{}> {
  logoImage: { url: string } | null;
  merchantPublicId: string | null;
  displayName: string | null;
}

const GlobalNav: React.FC<Props> = ({
  className,
  logoImage,
  merchantPublicId,
  displayName,
}) => {
  const intl = useIntl();
  const { user, isLoggedIn } = useUserMe();
  const { logout } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const toggleUserMenu = useCallback((flag: boolean) => {
    setIsActive(flag);
  }, []);

  const intlPref = 'components.common.layouts.globalHeader.';

  return (
    <nav className={classNames(css.GlobalNav, className)}>
      <div className={css.GlobalNav__Inner}>
        {logoImage && merchantPublicId ? (
          <a href={`/${merchantPublicId}`} className={css.GlobalNav__Logo}>
            <div>
              <img
                src={logoImage.url}
                alt="logo"
                className={css.GlobalNav__LogoImage}
              />
            </div>
          </a>
        ) : (
          <Link
            href={merchantPublicId ? `/${merchantPublicId}` : '/'}
            className={css.GlobalNav__DisplayName}
          >
            {displayName}
          </Link>
        )}

        <div className={css.GlobalNav__Buttons}>
          {isLoggedIn && (
            <a href="/_" className={css.GlobalNav__TextLink}>
              {intl.formatMessage({ id: intlPref + 'links.dashboard' })}
            </a>
          )}
          {isLoggedIn && user && (
            <UserMenu
              user={user}
              isActive={isActive}
              toggle={toggleUserMenu}
              logout={async () => {
                await logout();
                location.href = `/signin/user?return_to=${location.pathname}`;
              }}
            />
          )}
          {!isLoggedIn && (
            <>
              <div className={css.GlobalNav__LoginButtonWrapper}>
                <RSButton
                  variant="outline"
                  onClick={() =>
                    (location.href = `/signin/user?return_to=${location.pathname}`)
                  }
                >
                  {intl.formatMessage({ id: intlPref + 'buttons.signin' })}
                </RSButton>
              </div>
              <svg className={css.GlobalNav__MenuButton} onClick={openModal}>
                <use xlinkHref="#globalnav-menu" />
              </svg>
            </>
          )}
        </div>
      </div>
      {isModalOpen && <GlobalNavModalMenu handleClose={closeModal} />}
    </nav>
  );
};

export default GlobalNav;

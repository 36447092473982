import React, { FC } from 'react';
import dynamic from 'next/dynamic';

// デフォルトでSSRを無効化したコンポーネントを提供する
const Raw = dynamic(() => import('./components/Raw').then((mod) => mod.Raw), {
  ssr: false,
});

export interface DraftWysiwygViewerProps {
  value: string;
}

export const DraftWysiwygViewer: FC<DraftWysiwygViewerProps> = (props) => {
  return <Raw {...props} />;
};

import { useCallback } from 'react';
import { useUserMe } from '../me';
import { removeCookie, setCookie } from '../../../utils/cookie';
import axios from 'axios';
import { toErrorType } from '../../../domain/entities/Auth';
import securedAxios from '../../../utils/secured-axios';
import { apiRoot, fetchCsrfToken } from '../../../infrastructure/api';

export interface LoginQuery {
  email: string;
  password: string;
}

export interface LoginWithFbQuery {
  email: string;
  id: string;
  accessToken: string;
  lastName: string;
  firstName: string;
  expireAt: number;
}

export interface CreateUserQuery {
  lastName: string;
  firstName: string;
  email: string;
  password: string;
}

export interface CreateUserWithResvQuery {
  reservationId: number;
  password: string;
}

export const useAuth = () => {
  const { mutate: mutateUser } = useUserMe();

  const login = useCallback(
    async (email: string, password: string) => {
      try {
        // TODO: openapi
        const response = await securedAxios.post<
          { email: string; password: string; requested_by: string },
          { data: { token: string } }
        >(`${apiRoot()}/sessions`, {
          email,
          password,
          requested_by: 'web',
        });
        const { token } = response.data;
        mutateUser();
        setCookie('token', token);

        return {};
      } catch (err) {
        removeCookie('token');
        mutateUser();

        if (axios.isAxiosError(err) && err?.response?.data?.error?.code) {
          return { errorType: toErrorType(err.response.data.error.code) };
        }
        throw err;
      }
    },
    [mutateUser],
  );

  const loginWithFb = useCallback(
    async (query: LoginWithFbQuery) => {
      try {
        // TODO openapi
        const response = await securedAxios.post(
          `${apiRoot()}/sessions/omniauth`,
          {
            email: query.email,
            id: query.id,
            token: query.accessToken,
            last_name: query.lastName,
            first_name: query.firstName,
            expires_at: query.expireAt,
            provider: 'facebook',
            requested_by: 'web',
          },
        );
        const { token } = response.data;
        mutateUser();
        setCookie('token', token);

        return {};
      } catch (err) {
        removeCookie('token');
        mutateUser();

        if (axios.isAxiosError(err) && err?.response?.data?.error?.code) {
          return { errorType: toErrorType(err.response.data.error.code) };
        }
        throw err;
      }
    },
    [mutateUser],
  );

  const createUser = useCallback(
    async (query: CreateUserQuery) => {
      try {
        // TODO openapi
        const response = await securedAxios.post<
          {
            last_name: string;
            first_name: string;
            email: string;
            password: string;
          },
          { data: { token: string } }
        >(`${apiRoot()}/users/new`, {
          last_name: query.lastName,
          first_name: query.firstName,
          email: query.email,
          password: query.password,
        });
        const { token } = response.data;
        mutateUser();
        setCookie('token', token);

        return {};
      } catch (err) {
        removeCookie('token');
        mutateUser();

        if (axios.isAxiosError(err) && err?.response?.data?.error?.code) {
          return { errorType: toErrorType(err.response.data.error.code) };
        }
        throw err;
      }
    },
    [mutateUser],
  );

  const createUserWithResv = useCallback(
    async (query: CreateUserWithResvQuery) => {
      try {
        // TODO openapi
        const response = await securedAxios.post<
          { reservation_id: number; password: string },
          { data: { token: string } }
        >(`${apiRoot()}/users/new`, {
          reservation_id: query.reservationId,
          password: query.password,
        });
        const { token } = response.data;
        mutateUser();
        setCookie('token', token);

        return {};
      } catch (err) {
        removeCookie('token');
        mutateUser();

        if (axios.isAxiosError(err) && err?.response?.data?.error?.code) {
          return { errorType: toErrorType(err.response.data.error.code) };
        }
        throw err;
      }
    },
    [mutateUser],
  );

  const logout = useCallback(async () => {
    // railsのSessionsController#destroy を呼ぶ
    const csrfToken = await fetchCsrfToken();
    await securedAxios.post(
      `/logout?target=end_user`,
      {},
      {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken,
        },
      },
    );

    removeCookie('token');
    mutateUser(undefined);
  }, [mutateUser]);

  return { login, loginWithFb, createUser, createUserWithResv, logout };
};

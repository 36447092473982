import classNames from 'classnames';
import * as React from 'react';
import { useIntl } from 'react-intl';
import {
  localizeKey,
  VacancyStatus,
  VacancyStatusEnum,
} from '../../../../../../domain/entities/VacancyStatus';
import * as I from '../../../../../../interfaces/merchants/services';
import { SingleLineTruncate } from '../../../../../../components/LineTruncate';

import css from './SpMerchantCalendarCard.module.scss';

interface Props {
  service: I.Service;
  list?: boolean;
  timeZone?: string;
  shouldSummarizeTimeSlot: boolean;
  onClickCard: (event: I.Service) => void;
}

export const MerchantCalendarCard = ({
  list,
  timeZone,
  shouldSummarizeTimeSlot,
  service,
  onClickCard,
}: Props) => {
  const intl = useIntl();
  const {
    color,
    allDay,
    startAt,
    title,
    waitingListProvided,
    vacancy,
    reservable,
    full,
    isRegisteredWaitingList,
  } = service;

  const status: VacancyStatus = new VacancyStatus({
    vacancyCount: vacancy,
    full,
    waitingListProvided,
    isRegisteredWaitingList,
  });

  const isFull = status.vacancyAppearance === VacancyStatusEnum.SOLD_OUT;

  const classes = classNames(css.MerchantCalendarCard, {
    [css.isFull]: isFull,
    [css.isList]: list,
    [css.notReservable]: !reservable,
  });

  const accentClasses = classNames(
    css.MerchantCalendarCard__Accent,
    { [css.isFull]: isFull },
    { [css.isList]: list },
  );
  const accentStyles = {
    backgroundColor: color,
  };

  const titleClasses = classNames(
    css.MerchantCalendarCard__Title,
    { [css.isFull]: isFull },
    { [css.isList]: list },
  );

  const textClasses = classNames(
    css.MerchantCalendarCard__Text,
    { [css.isFull]: isFull },
    { [css.isList]: list },
  );

  const statusClasses = classNames(css.MerchantCalendarCard__Status, {
    [css.isList]: list,
    [css.hasVacancy]: vacancy,
    [css.isRegisteredWaitingList]: isRegisteredWaitingList,
  });

  let statusColor = color;
  if (vacancy && list) {
    statusColor = 'inherit';
  } else if (isFull) {
    statusColor = '#fff';
  }
  const statusStyles = {
    backgroundColor: isFull ? color : '#ffffff',
    borderColor: vacancy && list ? 'transparent' : color,
    color: statusColor,
  };

  const statusLocalizeKey = localizeKey(status.vacancyAppearance);

  return (
    <a className={classes} onClick={() => onClickCard(service)}>
      <div className={accentClasses} style={accentStyles} />
      <div className={textClasses}>
        <time className={css.MerchantCalendarCard__Time}>
          {!shouldSummarizeTimeSlot &&
            !allDay &&
            intl.formatTime(startAt, {
              timeZone,
              hour: 'numeric',
              minute: 'numeric',
            })}
        </time>
        <SingleLineTruncate className={titleClasses} maxLine={2} text={title} />
      </div>
      {!shouldSummarizeTimeSlot &&
        (status.vacancyAppearance === VacancyStatusEnum.AVAILABLE ? (
          <div className={statusClasses} style={statusStyles}>
            <span>
              {intl.formatMessage({
                id: 'merchants.services.status.vacancy',
              })}
            </span>
            <span className={css.MerchantCalendarCard__Vacancy}>
              {status.vacancyCount}
            </span>
          </div>
        ) : (
          status.vacancyAppearance !== VacancyStatusEnum.UNKNOWN && (
            <div className={statusClasses} style={statusStyles}>
              {intl.formatMessage({
                id: `merchants.services.status.${statusLocalizeKey}`,
              })}
            </div>
          )
        ))}
    </a>
  );
};

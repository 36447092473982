import { Action as _Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { MerchantBookingPageApiResponse } from '../../../../../interfaces/merchants/bookings';
import * as IAvailavilities from '../../../../../interfaces/merchants/bookings/availabilities';
import * as IConfirm from '../../../../../interfaces/merchants/bookings/confirm';
import * as ICourse from '../../../../../interfaces/merchants/bookings/courses';
import * as ICustomFields from '../../../../../interfaces/merchants/bookings/custom_fields';
import * as IEvent from '../../../../../interfaces/merchants/bookings/event';
import * as IEvents from '../../../../../interfaces/merchants/bookings/events';
import * as ILesson from '../../../../../interfaces/merchants/bookings/lessons';
import {
  MerchantBookingSelectedStaffApiResponse,
  MerchantBookingStaffApiResponse,
} from '../../../../../interfaces/merchants/bookings/staff';
import * as ITimeSlots from '../../../../../interfaces/merchants/bookings/time_slots';
import * as IBooks from '../../../../../interfaces/merchants/resources/books';
import * as IReserve from '../../../../../interfaces/reservations';

import * as $ from './helper';

export type Result<R> = ThunkAction<R, IBooks.State, undefined, Actions>;
export type Dispatch = ThunkDispatch<IBooks.State, undefined, Actions>;

export enum Types {
  Init = 'merchant/services/resources/books/init',
  SetStep = 'merchant/services/resources/books/setstep',
  SetFirstSelected = 'merchant/services/resources/books/setfirstselected',
  SetCalendar = 'merchant/services/resources/books/setcalendar',
  SetDateTimePicker = 'merchant/services/resources/books/setdatetimepicker',
  SetAvailableDates = 'merchant/services/resources/books/setavailabledates',
  SetAvailableTimes = 'merchant/services/resources/books/setavailabletimes',
  AddToWaitingList = 'merchant/services/resources/books/addtowaitinglist',
  AddToWaitingListErrors = 'merchant/services/resources/books/addtowaitinglisterrors',
  SetPaymentMethods = 'merchant/services/resources/books/setpaymentmethods',
  SetSelectPaymentMethod = 'merchant/services/resources/books/setselectpaymentmethod',
  SetErrors = 'merchant/services/resources/books/seterrors',
  ResetErrors = 'merchant/services/resources/books/reseterrors',
  SetPaymentErrors = 'merchant/services/resources/books/setpaymenterrors',
  ResetPaymentErrors = 'merchant/services/resources/books/resetpaymenterrors',
  SetFinishedStatus = 'merchant/services/resources/books/setfinishedstatus',
  SetStripeErrorDetailsOnCompletion = 'reservation_flow/set_stripe_error_details_on_completion',
  SetRemoteLockErrorDetailsOnCompletion = 'reservation_flow/set_remotelock_error_details_on_completion',
  SetCreateUserWithResv = 'merchant/services/resources/books/setcreateuserwithresv',
  SetPostable = 'merchant/services/resources/books/setpostable',
  SetCustomFields = 'merchant/services/resources/books/setcustomfields',
  Clear = 'merchant/services/resources/books/clear',
}

export interface InitAction extends _Action {
  type: Types.Init;
  payload: {
    merchantId: string;
    resourceId: string;
    loggedIn?: boolean;
    selectedDate?: string;
    resourceData: MerchantBookingPageApiResponse;
    dataSet: DataSet;
    jcbEnabled: boolean;
    isEmailVerified: boolean;
  };
}

export interface SetStepAction extends _Action {
  type: Types.SetStep;
  payload: { step: number; renew?: boolean };
}

export interface SetFirstSelectedAction extends _Action {
  type: Types.SetFirstSelected;
  payload: { firstSelected: IBooks.Switch };
}

export interface SetCalendarAction extends _Action {
  type: Types.SetCalendar;
  payload: { events: IEvents.MerchantBookingEventsData };
}

export interface SetDateTimePickerAction extends _Action {
  type: Types.SetDateTimePicker;
  payload: { timeSlots: ITimeSlots.MerchantBookingTimeSlotsData };
}

export interface SetAvailableDatesAction extends _Action {
  type: Types.SetAvailableDates;
  payload: {
    availableDates: IAvailavilities.MerchantBookingAvailableDatesData;
  };
}

export interface SetAvailableTimesAction extends _Action {
  type: Types.SetAvailableTimes;
  payload: {
    availableTimes: IAvailavilities.MerchantBookingAvailableTimesData;
    selectedDate: string;
  };
}

export interface AddToWaitingListAction extends _Action {
  type: Types.AddToWaitingList;
  payload: { timeSlot: IBooks.EventTimeSlot };
}

export interface AddToWaitingListErrorsAction extends _Action {
  type: Types.AddToWaitingListErrors;
  payload: { errors: IReserve.WaitingListErrors };
}

export interface SetPaymentMethodsAction extends _Action {
  type: Types.SetPaymentMethods;
  payload: { paymentMethods: IReserve.PaymentMethod[] };
}

export interface SetSelectPaymentMethodAction extends _Action {
  type: Types.SetSelectPaymentMethod;
  payload: {
    selectedPaymentMethod: IReserve.InputPaymentMethod;
    ctok?: string;
  };
}

export interface SetErrorsAction extends _Action {
  type: Types.SetErrors;
  payload: { errors: IReserve.Errors };
}

export interface ResetErrorsAction extends _Action {
  type: Types.ResetErrors;
  payload: { fieldName: string };
}

export interface SetPaymentErrorsAction extends _Action {
  type: Types.SetPaymentErrors;
  payload: { errors: stripe.StripeError };
}

export interface ResetPaymentErrorsAction extends _Action {
  type: Types.ResetPaymentErrors;
  payload: {};
}

export interface SetFinishedStatusAction extends _Action {
  type: Types.SetFinishedStatus;
  payload: {
    finishedStatus: IReserve.FinishedStatus;
    entireError?:
      | IReserve.InvalidReservationErrors
      | IReserve.BookingFailedReason;
  };
}

export interface SetCreateUserWithResvAction extends _Action {
  type: Types.SetCreateUserWithResv;
  payload: {
    reservationId: number;
    available: boolean;
  };
}

export interface SetPostableAction extends _Action {
  type: Types.SetPostable;
  payload: {
    postable: boolean;
  };
}

export interface SetCustomFieldsAction extends _Action {
  type: Types.SetCustomFields;
  payload: {
    customFields: ICustomFields.MerchantBookingCustomFieldsData;
  };
}

interface SetStripeErrorDetailsOnCompletionAction extends _Action {
  type: Types.SetStripeErrorDetailsOnCompletion;
  payload: {
    message: string;
  };
}

interface SetRemoteLockErrorDetailsOnCompletionAction extends _Action {
  type: Types.SetRemoteLockErrorDetailsOnCompletion;
  payload: {
    message: string;
  };
}
interface ClearAction extends _Action {
  type: Types.Clear;
}

export type Actions =
  | InitAction
  | SetStepAction
  | SetFirstSelectedAction
  | SetCalendarAction
  | SetPaymentMethodsAction
  | SetSelectPaymentMethodAction
  | SetErrorsAction
  | ResetErrorsAction
  | SetPaymentErrorsAction
  | ResetPaymentErrorsAction
  | SetFinishedStatusAction
  | SetCreateUserWithResvAction
  | SetDateTimePickerAction
  | SetAvailableDatesAction
  | SetAvailableTimesAction
  | AddToWaitingListAction
  | AddToWaitingListErrorsAction
  | SetPostableAction
  | SetCustomFieldsAction
  | SetStripeErrorDetailsOnCompletionAction
  | SetRemoteLockErrorDetailsOnCompletionAction
  | ClearAction;

export type DataSet =
  | DataSetModelTypeCourse
  | DataSetModelTypeCourseWithStaff
  | DataSetModelTypeSchool
  | DataSetModelTypeEvent;

interface DataSetModelTypeCourse {
  modelType: IBooks.ModelType.AvailabilityScheme;
  candidateViewType: IBooks.CandidateViewType;
  courseData: ICourse.MerchantBookingCourseData;
  customFieldsData: ICustomFields.MerchantBookingCustomFieldsData;
  confirmData: IConfirm.MerchantBookingConfirmData;
  selectedDate?: string;
  selectedCourseData?: ICourse.MerchantBookingSelectedCourseData;
}

interface DataSetModelTypeCourseWithStaff {
  modelType: IBooks.ModelType.AssignScheme;
  candidateViewType: IBooks.CandidateViewType;
  courseData: ICourse.MerchantBookingCourseData;
  staffData: MerchantBookingStaffApiResponse;
  customFieldsData: ICustomFields.MerchantBookingCustomFieldsData;
  confirmData: IConfirm.MerchantBookingConfirmData;
  selectedDate?: string;
  selectedCourseData?: ICourse.MerchantBookingSelectedCourseData;
  selectedStaffData?: MerchantBookingSelectedStaffApiResponse;
}

interface DataSetModelTypeSchool {
  modelType: IBooks.ModelType.SchoolScheme;
  candidateViewType: IBooks.CandidateViewType;
  eventData: IEvent.MerchantBookingEventData;
  lessonData: ILesson.MerchantBookingLessonData;
  customFieldsData: ICustomFields.MerchantBookingCustomFieldsData;
  confirmData: IConfirm.MerchantBookingConfirmData;
  selectedTimeSlotData?: ITimeSlots.MerchantBookingTimeSlotData;
}

interface DataSetModelTypeEvent {
  modelType: IBooks.ModelType.EventScheme;
  candidateViewType: IBooks.CandidateViewType;
  eventData: IEvent.MerchantBookingEventData;
  customFieldsData: ICustomFields.MerchantBookingCustomFieldsData;
  confirmData: IConfirm.MerchantBookingConfirmData;
  selectedTimeSlotData?: ITimeSlots.MerchantBookingTimeSlotData;
}

const PAYLOAD_TO_STATE_CONVERTERS: {
  [modelType in IBooks.ModelType]: (
    dataSet: DataSet,
  ) => IBooks.Settings | undefined;
} = {
  [IBooks.ModelType.AvailabilityScheme]: (dataSet: DataSetModelTypeCourse) =>
    $.toSettingsModelTypeCourse(dataSet),
  [IBooks.ModelType.EventScheme]: (dataSet: DataSetModelTypeEvent) =>
    $.toSettingsModelTypeEvent(dataSet),
  [IBooks.ModelType.AssignScheme]: (dataSet: DataSetModelTypeCourseWithStaff) =>
    $.toSettingsModelTypeCourseWithStaff(dataSet),
  [IBooks.ModelType.SchoolScheme]: (dataSet: DataSetModelTypeSchool) =>
    $.toSettingsModelTypeSchool(dataSet),
};

const initialState: IBooks.State = {
  jcbEnabled: false,
};

export default (
  state: IBooks.State = initialState,
  action: Actions,
): IBooks.State => {
  switch (action.type) {
    case Types.Init: {
      const {
        merchantId,
        resourceId,
        resourceData,
        dataSet,
        loggedIn,
        jcbEnabled,
        isEmailVerified,
      } = action.payload;
      const converter = PAYLOAD_TO_STATE_CONVERTERS[dataSet.modelType];
      if (!converter) {
        throw new Error(
          `please defined a converter for modelType: "${dataSet.modelType}"`,
        );
      }

      const merchantResource = $.toMerchantResource(resourceData);
      const isAuthenticated = loggedIn && isEmailVerified;

      return {
        merchantResource,
        merchantId,
        resourceId,
        settings: converter(dataSet),
        step: resourceData.require_customer_login && !isAuthenticated ? 0 : 1,
        postable: true,
        jcbEnabled,
      };
    }
    case Types.SetStep: {
      return {
        ...state,
        step: action.payload.step,
        renew: action.payload.renew,
      };
    }
    case Types.SetPostable: {
      return {
        ...state,
        postable: action.payload.postable,
      };
    }
    case Types.SetFirstSelected: {
      const _settings =
        state.settings! as IBooks.SettingsModelTypeCourseWithStaff;
      const settings: IBooks.SettingsModelTypeCourseWithStaff = {
        ..._settings!,
        bookingDateTimes: {
          ..._settings!.bookingDateTimes,
          firstSelected: action.payload.firstSelected,
        },
        bookingTargets: {
          ..._settings!.bookingTargets,
          selected: action.payload.firstSelected,
        },
      };
      return { ...state, settings };
    }
    case Types.SetCalendar: {
      const _settings = state.settings as
        | IBooks.SettingsModelTypeCourse
        | IBooks.SettingsModelTypeCourseWithStaff;
      const events = $.toEvents(action.payload.events);
      const settings = {
        ..._settings!,
        bookingDateTimes: {
          ..._settings!.bookingDateTimes,
          selectedDate: _settings!.bookingDateTimes.selectedDate,
          events,
        },
      };
      return { ...state, settings };
    }
    case Types.SetAvailableDates: {
      const _settings = state.settings as
        | IBooks.SettingsModelTypeCourse
        | IBooks.SettingsModelTypeCourseWithStaff;
      const settings = {
        ..._settings!,
        bookingDateTimes: {
          ..._settings!.bookingDateTimes,
          availableDates: action.payload.availableDates,
        },
      };
      return { ...state, settings };
    }
    case Types.SetAvailableTimes: {
      const _settings = state.settings as
        | IBooks.SettingsModelTypeCourse
        | IBooks.SettingsModelTypeCourseWithStaff;
      const settings = {
        ..._settings!,
        bookingDateTimes: {
          ..._settings!.bookingDateTimes,
          selectedDate: action.payload.selectedDate,
          availableTimes: action.payload.availableTimes,
        },
      };
      return { ...state, settings };
    }
    case Types.SetDateTimePicker: {
      if (state.settings!.modelType === IBooks.ModelType.SchoolScheme) {
        const _settings = state.settings as IBooks.SettingsModelTypeSchool;
        const timeSlots = $.toSchoolTimeSlots(action.payload.timeSlots);
        const settings = {
          ..._settings!,
          bookingDateTimePicker: {
            ..._settings!.bookingDateTimePicker,
            timeSlots,
          },
        };
        return { ...state, settings };
      }
      if (state.settings!.modelType === IBooks.ModelType.EventScheme) {
        const _settings = state.settings as IBooks.SettingsModelTypeEvent;
        const timeSlots = $.toEventTimeSlots(action.payload.timeSlots);
        const timeSlotsFlatten = action.payload.timeSlots.data.map(
          $.toEventTimeSlot,
        );
        const waitingList = action.payload.timeSlots.meta.waiting_listed.map(
          (w) => w.time_slot_id,
        );
        const settings = {
          ..._settings!,
          event: {
            ..._settings!.event,
            waitingListProvided:
              action.payload.timeSlots.meta.waiting_list_provided,
            waitingList: waitingList.concat(_settings!.event.waitingList),
            timeSlots,
            timeSlotsFlatten,
          },
        };
        return { ...state, settings };
      }
      return { ...state };
    }
    case Types.AddToWaitingList: {
      const _settings = state.settings as IBooks.SettingsModelTypeEvent;
      const _waitingList = _settings.event!.waitingList;
      _waitingList.push(action.payload.timeSlot.id);
      const settings = {
        ..._settings!,
        event: {
          ..._settings!.event,
          waitingList: _waitingList,
        },
      };
      return { ...state, settings };
    }
    case Types.AddToWaitingListErrors: {
      const _settings = state.settings as IBooks.SettingsModelTypeEvent;
      const settings = {
        ..._settings!,
        event: {
          ..._settings!.event,
          addWaitingListErrors: action.payload.errors,
        },
      };
      return { ...state, settings };
    }
    case Types.SetCustomFields: {
      const settings = state.settings;
      const userInfo = $.toSettingUserInfo(action.payload.customFields);
      return {
        ...state,
        settings: {
          ...settings!,
          userInfo,
        },
      };
    }
    case Types.SetPaymentMethods: {
      const settings = state.settings;
      const payment = $.toSettingPayment(action.payload.paymentMethods);
      return {
        ...state,
        settings: {
          ...settings!,
          payment,
        },
      };
    }
    case Types.SetSelectPaymentMethod: {
      const settings = state.settings;
      return {
        ...state,
        settings: {
          ...settings!,
          confirmation: {
            ...settings!.confirmation,
            selectedPaymentMethod: $.toSelectedPaymentMethod(
              action.payload.selectedPaymentMethod,
              action.payload.ctok,
            ),
          },
        },
      };
    }
    case Types.SetErrors: {
      return {
        ...state,
        settings: $.attachErrors(state.settings!, action.payload.errors),
      };
    }
    case Types.ResetErrors: {
      return {
        ...state,
        settings: $.detachErrors(state.settings!, action.payload.fieldName),
      };
    }
    case Types.SetPaymentErrors: {
      return {
        ...state,
        settings: $.attachPaymentErrors(state.settings!, action.payload.errors),
      };
    }
    case Types.ResetPaymentErrors: {
      return {
        ...state,
        settings: $.detachPaymentErrors(state.settings!),
      };
    }
    case Types.SetFinishedStatus: {
      const settings = state.settings;
      const { finishedStatus, entireError } = action.payload;

      return {
        ...state,
        settings: {
          ...settings!,
          completion: {
            ...settings!.completion,
            finishedStatus,
            entireError,
          },
        },
      };
    }
    case Types.SetStripeErrorDetailsOnCompletion: {
      // 本来はここでエラーコードからメッセージへの変換を行ったほうがいいのではないか
      // 現在はサーバサイドで翻訳してメッセージを返している。state内にcurrent localeを持たないため、現時点ではこの関数内では変換ができない
      const settings = state.settings;
      const { message } = action.payload;

      return {
        ...state,
        settings: {
          ...settings!,
          completion: {
            ...settings!.completion,
            errorDetail: { message },
          },
        },
      };
    }
    case Types.SetRemoteLockErrorDetailsOnCompletion: {
      const settings = state.settings;
      const { message } = action.payload;

      return {
        ...state,
        settings: {
          ...settings!,
          completion: {
            ...settings!.completion,
            errorDetail: { message },
          },
        },
      };
    }
    case Types.SetCreateUserWithResv: {
      const settings = state.settings;
      const { reservationId, available } = action.payload;
      return {
        ...state,
        settings: {
          ...settings!,
          completion: {
            ...settings!.completion,
            createUserWithResv: {
              ...settings!.completion.createUserWithResv,
              available,
              reservationId,
            },
          },
        },
      };
    }
    case Types.Clear: {
      return initialState;
    }
    default:
      return state;
  }
};

import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { dateTimeFromISO } from '../../calendarHelper';
import css from './SpCalendarController.module.scss';

type Props = {
  baseDate: string;
  hasPrev: boolean;
  onClickNext: () => void;
  onClickPrev: () => void;
};

const SpCalendarController: FC<Props> = ({
  baseDate,
  hasPrev,
  onClickPrev,
  onClickNext,
}) => {
  const intl = useIntl();
  const date = dateTimeFromISO(baseDate);
  return (
    <div className={css.root}>
      <button
        className={css.button}
        disabled={!hasPrev}
        onClick={onClickPrev}
        type="button"
      >
        &lt;
      </button>
      <div className={css.time}>
        {intl.formatMessage(
          { id: 'merchants.services.year' },
          { year: date.year },
        )}
        <span className={css.month}>
          <span className={css.month_number}>{date.month}</span>
          {intl.formatMessage({ id: 'merchants.services.month.suffix' }).trim()}
        </span>
      </div>
      <button className={css.button} onClick={onClickNext} type="button">
        &gt;
      </button>
    </div>
  );
};

export default SpCalendarController;

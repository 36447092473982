export interface ReservationsRequestStartData {
  public_id: string;
  resource_id: string;
  input: Input;
  booked_from: string;
}

export interface ReservationsRequestData {
  input: Input;
}

export type ReservationsRequestType =
  | ReservationsRequestStartData
  | ReservationsRequestData;

export interface InputPaymentMethodOnsite {
  method: 'onsite';
}

export interface InputPaymentMethodBankTransfer {
  method: 'bank_transfer';
}

export interface InputPaymentMethodSubscription {
  method: 'subscription';
  customer_subscription_id: string;
}

export interface InputPaymentMethodTicket {
  method: 'ticket';
  product_id: string;
}

export interface InputPaymentMethodCoupon {
  method: 'first_time_free_voucher';
  voucher_public_id: string;
  email: string;
}

export interface InputPaymentMethodUseAnotherCard {
  method: 'credit_card';
  restore_credit_card: false;
  ctok: string;
}

export interface InputPaymentMethodUseUsedCard {
  method: 'credit_card';
  restore_credit_card: true;
  ctok?: string;
}

export interface InputPaymentMethodUseTotallyNew {
  method: 'credit_card';
  ctok: string;
}

export type InputPaymentMethod =
  | InputPaymentMethodOnsite
  | InputPaymentMethodBankTransfer
  | InputPaymentMethodSubscription
  | InputPaymentMethodTicket
  | InputPaymentMethodUseAnotherCard
  | InputPaymentMethodUseUsedCard
  | InputPaymentMethodUseTotallyNew
  | InputPaymentMethodCoupon;

export type QuestionnaireAttribute =
  | { questionnaire_custom_field_id: string; value: string }
  | {
      questionnaire_custom_field_id: string;
      value: string[];
      value_type: 'array_type';
    };

export interface Input {
  course_id?: string;
  option_ids?: string[];
  staff_id?: string;
  lesson_id?: string;

  preferred_date?: string;
  preferred_time?: string;

  time_slot_id?: string;
  time_slot_ids?: string[];
  num_attendees?: number;

  survey_answer?: string;

  contact?: { [name: string]: string };

  questionnaires_attributes?: QuestionnaireAttribute[];

  payment_method?: InputPaymentMethod;

  terms_accepted?: boolean;
  line_access_token?: string | null;
}

export enum DisabledReason {
  LoginRequired = 'login_required',
  NoProducts = 'no_products',
  LimitExceeded = 'limit_exceeded',
  MultipleGuestsRequested = 'multiple_guests_requested',
  UnidentifiedSeller = 'unidentified_seller',
  MultipleReservationsRequested = 'multiple_reservadtions_requested',
}

export interface PaymentMethodUnspecified {
  method: 'unspecified';
  available: boolean;
  disabled_reasons?: DisabledReason[];
  price: string;
}

export interface PaymentMethodOnsite {
  method: 'onsite';
  available: boolean;
  disabled_reasons?: DisabledReason[];
  price: string;
}

export interface PaymentMethodBankTransfer {
  method: 'bank_transfer';
  available: boolean;
  disabled_reasons?: DisabledReason[];
  price: string;
}

export interface PaymentMethodCreditCard {
  method: 'credit_card';
  available: boolean;
  disabled_reasons?: DisabledReason[];
  price: string;
}

export interface PaymentMethodFirstTimeFreeCoupon {
  method: 'first_time_free_voucher';
  available: boolean;
  products: {
    public_id: string;
    name: string;
    description: string;
    price: string;
    type: string;
  }[];
}

export interface AvailableForPurchaseProduct {
  public_id: string;
  name: string;
  price: number;
  purchase_url: string;
  description: string;
  ticket_count?: number;
  expiration?: number;
}

export interface PurchasedTicket {
  public_id: string;
  name: string;
  remain_count?: number;
  total_count?: number;
  available: boolean;
  expires_at: number;
}

export interface PurchasedProducts {
  public_id: string;
  name: string;
  consumption: number;
  ticket_books: PurchasedTicket[];
}

export interface PaymentMethodTicket {
  method: 'ticket';
  purchased_products: PurchasedProducts[];
  products: AvailableForPurchaseProduct[];
  available: boolean;
  disabled_reasons?: DisabledReason[];
}

interface BookingLimitTimes {
  remain: number;
  total: number;
}

export interface BookingLimit {
  limited: true;
  per_month: BookingLimitTimes;
}

export interface BookingNoLimit {
  limited: false;
}

export interface PurchasedSubscription {
  public_id: string;
  name: string;
  available: boolean;
  booking_limit: BookingLimit | BookingNoLimit;
  expires_at: string | null;
}

export interface PaymentMethodSubscription {
  method: 'subscription';
  purchased_products: PurchasedSubscription[];
  products: AvailableForPurchaseProduct[];
  available: boolean;
  disabled_reasons?: DisabledReason[];
}

export type PaymentMethod =
  | PaymentMethodUnspecified
  | PaymentMethodOnsite
  | PaymentMethodBankTransfer
  | PaymentMethodCreditCard
  | PaymentMethodTicket
  | PaymentMethodSubscription
  | PaymentMethodFirstTimeFreeCoupon;

export enum ErrorType {
  Blank = 'blank',
  Taken = 'taken',
  Invalid = 'invalid',
  InvalidDomain = 'invalid_domain',
  LoginFailed = 'login_failed',
}

export enum BookingFailedReason {
  Occupied = 'occupied',
  Unexpected = 'unexpected',
  StripeErrorOccurred = 'stripe_error_occurred',
  CardError = 'card',
}

export interface Errors {
  survey_answer?: ErrorType[];
  resource_customer_fields?: {
    [name: string]: ErrorType[];
  };
  questionnaire_custom_fields?: {
    [id: string]: ErrorType[];
  };
  preferred_time?: BookingFailedReason[];
  payment_method?: BookingFailedReason;
  payment_error_detail?: FinishedErrorDetail;
  remotelock_error?: boolean;
}

export type FinishedErrorDetail = {
  message: string;
  stripe_error_code?: string | undefined;
};

export interface InvalidReservationErrors {
  details: { [key: string]: InvalidReservationErrorDetail };
}

export type InvalidReservationErrorDetail = string;

export enum FinishedStatus {
  Accepted = 'accepted',
  Pending = 'pending',
  Failed = 'failed',
}

// Errorsタイプは異なる種類のError(クレカエラーであったり、もっと単純なバリデーションエラーであったり)を表してしまっているのでoptionalなフィールドが多くなっている.
// その状態に対応するためにまずInvalidReservationErrors型を定義して始める
export type ReservationResponseError = Errors | InvalidReservationErrors;

export interface ReservationsResponseData {
  success: boolean;
  entered?: {
    reservation_id: number;
    payment_methods: PaymentMethod[];
    finished_status: FinishedStatus;
  };
  selected_payment_method?: InputPaymentMethod;
  create_user_with_resv?: boolean;
  google_calendar_template_url?: string;
  errors?: ReservationResponseError;
  errorCode?: number;
}

export interface ReservationsPaymentMethodsData {
  payment_methods: PaymentMethod[];
}

export function isInvalidReservationError(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arg: any,
): arg is InvalidReservationErrors {
  return arg.type === 'invalid_reservation';
}

export interface WaitingListErrors {
  email?: ErrorType[];
  first_name?: ErrorType[];
  last_name?: ErrorType[];
}

export interface WaitingListResponseData {
  success: boolean;
  errors?: WaitingListErrors;
}

export interface SessionsResponseData {
  token: string;
}

import {
  BookingFailedReason,
  BookingLimit,
  BookingNoLimit,
  InputPaymentMethod,
  InvalidReservationErrors,
  FinishedErrorDetail,
} from '../../../reservations';
import { MerchantBookingStatus } from '../../bookings';
import { ValidationType } from '../../bookings/custom_fields';

export interface PrimaryImage {
  meta: {
    width: number;
    height: number;
  };
  thumb?: {
    url: string;
  };
  square?: {
    url: string;
  };
  // c800x420?: {
  //   url: string;
  // };
  // p800x600?: {
  //   url: string;
  // };
  // p1280x1280?: {
  //   url: string;
  // };
}

export interface Image {
  url: string | undefined;
  thumb: { url: string | undefined };
  square: { url: string | undefined };
  large: { url: string | undefined };
  squareFill210210: { url: string | undefined };
}

export enum FinishedStatus {
  Accepted = 'accepted',
  Pending = 'pending',
  Failed = 'failed',
}

export interface CreateUserWithResv {
  reservationId: number;
  available: boolean;
}

export interface SettingCompletion {
  finishedStatus?: FinishedStatus;
  createUserWithResv?: CreateUserWithResv;
  reservable_period: ReservablePeriod;
  changeable: ChangeableType;
  cancelable: CancelableType;
  onlineMeeting: OnlineMeetingType;
  enabledRemoteLock: EnabledRemoteLock;
  entireError?: BookingFailedReason | InvalidReservationErrors | undefined;
  errorDetail?: FinishedErrorDetail;
}

// TODO: openapiが生成した型に置き換える
export type ReservablePeriod = {
  start_period: ReservablePeriodStart;
  end_period: ReservablePeriodEnd;
};

export type ReservablePeriodStart = {
  type: ReservablePeriodStart.type;
  datetime?: {
    datetime: string;
  };
  relative_day?: {
    base_days: number;
    seconds_from_base_days: number;
  };
  relative_month?: {
    base_months: number;
    seconds_from_base_months: number;
  };
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ReservablePeriodStart {
  export enum type {
    DATETIME = 'datetime',
    RELATIVE_DAY = 'relative_day',
    RELATIVE_MONTH = 'relative_month',
    NO_SETUP = 'no_setup',
  }
}

export type ReservablePeriodEnd = {
  type: ReservablePeriodEnd.type;
  datetime?: {
    datetime: string;
  };
  relative?: {
    seconds_from_base_checkin: number;
  };
  relative_day?: {
    base_days: number;
    seconds_from_base_days: number;
  };
  relative_month?: {
    base_months: number;
    seconds_from_base_months: number;
  };
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ReservablePeriodEnd {
  export enum type {
    DATETIME = 'datetime',
    RELATIVE = 'relative',
    RELATIVE_DAY = 'relative_day',
    RELATIVE_MONTH = 'relative_month',
    NO_SETUP = 'no_setup',
  }
}

export type ChangeableType =
  | ChangeableTypeAccept
  | ChangeableTypeNotAccept
  | undefined;

interface ChangeableTypeAccept {
  type: 'accept';
  until: number; // sec
}

interface ChangeableTypeNotAccept {
  type: 'not_accept';
}

export type CancelableType =
  | CancelableTypeAccept
  | CancelableTypeNotAccept
  | undefined;

interface CancelableTypeAccept {
  type: 'accept';
  until: number; // sec
  prepayable: boolean;
  refundableDaysLimit: number;
}

interface CancelableTypeNotAccept {
  type: 'not_accept';
}

export type OnlineMeetingType =
  | OnlineMeetingAccept
  | OnlineMeetingNotAccept
  | undefined;

interface OnlineMeetingAccept {
  auto_create: 'enabled';
}

interface OnlineMeetingNotAccept {
  auto_create: 'disabled';
}

export type EnabledRemoteLock = boolean | undefined;

export interface SettingConfirmation {
  resourceTerm: string | undefined;
  reservable_period: ReservablePeriod;
  changeable: ChangeableType;
  cancelable: CancelableType;
  onlineMeeting: OnlineMeetingType;
  enabledRemoteLock: EnabledRemoteLock;
  selectedPaymentMethod?: InputPaymentMethod;
}

export enum CardSelection {
  Used = 'used',
  Another = 'another',
}

export type CandidateViewType = 'calendar' | 'board' | 'list';

export enum DisabledReason {
  LoginRequired = 'login_required',
  NoProducts = 'no_products',
  LimitExceeded = 'limit_exceeded',
  MultipleGuestsRequested = 'multiple_guests_requested',
  UnidentifiedSeller = 'unidentified_seller',
  MultipleReservationsRequested = 'multiple_reservadtions_requested',
}

export interface PaymentMethodUnspecified {
  method: 'unspecified';
  price: string;
  available: boolean;
  disabled_reasons?: DisabledReason[];
}

export interface PaymentMethodOnsite {
  method: 'onsite';
  price: string;
  available: boolean;
  disabled_reasons?: DisabledReason[];
}

export interface PaymentMethodBankTransfer {
  method: 'bank_transfer';
  price: string;
  available: boolean;
  disabled_reasons?: DisabledReason[];
}

export interface PaymentMethodCreditCard {
  method: 'credit_card';
  price: string;
  available: boolean;
  disabled_reasons?: DisabledReason[];
}

export interface AvailableForPurchaseProduct {
  public_id: string;
  name: string;
  price: number;
  purchase_url: string;
}

export interface PurchasedTicket {
  public_id: string;
  name: string;
  remain_count?: number;
  total_count?: number;
  available: boolean;
  expires_at: number;
}

export interface PurchasedProducts {
  public_id: string;
  name: string;
  consumption: number;
  ticket_books: PurchasedTicket[];
}

export interface PaymentMethodTicket {
  method: 'ticket';
  purchased_products: PurchasedProducts[];
  products: AvailableForPurchaseProduct[];
  available: boolean;
  disabled_reasons?: DisabledReason[];
}

export interface PurchasedSubscription {
  public_id: string;
  name: string;
  available: boolean;
  booking_limit: BookingLimit | BookingNoLimit;
  expires_at: string | null;
}

export interface PaymentMethodSubscription {
  method: 'subscription';
  purchased_products: PurchasedSubscription[];
  products: AvailableForPurchaseProduct[];
  available: boolean;
  disabled_reasons?: DisabledReason[];
}

export interface PaymentMethodFirstTimeFreeCoupon {
  method: 'first_time_free_voucher';
  available: boolean;
  disabled_reasons?: DisabledReason[];
  products: {
    public_id: string;
    name: string;
    description: string;
    price: string;
    type: string;
  }[];
}

export type PaymentMethod =
  | PaymentMethodUnspecified
  | PaymentMethodOnsite
  | PaymentMethodBankTransfer
  | PaymentMethodCreditCard
  | PaymentMethodTicket
  | PaymentMethodSubscription
  | PaymentMethodFirstTimeFreeCoupon;

export type CreditCardError =
  | { type: 'invalid_number'; field: 'number' }
  | { type: 'invalid_expiry_month'; field: 'month' }
  | { type: 'invalid_expiry_year'; field: 'year' }
  | { type: 'invalid_cvc'; field: 'cvc' }
  | { type: 'incorrect_number'; field: 'number' }
  | { type: 'expired_card'; field: 'exp' }
  | { type: 'incorrect_cvc'; field: 'cvc' }
  | { type: 'card_declined'; field: 'card' }
  | { type: 'missing'; field: 'card' }
  | { type: 'processing_error'; field: 'card' }
  | { type: 'script_load_failed'; field: 'card' };

export interface SettingPayment {
  paymentMethods: PaymentMethod[];
  errors?: { creditCard: CreditCardError };
}

export interface QuestionnaireText {
  customFieldId: number;
  type: 'text';
  required: boolean;
  firstTimeOnly: boolean;
  label: string;
  errors?: ErrorType[];
}

export interface QuestionnaireTextArea {
  customFieldId: number;
  type: 'text_area';
  required: boolean;
  firstTimeOnly: boolean;
  label: string;
  errors?: ErrorType[];
}

export interface QuestionnaireSelect {
  customFieldId: number;
  type: 'select';
  required: boolean;
  firstTimeOnly: boolean;
  label: string;
  options: Array<{ label: string; value: string }>;
  errors?: ErrorType[];
}

export interface QuestionnaireRadio {
  customFieldId: number;
  type: 'radio';
  required: boolean;
  firstTimeOnly: boolean;
  label: string;
  options: Array<{ label: string; value: string }>;
  errors?: ErrorType[];
}

export interface QuestionnaireCheckbox {
  customFieldId: number;
  type: 'checkbox';
  required: boolean;
  firstTimeOnly: boolean;
  label: string;
  options: Array<{ label: string; value: string }>;
  errors?: ErrorType[];
}

export type Questionnaire =
  | QuestionnaireText
  | QuestionnaireTextArea
  | QuestionnaireSelect
  | QuestionnaireRadio
  | QuestionnaireCheckbox;

export interface Survey {
  question: string;
  required: boolean;
  errors?: ErrorType[];
}

export enum ErrorType {
  Blank = 'blank',
  Taken = 'taken',
  Invalid = 'invalid',
  InvalidDomain = 'invalid_domain',
  LoginFailed = 'login_failed',
}

export interface WaitingListErrors {
  email?: ErrorType[];
  first_name?: ErrorType[];
  last_name?: ErrorType[];
}

export interface CustomerField {
  name: string;
  defaultValue?: string;
  editable: boolean;
  required: boolean;
  validationType: ValidationType;
  customLabel?: string;
  errors?: ErrorType[];
}

export interface CustomerFields {
  fields: CustomerField[];
}

export interface SettingUserInfo {
  survey?: Survey;
  customerFields?: CustomerFields;
  questionnaires?: Questionnaire[];
}

export enum AvailabilityState {
  Available = 'available',
  Occupied = 'occupied',
}

export interface TimeSlotAvailability {
  date: string;
  value: string;
  time: number;
  state: AvailabilityState;
}

export interface DateTimeSlot {
  class: string;
  availabilities: TimeSlotAvailability[];
}

export interface AvailableDates {
  dates: string[];
  time_zone: string;
}

export interface EventAvailability {
  time: number;
  value: string;
  state: AvailabilityState;
}

export interface AvailableTimes {
  status: string;
  availabilities: EventAvailability[];
  time_zone: string;
}

export interface SettingBookingDateTimes {
  selectedDate?: string;
  firstSelected: Switch | undefined;
  candidateViewType: CandidateViewType;
  availableDates: AvailableDates;
  availableTimes: AvailableTimes;
  events: {
    checkinTimes: string[];
    dates: { [date: string]: DateTimeSlot };
  };
}

export type Instructor = RealStaff;

export interface SchoolTimeSlot {
  id: number;
  lessonId: number;
  capacity: number;
  vacancy: number;
  accepted: number;
  pending: number;
  instructors: Instructor[];
  date: string; // '2019-02-02'
  startTime: string;
  endTime: string;
  start: number;
  end: number;
}

export interface EventTimeSlot {
  id: number;
  // capacity: number;
  vacancy: number;
  accepted: number;
  pending: number;
  date: string; // '2019-02-02'
  startTime: string;
  endTime: string;
  start: number;
  end: number;
}

export type TimeSlot = SchoolTimeSlot | EventTimeSlot;

export interface SettingBookingDateTimePicker {
  selectedTimeSlot?: SchoolTimeSlot;
  selectedLessonId?: number;
  showVacancy: boolean;
  timeSlots: {
    [date: string]: SchoolTimeSlot[];
  };
}

export interface Option {
  description?: string;
  id: number;
  inputValue?: string;
  name: string;
  price: string | null;
  sortOrder: number;
  duration: number; // second
}

export interface Course {
  id: number;
  query_id: number;
  name: string;
  description: string;
  options: Option[];
  price?: string;
  image: Image;
  duration?: number; // second
  staffIds?: number[];
  acceptRandomAssign?: boolean;
}

export type Staff = RealStaff | RandomStaff;

export interface RandomStaff {
  id: '';
  query_id: '';
}

export interface RealStaff {
  id: number;
  query_id: number;
  name: string;
  email: string;
  image: Image;
  description: string;
  workday: {
    sunday: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
  };
  courseIds: number[];
}

export enum Switch {
  Course = 'Course',
  Staff = 'Staff',
}

export interface SettingBookingTargets {
  courses: Course[];
  selectedCourse?: Course;
  staffs?: { [id: number]: RealStaff };
  staffList?: RealStaff[];
  selected?: Switch | undefined;
  selectedStaff?: RealStaff;
}

export interface Lesson {
  id: number;
  name: string;
  description: string;
}

export interface SettingBookingTargetsLesson {
  selectedLesson?: Lesson;
  lessons: Lesson[];
}

export interface SettingEvent {
  name: string;
  description: string;
  selectSlotsType: 'list' | 'calendar';
  showVacancy: boolean;
  multipleAttendees: {
    allowed: boolean;
    max: number;
  };
  multipleSelectSlots: boolean;
  waitingListProvided: boolean | undefined;
  waitingList: number[];
  addWaitingListErrors?: WaitingListErrors;
  timeSlots: {
    [date: string]: EventTimeSlot[];
  };
  timeSlotsFlatten: EventTimeSlot[];
  selectedTimeSlot?: EventTimeSlot;
  attendeesCustomLabel?: string;
}

export type Settings =
  | SettingsModelTypeCourse
  | SettingsModelTypeCourseWithStaff
  | SettingsModelTypeSchool
  | SettingsModelTypeEvent;

export enum ModelType {
  AvailabilityScheme = 'AVAILABILITY_SCHEME',
  EventScheme = 'EVENT_SCHEME',
  AssignScheme = 'ASSIGN_SCHEME',
  SchoolScheme = 'SCHOOL_SCHEME',
}

export interface SettingsModelTypeCourse {
  modelType: ModelType.AvailabilityScheme;
  bookingTargets: SettingBookingTargets;
  bookingDateTimes: SettingBookingDateTimes;
  userInfo: SettingUserInfo;
  payment?: SettingPayment;
  confirmation: SettingConfirmation;
  completion: SettingCompletion;
}

export interface SettingsModelTypeCourseWithStaff {
  modelType: ModelType.AssignScheme;
  bookingTargets: SettingBookingTargets;
  bookingDateTimes: SettingBookingDateTimes;
  userInfo: SettingUserInfo;
  payment?: SettingPayment;
  confirmation: SettingConfirmation;
  completion: SettingCompletion;
}

export interface SettingsModelTypeSchool {
  modelType: ModelType.SchoolScheme;
  bookingTargets: SettingBookingTargetsLesson;
  bookingDateTimePicker: SettingBookingDateTimePicker;
  userInfo: SettingUserInfo;
  payment?: SettingPayment;
  confirmation: SettingConfirmation;
  completion: SettingCompletion;
}

export interface SettingsModelTypeEvent {
  modelType: ModelType.EventScheme;
  event: SettingEvent;
  userInfo: SettingUserInfo;
  payment?: SettingPayment;
  confirmation: SettingConfirmation;
  completion: SettingCompletion;
}

export interface State {
  settings?: Settings;
  step?: number;
  postable?: boolean;
  merchantId?: string;
  resourceId?: string;
  merchantResource?: MerchantResource;
  renew?: boolean;
  jcbEnabled: boolean;
}

export interface CoverContent {
  url: string;
  embed: string;
  thumbnailUrl: string;
}

export interface MerchantResource {
  name: string;
  modelType: string;
  nickname: string;
  status: MerchantBookingStatus;
  coverContentsType: string;
  primaryImage?: PrimaryImage;
  coverContent?: CoverContent;
  isJsonDescription: boolean;
  description?: string;
  descriptionText?: string;
  publicId: string;
  rateCount: number;
  rate?: number;
  prepaymentEnabled: boolean;
  onsitePaymentEnabled: boolean;
  payableWithTicket: boolean;
  payableWithSubscription: boolean;
  waitingListProvided: boolean;
  requireAcceptance: boolean;
  staffSelectable?: boolean;
  paymentStep: boolean;
}

import React, { Component, createContext, useContext } from 'react';

export enum ViewDevice {
  Detecting,
  WebPc,
  WebSp,
  Unknown,
}

export interface ViewDeviceContextProps {
  children?: React.ReactNode;
}

export interface ViewDeviceContextState {
  currentViewDevice: ViewDevice;
}

export const ViewDeviceContext = createContext<ViewDeviceContextState>({
  currentViewDevice: ViewDevice.Detecting,
});

export const useViewDevice = () => {
  return useContext(ViewDeviceContext);
};

export class ViewDeviceContextProvider extends Component<
  ViewDeviceContextProps,
  ViewDeviceContextState
> {
  public state: ViewDeviceContextState;
  private breakPointWidth = 940;

  constructor(props: ViewDeviceContextProps) {
    super(props);

    this.state = {
      currentViewDevice: ViewDevice.Unknown,
    };

    this.detectViewDevice = this.detectViewDevice.bind(this);
    this.onChangeDimensions = this.onChangeDimensions.bind(this);
  }

  public componentDidMount() {
    this.detectViewDevice();
    window.addEventListener('resize', this.onChangeDimensions);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.onChangeDimensions);
  }

  private detectViewDevice() {
    const viewDevice =
      window.innerWidth > this.breakPointWidth
        ? ViewDevice.WebPc
        : ViewDevice.WebSp;
    this.setState({
      ...this.state,
      currentViewDevice: viewDevice,
    });
  }

  private onChangeDimensions() {
    this.detectViewDevice();
  }

  public render() {
    return (
      <ViewDeviceContext.Provider value={this.state}>
        {this.props.children}
      </ViewDeviceContext.Provider>
    );
  }
}

// then make a consumer which will surface it
export const ViewDeviceContextConsumer = ViewDeviceContext.Consumer;

import React, { useMemo } from 'react';
import Linkify from 'react-linkify';
import { useIntl } from 'react-intl';

import { useUserMe } from 'src/hooks/api/me';
import { useGetReservationFlowCourseSchemeResource } from 'src/hooks/api/reservation-flow/useGetReservationFlowCourseSchemeResource';
import StringUtils from 'src/components/utils/StringUtils';
import { RSModal } from 'src/reservation-page/modality/RSModal';
import { RSButtonLink } from 'src/reservation-page/RSButtonLink';
import KantanIcon from 'src/reservation-page/icons/kantan-icon.svg';
import { RSKantanIconButtonAnchor } from 'src/reservation-page/RSKantanIconButtonAnchor';

import { DraftWysiwygViewer } from 'src/templates/merchants/resources/book/components/resource-detail-modal/DraftWysiwygViewer';
import { PaymentMethodsList } from 'src/templates/merchants/resources/book/components/resource-detail-modal/PaymentMethodsList';
import { CautionList } from 'src/templates/merchants/resources/book/components/resource-detail-modal/CautionList';

import { StaffList } from '../StaffList';
import { useGetReservationFlowCourseSchemeStaffMembers } from 'src/hooks/api/reservation-flow/useGetReservationFlowCourseSchemeStaffMembers';

export interface CourseTypeResourceDetailModalOnCalendarProps {
  isOpen: boolean;
  onRequireClosing: () => void;
  merchantPublicId: string;
  resourcePublicId: string;
  reservationPath?: string;
}

export const CourseTypeResourceDetailModalOnCalendar: React.FC<
  CourseTypeResourceDetailModalOnCalendarProps
> = ({
  isOpen,
  onRequireClosing,
  merchantPublicId,
  resourcePublicId,
  reservationPath,
}) => {
  const intl = useIntl();

  const { data: resource } = useGetReservationFlowCourseSchemeResource({
    parameter: {
      merchant_public_id: merchantPublicId,
      resource_public_id: resourcePublicId,
    },
  });
  const { data: staffMembersData } =
    useGetReservationFlowCourseSchemeStaffMembers({
      parameter: {
        merchant_public_id: merchantPublicId,
        resource_public_id: resourcePublicId,
      },
    });
  const { user, isUserLoading } = useUserMe();

  const isPrepaymentEnabled = useMemo(() => {
    return (
      !!resource &&
      resource.enabled_payment_methods.includes('credit_card') &&
      resource.is_prepayment_enabled
    );
  }, [resource]);

  if (resource === undefined) return null;
  if (isUserLoading) return null;

  if (!reservationPath) throw Error('never');

  const ButtonContent = (() => {
    // 組織管理者・スタッフアクセスの場合はresourceが存在しないので、予約ボタンのみ表示する
    if (
      !resource ||
      (user && resource?.acceptable_customer_login_status !== 'guest')
    ) {
      return (
        <RSButtonLink isBlock={true} href={reservationPath}>
          {intl.formatMessage({
            defaultMessage: '予約',
            id: 'lmS8qr',
            description:
              'src/templates/merchants/resources/book/course_type/components/CourseTypeResourceDetailModalOnCalendar/index.tsx',
          })}
        </RSButtonLink>
      );
    }

    return (
      <div className="flex flex-col gap-1.5 pc:flex-row">
        {resource?.acceptable_customer_login_status === 'all' && (
          <RSButtonLink isBlock={true} href={reservationPath}>
            {intl.formatMessage({
              defaultMessage: 'ゲスト予約',
              id: 'HnUN35',
              description:
                'src/templates/merchants/resources/book/course_type/components/CourseTypeResourceDetailModalOnCalendar/index.tsx',
            })}
          </RSButtonLink>
        )}
        {resource?.acceptable_customer_login_status === 'guest' && (
          <RSButtonLink isBlock={true} href={reservationPath}>
            {intl.formatMessage({
              defaultMessage: '予約',
              id: 'lmS8qr',
              description:
                'src/templates/merchants/resources/book/course_type/components/CourseTypeResourceDetailModalOnCalendar/index.tsx',
            })}
          </RSButtonLink>
        )}
        {resource?.acceptable_customer_login_status !== 'guest' && (
          <RSKantanIconButtonAnchor
            icon={<KantanIcon width="24" height="24" viewBox="0 0 24 24" />}
            isBlock={true}
            href={`/signin/user?return_to=${reservationPath}`}
            variant="black"
          >
            {intl.formatMessage({
              defaultMessage: 'ログインして予約',
              id: 'PpuffW',
              description:
                'src/templates/merchants/resources/book/course_type/components/CourseTypeResourceDetailModalOnCalendar/index.tsx',
            })}
          </RSKantanIconButtonAnchor>
        )}
      </div>
    );
  })();

  return (
    <RSModal
      title={intl.formatMessage({
        defaultMessage: '内容',
        id: 'PyakmV',
        description:
          'src/templates/merchants/resources/book/course_type/components/CourseTypeResourceDetailModalOnCalendar/index.tsx',
      })}
      isOpen={isOpen}
      closeText={intl.formatMessage({
        defaultMessage: '閉じる',
        id: 'X4qSN5',
        description:
          'src/templates/merchants/resources/book/course_type/components/CourseTypeResourceDetailModalOnCalendar/index.tsx',
      })}
      onRequireClosing={onRequireClosing}
    >
      <div className="px-2.5 pb-5 pt-3 pc:px-4 pc:pb-4 pc:pt-4">
        {resource.primary_image && (
          <img
            className="mb-2"
            src={resource.primary_image.thumb_url}
            width="100%"
            height="auto"
          />
        )}
        <div className="rs-font mb-2 text-16 font-bold text-gray-1000 pc:text-20">
          {resource.name}
        </div>
        <div className="hidden pc:block">{ButtonContent}</div>
        <div className="space-y-6">
          {(() => {
            if (resource.description !== null) {
              return <DraftWysiwygViewer value={resource.description} />;
            }

            if (resource.description_text !== null) {
              return (
                <Linkify>
                  {StringUtils.simpleFormat(resource.description_text)}
                </Linkify>
              );
            }

            return null;
          })()}

          {(() => {
            if (
              !staffMembersData ||
              staffMembersData.staff_members.length === 0
            ) {
              return null;
            }

            return (
              <div>
                <div className="mb-1.5">
                  <div className="rs-font text-24 font-normal text-gray-1000 pc:text-20">
                    {intl.formatMessage({
                      defaultMessage: '担当スタッフ',
                      id: 'N/wG7Q',
                      description:
                        'src/templates/merchants/resources/book/course_type/components/CourseTypeResourceDetailModalOnCalendar/index.tsx',
                    })}
                  </div>
                </div>

                <StaffList staffMembers={staffMembersData.staff_members} />
              </div>
            );
          })()}

          {(() => {
            if (resource.enabled_payment_methods.length === 0) {
              return null;
            }

            return (
              <div>
                <div className="mb-1.5">
                  <div className="rs-font text-24 font-normal text-gray-1000 pc:text-20">
                    {intl.formatMessage({
                      defaultMessage: 'お支払い方法',
                      id: '1cgek6',
                      description:
                        'src/templates/merchants/resources/book/course_type/components/CourseTypeResourceDetailModalOnCalendar/index.tsx',
                    })}
                  </div>
                </div>

                <PaymentMethodsList
                  enablePaymentMethods={resource.enabled_payment_methods}
                  isPriceUnique={resource.is_price_unique}
                  lowestPrice={resource.lowest_price}
                />
              </div>
            );
          })()}

          <div>
            <div className="mb-1.5">
              <div className="rs-font text-24 font-normal text-gray-1000 pc:text-20">
                {intl.formatMessage({
                  defaultMessage: '注意事項',
                  id: 'RcBuYm',
                  description:
                    'src/templates/merchants/resources/book/course_type/components/CourseTypeResourceDetailModalOnCalendar/index.tsx',
                })}
              </div>
            </div>

            <CautionList
              merchantTimeZone={resource.merchant_time_zone}
              reservablePeriod={resource.reservable_period}
              changeableUntil={resource.changeable_until}
              cancelableUntil={resource.cancelable_until}
              isPrepaymentEnabled={isPrepaymentEnabled}
            />
          </div>

          {(() => {
            const { access_info } = resource;

            if (access_info === null || access_info.address === null) {
              return null;
            }

            return (
              <div>
                <div className="mb-1.5">
                  <div className="rs-font text-24 font-normal text-gray-1000 pc:text-20">
                    {intl.formatMessage({
                      defaultMessage: 'アクセス',
                      id: 'KU0/lV',
                      description:
                        'src/templates/merchants/resources/book/course_type/components/CourseTypeResourceDetailModalOnCalendar/index.tsx',
                    })}
                  </div>
                </div>

                <div>
                  <div className="mb-2">
                    <div className="rs-font text-13 font-normal text-gray-1000 pc:text-12">
                      {access_info.address}
                    </div>
                  </div>

                  <div>
                    <a
                      className="block w-full"
                      href={access_info.google_map_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="block w-full object-contain"
                        src={access_info.google_map_image_url}
                        alt={intl.formatMessage({
                          defaultMessage: '地図',
                          id: 'nuVrju',
                          description:
                            'src/templates/merchants/resources/book/course_type/components/CourseTypeResourceDetailModalOnCalendar/index.tsx',
                        })}
                      />
                    </a>
                  </div>
                </div>
              </div>
            );
          })()}
        </div>
      </div>
      <div className="sticky bottom-0 left-0 right-0 border-t border-solid border-gray-50 bg-white px-2 py-2.5 pc:hidden">
        {ButtonContent}
      </div>
    </RSModal>
  );
};

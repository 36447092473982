import React, { FC, useEffect, useMemo, useState } from 'react';

import css from '../SpMerchantCalendarBody.module.scss';
import { MerchantCalendarCard } from './SpMerchantCalendarCard';

import * as I from '../../../../../../interfaces/merchants/services';
import { SpMerchantResourceSummarizeTimeSlotModal } from './SpMerchantResourceSummarizeTimeSlotModal';

import { format, isEqual, startOfDay } from 'date-fns';
import {
  VacancyStatus,
  VacancyStatusEnum,
} from '../../../../../../domain/entities/VacancyStatus';
import { useRouter } from 'next/router';
import { EventTypeResourceDetailModalOnCalendar } from 'src/templates/merchants/resources/book/event_type/components/EventTypeResourceDetailModalOnCalendar';
import { CourseTypeResourceDetailModalOnCalendar } from 'src/templates/merchants/resources/book/course_type/components/CourseTypeResourceDetailModalOnCalendar';
import { useGetReservationFlowVersion } from 'src/hooks/api/reservation-flow/useGetReservationFlowVersion';

type Props = {
  merchantPublicId: string;
  isList: boolean;
  serviceList: I.Service[];
  timeZone?: string;
};

export const MerchantCalendarBodyItemCard: FC<Props> = ({
  merchantPublicId,
  isList,
  serviceList,
  timeZone,
}) => {
  const router = useRouter();
  const [selectedEvent, setSelectedEvent] = useState<I.Service>();
  // time_slotを1日毎にまとめて表示するか
  const shouldSummarizeTimeSlot = merchantPublicId === 'hiramekistudio-spark';

  const services = useMemo(
    () =>
      shouldSummarizeTimeSlot
        ? Array.from(
            new Map(
              serviceList?.map((service) => {
                return [
                  service.publicId +
                    format(startOfDay(service.startAt), 'YYYYMMDDHHmm'),
                  service,
                ];
              }),
            ).values(),
          )
        : serviceList,
    [serviceList, shouldSummarizeTimeSlot],
  );

  const sameDayServices = useMemo(
    () =>
      serviceList?.filter(
        (e) =>
          e.publicId === selectedEvent?.publicId &&
          isEqual(startOfDay(e.startAt), startOfDay(selectedEvent.startAt)),
      ),
    [selectedEvent?.publicId, selectedEvent?.startAt, serviceList],
  );

  const handleBook = (event: I.Service) => {
    location.href = `${event.bookingUrl}#pageContent`;
  };

  const isEventType = selectedEvent?.modelType === 'EVENT_SCHEME';
  const isCourseType =
    selectedEvent?.modelType === 'AVAILABILITY_SCHEME' ||
    selectedEvent?.modelType === 'ASSIGN_SCHEME';

  const { data, isLoading: isReservationFlowVersionLoading } =
    useGetReservationFlowVersion(merchantPublicId, selectedEvent?.publicId);
  const isV2Flow = data?.reservation_flow_version === 'v2';

  useEffect(() => {
    if (
      selectedEvent &&
      !isReservationFlowVersionLoading &&
      !shouldSummarizeTimeSlot
    ) {
      if (!isV2Flow) {
        location.href = `${selectedEvent.bookingUrl}#pageContent`;
        return;
      }

      const [pathname, searchParams] = selectedEvent.bookingUrl.split('?');
      if (isEventType) {
        router.push(
          `${pathname}/book/event_type?${searchParams.replace(
            'selected_slot',
            'timeSlotId',
          )}`,
        );
        return;
      }
      if (isCourseType) {
        router.push(
          `${pathname}/book/course_type?${searchParams.replace(
            'selected_date',
            'date',
          )}`,
        );
        return;
      }
    }
  }, [
    isCourseType,
    isEventType,
    isV2Flow,
    isReservationFlowVersionLoading,
    router,
    selectedEvent,
    shouldSummarizeTimeSlot,
  ]);

  const handleClickCard = (event: I.Service) => {
    if (shouldSummarizeTimeSlot) {
      setSelectedEvent(event);
    } else {
      // 満席なら遷移させない
      const status: VacancyStatus = new VacancyStatus({
        vacancyCount: event.vacancy,
        full: event.full,
        waitingListProvided: event.waitingListProvided,
        isRegisteredWaitingList: event.isRegisteredWaitingList,
      });
      const isFull = status.vacancyAppearance === VacancyStatusEnum.SOLD_OUT;
      if (isFull) {
        return;
      }

      setSelectedEvent(event);
    }
  };

  const eventTypeNewReservationFlowPath = (() => {
    if (!selectedEvent) return undefined;
    const [pathname, searchParams] = selectedEvent.bookingUrl.split('?');
    return `${pathname}/book/event_type?${searchParams.replace(
      'selected_slot',
      'timeSlotId',
    )}`;
  })();

  const courseTypeNewReservationFlowPath = (() => {
    if (!selectedEvent) return undefined;
    const [pathname, searchParams] = selectedEvent.bookingUrl.split('?');
    return `${pathname}/book/course_type?${searchParams.replace(
      'selected_date',
      'date',
    )}`;
  })();

  if (!services) {
    return <div className={css.MerchantCalendarBody__ItemBody} />;
  }

  return (
    <div className={css.MerchantCalendarBody__ItemBody}>
      {services &&
        services.map((s) => (
          <MerchantCalendarCard
            service={s}
            shouldSummarizeTimeSlot={shouldSummarizeTimeSlot}
            key={s.digest}
            list={isList}
            timeZone={timeZone}
            onClickCard={handleClickCard}
          />
        ))}
      {selectedEvent &&
        shouldSummarizeTimeSlot &&
        !isV2Flow &&
        !isReservationFlowVersionLoading && (
          <SpMerchantResourceSummarizeTimeSlotModal
            handleClose={() => setSelectedEvent(undefined)}
            handleBook={handleBook}
            event={selectedEvent}
            sameDayServices={sameDayServices}
            merchantId={merchantPublicId}
          />
        )}
      {selectedEvent && shouldSummarizeTimeSlot && isV2Flow && isEventType && (
        <EventTypeResourceDetailModalOnCalendar
          isOpen={!!selectedEvent}
          onRequireClosing={() => setSelectedEvent(undefined)}
          merchantPublicId={merchantPublicId}
          resourcePublicId={selectedEvent?.publicId}
          reservationPath={eventTypeNewReservationFlowPath}
          isTimeSlotFull={selectedEvent?.full ?? undefined}
          start={selectedEvent?.startAt}
          end={selectedEvent?.endAt}
          vacancy={selectedEvent?.vacancy ?? undefined}
          isRegisteredWaitingList={selectedEvent?.isRegisteredWaitingList}
        />
      )}
      {selectedEvent && shouldSummarizeTimeSlot && isV2Flow && isCourseType && (
        <CourseTypeResourceDetailModalOnCalendar
          isOpen={!!selectedEvent}
          onRequireClosing={() => setSelectedEvent(undefined)}
          merchantPublicId={merchantPublicId}
          resourcePublicId={selectedEvent?.publicId}
          reservationPath={courseTypeNewReservationFlowPath}
        />
      )}
    </div>
  );
};

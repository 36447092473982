import * as React from 'react';

declare global {
  interface Window {
    adsbygoogle: unknown[];
  }
}

type Props = React.HTMLProps<{}>;

const AdsBottom = (_: Props) => {
  React.useEffect(() => {
    if (window.adsbygoogle) {
      window.adsbygoogle.push({});
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{
        display: 'block',
      }}
      data-ad-client={'ca-pub-7734657157218933'}
      data-ad-slot={'1763785009'}
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  );
};

export default AdsBottom;

import classNames from 'classnames';
import * as React from 'react';

import css from './Icon.module.scss';

interface Props {
  className?: string;
  name: string;
}

const Icon = ({ className, name }: Props) => {
  const classes = classNames(css.Icon, className);

  return (
    <svg className={classes}>
      <use xlinkHref={`#${name}`} />
    </svg>
  );
};

export default Icon;

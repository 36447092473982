import * as React from 'react';

import { filterXSS } from 'xss';
import { MerchantBookingPageApiResponse } from '../../interfaces/merchants/bookings';

import { MerchantReview } from '../../interfaces/merchants/reviews';
import { MerchantDetail } from '../../interfaces/merchants';

interface Props {
  bookingData?: MerchantBookingPageApiResponse;
  merchant?: MerchantDetail;
  reviews?: MerchantReview[];
}

function escape(str: string): string {
  return filterXSS(str);
}

export default class JsonLd extends React.PureComponent<Partial<Props>> {
  public render() {
    const jsonLdString = this.makeJsonLdString();
    if (jsonLdString === '') {
      return null;
    }
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: jsonLdString }}
      />
    );
  }

  private makeJsonLdString(): string {
    const structureData = [];

    if (this.props.merchant && !this.props.merchant.seo_setting?.no_index) {
      // localBusiness
      const merchantBusinessData = this.structureBusiness(
        this.props.merchant,
        this.props.reviews,
      );
      if (merchantBusinessData) {
        structureData.push(merchantBusinessData);
      }

      // aggregateRating
      if (
        this.props.merchant.merchant_setting.is_customer_review_active &&
        this.props.reviews
      ) {
        structureData.push(
          this.structureAggregateRating(
            this.props.merchant,
            this.props.bookingData,
          ),
        );
      }

      // product
      if (this.props.bookingData && this.props.bookingData.lowest_price) {
        structureData.push(
          this.structureProduct(
            this.props.merchant,
            this.props.bookingData,
            this.props.reviews,
          ),
        );
      }
    }

    try {
      return JSON.stringify(structureData);
    } catch (_) {
      return '';
    }
  }

  private structureAggregateRating(
    merchant: MerchantDetail,
    bookingPage?: MerchantBookingPageApiResponse,
  ) {
    return {
      '@context': 'https://schema.org/',
      '@type': 'AggregateRating',
      itemReviewed: this.structureItemReviewed(merchant, bookingPage),
      ratingValue: merchant.rate,
      ratingCount: merchant.rate_count,
    };
  }

  private structureItemReviewed(
    merchant: MerchantDetail,
    bookingData?: MerchantBookingPageApiResponse,
  ) {
    const imageUrl =
      bookingData &&
      bookingData.primary_image &&
      bookingData.primary_image.c800x420
        ? bookingData.primary_image.c800x420
        : merchant.cover_image.url;
    return {
      '@type': 'LocalBusiness',
      image: `${imageUrl}`,
      name: escape(`${bookingData ? bookingData.name : merchant.display_name}`),
      address: escape(`${merchant.address}`),
      telephone: escape(`${merchant.phone_number}`),
    };
  }

  private structureBusinessHours(merchant: MerchantDetail) {
    const specs: [object?] = [];
    const wdays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    merchant.business_hours.forEach((bh) => {
      if (bh.is_open) {
        specs.push({
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: [wdays[bh.weekday]],
          opens: bh.opening_hour.substring(0, 5),
          closes: bh.closing_hour.substring(0, 5),
        });
      }
    });
    if (specs.length === 0) {
      return null;
    }
    return specs;
  }

  private structureBusiness(
    merchant: MerchantDetail,
    reviews?: MerchantReview[],
  ) {
    const aggregateRatingData =
      merchant.merchant_setting.is_customer_review_active && reviews
        ? this.structureAggregateRating(merchant)
        : null;
    const merchantUrl = `https://coubic.com/${merchant.public_id}`;

    return {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      '@id': merchantUrl,
      image: [`${merchant.cover_image.url}`],
      name: escape(`${merchant.display_name}`),
      address: escape(`${merchant.address}`),
      telephone: `${merchant.phone_number}`,
      aggregateRating: aggregateRatingData,
      url: merchantUrl,
      description: escape(merchant.description),
      openingHoursSpecification: this.structureBusinessHours(merchant),
    };
  }

  private structureProduct(
    merchant: MerchantDetail,
    bookingData: MerchantBookingPageApiResponse,
    reviews?: MerchantReview[],
  ) {
    const aggregateRatingData =
      merchant.merchant_setting.is_customer_review_active && reviews
        ? this.structureAggregateRating(merchant)
        : null;
    const imageUrl =
      bookingData &&
      bookingData.primary_image &&
      bookingData.primary_image.c800x420
        ? bookingData.primary_image.c800x420
        : merchant.cover_image.url;
    const bookingUrl = `https://coubic.com/${merchant.public_id}/${bookingData.public_id}`;

    return {
      '@context': 'http://schema.org',
      '@type': 'Product',
      name: escape(`${bookingData.name}`),
      description: escape(`${bookingData.description_text}`),
      image: [imageUrl],
      brand: {
        '@type': 'Thing',
        name: escape(`${merchant.display_name}`),
      },
      offers: {
        '@type': 'Offer',
        priceCurrency: 'JPY',
        price: bookingData.lowest_price || null,
        availability: 'PreOrder',
        url: bookingUrl,
      },
      aggregateRating: aggregateRatingData,
    };
  }
}

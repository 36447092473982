import { combineReducers } from 'redux';
import { State as PurchasesState } from '../../interfaces/merchants/products/purchases';
import { State as PurchasesInputsState } from '../../interfaces/merchants/products/purchases/inputs';
import { State as ResourcesBooksState } from '../../interfaces/merchants/resources/books';
import { State as ResourcesBooksInputsState } from '../../interfaces/merchants/resources/books/inputs';
import { UserReduxState } from '../../interfaces/users';
import { MerchantsState } from '../types/merchants';
import authenticate, { State as AuthenticateState } from './authenticate';
import merchants from './merchants';
import merchantsProductsPurchases from './merchants/products/purchases';
import merchantsProductsPurchasesInputs from './merchants/products/purchases/inputs';
import merchantsResourcesBooks from './merchants/resources/books';
import merchantsResourcesBooksInputs from './merchants/resources/books/inputs';
import merchantsServices, { ServicesState } from './merchants/services';
import users from './users';

export type ReduxState = {
  authenticate: AuthenticateState;
  merchants: MerchantsState;
  merchantsProductsPurchases: PurchasesState;
  merchantsProductsPurchasesInputs: PurchasesInputsState;
  merchantsResourcesBooks: ResourcesBooksState;
  merchantsResourcesBooksInputs: ResourcesBooksInputsState;
  merchantsServices: ServicesState;
  users: UserReduxState;
};

export default combineReducers<ReduxState>({
  authenticate,
  merchants,
  merchantsProductsPurchases,
  merchantsProductsPurchasesInputs,
  merchantsResourcesBooks,
  merchantsResourcesBooksInputs,
  merchantsServices,
  users,
});

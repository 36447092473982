import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

/**
 * SSR or not SSR の状態に応じた API エンドポイントを返します
 * @returns {string} API エンドポイント
 */
export const apiRoot = () => {
  const { apiUrl, apiExternalUrl } = publicRuntimeConfig;
  const origin = typeof window !== 'undefined' ? apiExternalUrl : apiUrl;
  const path = '/api/v2';

  return `${origin || ''}${path}`;
};

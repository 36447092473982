import React from 'react';

import { EventSchemeResource } from '../../../../../../../interfaces/reservation_flow';
import { RSDescriptionList } from '../../../../../../../reservation-page/description-list/RSDescriptionList';

export interface StaffListProps {
  staffMembers: EventSchemeResource['staff_members'];
}

export const StaffList: React.FC<StaffListProps> = ({ staffMembers }) => {
  return (
    <RSDescriptionList>
      {staffMembers.map((staff) => {
        return (
          <div
            key={staff.canonical_id}
            className="box-border border-b border-l-0 border-r-0 border-t-0 border-solid border-gray-50  p-1.5 last-of-type:border-b-0"
          >
            <div className="flex items-center">
              <div className="mr-1.5 flex-none">
                <div className="h-[56px] w-[56px] rounded-full">
                  <img
                    className="block h-full w-full rounded-full object-cover"
                    src={staff.primary_image_url}
                    alt=""
                  />
                </div>
              </div>

              <div className="flex-auto">
                <dt className="rs-font text-16 font-bold text-gray-1000 pc:text-15">
                  {staff.name}
                </dt>
                {staff.description !== null && (
                  <dd className="mb-0 ml-0 mr-0 mt-[2px]">
                    <div className="rs-font text-16 font-normal text-gray-1000 pc:text-15">
                      {staff.description}
                    </div>
                  </dd>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </RSDescriptionList>
  );
};

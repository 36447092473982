import classNames from 'classnames';
import * as React from 'react';
import css from './BaseLayout.module.scss';
import MerchantGlobalFooter from './MerchantGlobalFooter';
import GlobalNav from './GlobalNav/GlobalNav';
import PreviewAlertBand from '../../molecules/PreviewAlertBand';

interface Props extends React.HTMLProps<{}> {
  isSitePreview: boolean;
  logoImage: { url: string } | null;
  merchantPublicId: string | null;
  displayName: string | null;
  isCoubicApp: boolean;
}

const BaseLayoutMerchant = (props: Props) => {
  const { isSitePreview, logoImage, merchantPublicId, displayName } = props;

  return (
    <div className={classNames(css.BaseLayout, props.className)}>
      {isSitePreview && <PreviewAlertBand />}
      {!props.isCoubicApp && (
        <GlobalNav
          logoImage={logoImage}
          merchantPublicId={merchantPublicId}
          displayName={displayName}
        />
      )}
      {props.children}
      {!props.isCoubicApp && (
        <div className={css.BaseLayout__Footer}>
          <MerchantGlobalFooter />
        </div>
      )}
    </div>
  );
};

export default BaseLayoutMerchant;

import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import Icon from '../../atoms/icons/Icon';
import css from './style.module.scss';

const AlertBand: FC = () => {
  const intl = useIntl();
  return (
    <div className={css.container}>
      <div>
        <Icon name="warning" className={css.warning_icon} />
      </div>
      <div className={css.text}>
        <p className={css.paragraph}>
          {intl.formatMessage({
            id: 'alertBand.thisSiteIsUnpublished',
          })}
          {intl.formatMessage({
            id: 'alertBand.toAcceptReservation',
          })}
          <a href="/dashboard/site" className={css.link}>
            {intl.formatMessage({
              id: 'alertBand.siteSetting',
            })}
          </a>
          {intl.formatMessage({
            id: 'alertBand.canPublishSite',
          })}
        </p>
      </div>
    </div>
  );
};

export default AlertBand;

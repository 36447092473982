import * as IPurchases from '../../../../../interfaces/merchants/products/purchases';
import * as IConfirm from '../../../../../interfaces/merchants/products/purchases/confirm';
import * as ICustomFields from '../../../../../interfaces/merchants/products/purchases/custom_fields';
import * as IUseCase from '../../../../../interfaces/merchants/products/purchases/use_case';

export const toMerchantProduct = (
  productData: IPurchases.MerchantProductData,
): IPurchases.MerchantProduct => ({
  schemeType:
    productData.scheme === 0
      ? IPurchases.SchemeType.Subscription
      : IPurchases.SchemeType.TicketBook,
  name: productData.name,
  publicId: productData.public_id,
  price: productData.price,
  image: productData.image,
  description: productData.description,
  expiration: productData.expiration,
  ticketCount: productData.ticket_count,
  waitFirstAnchor: productData.wait_first_anchor,
  availableMonthsPeriod: productData.available_months_period,
});

const toCreditCardError = (
  e: stripe.StripeError,
): IPurchases.CreditCardError => {
  switch (e.code) {
    case 'invalid_number':
      return { type: 'invalid_number', field: 'number' };
    case 'invalid_expiry_month':
      return { type: 'invalid_expiry_month', field: 'month' };
    case 'invalid_expiry_year':
      return { type: 'invalid_expiry_year', field: 'year' };
    case 'invalid_cvc':
      return { type: 'invalid_cvc', field: 'cvc' };
    case 'incorrect_number':
      return { type: 'incorrect_number', field: 'number' };
    case 'expired_card':
      return { type: 'expired_card', field: 'exp' };
    case 'incorrect_cvc':
      return { type: 'incorrect_cvc', field: 'cvc' };
    case 'card_declined':
      return { type: 'card_declined', field: 'card' };
    case 'missing':
      return { type: 'missing', field: 'card' };
    case 'processing_error':
      return { type: 'processing_error', field: 'card' };
    case 'script_load_failed':
      return { type: 'script_load_failed', field: 'card' };
    default:
      throw new Error('stripe errored');
  }
};

export const attachPaymentErrors = (
  settings: IPurchases.Settings,
  _error: stripe.StripeError,
): IPurchases.Settings => ({
  ...settings,
  payment: {
    ...settings.payment!,
    errors: { creditCard: toCreditCardError(_error) },
  },
});

export const attachCustomerErrors = (
  settings: IPurchases.Settings,
  _errors: IPurchases.PurchasingCustomerCustomFieldsError,
): IPurchases.Settings => ({
  ...settings,
  customer: {
    ...settings.customer!,
    customerFields: settings.customer.customerFields!.map((f) => ({
      ...f,
      errors: _errors[f.id],
    })),
  },
});

export const dettachCustomerErrors = (
  settings: IPurchases.Settings,
  id: number,
): IPurchases.Settings => ({
  ...settings,
  customer: {
    ...settings.customer!,
    customerFields: settings.customer.customerFields!.map((f) => ({
      ...f,
      errors: f.id === id ? undefined : f.errors,
    })),
  },
});

export const toSettingCustomerAndErrors = (
  customFields: ICustomFields.MerchantPurchaseCustomFieldsData,
  _errors: IPurchases.PurchasingCustomerCustomFieldsError,
): IPurchases.SettingCustomer => {
  const { customer_custom_fields } = customFields;
  const customer: IPurchases.SettingCustomer = {};
  if (customer_custom_fields) {
    customer.customerFields = customer_custom_fields.map((f) => ({
      id: f.id,
      inputType: f.input_type,
      validationType: f.validation_type,
      label: f.label,
      valueOptions: f.value_options,
      description: f.description,
      required: f.required,
      defaultValue: f.default_value,
      errors: _errors[f.id],
    }));
  }
  return customer;
};

export const toSettingCustomer = (
  customFields: ICustomFields.MerchantPurchaseCustomFieldsData,
): IPurchases.SettingCustomer => {
  const { customer_custom_fields } = customFields;
  const customer: IPurchases.SettingCustomer = {};
  if (customer_custom_fields) {
    customer.customerFields = customer_custom_fields.map((f) => ({
      id: f.id,
      inputType: f.input_type,
      validationType: f.validation_type,
      label: f.label,
      valueOptions: f.value_options,
      description: f.description,
      required: f.required,
      defaultValue: f.default_value,
    }));
  }
  return customer;
};

const toReservationCount = (
  reservationCount: IConfirm.ReservationCountData,
): IPurchases.ReservationCount => ({
  upcoming: reservationCount.upcoming,
  perMonth: reservationCount.per_month,
  perWeek: reservationCount.per_week,
  perDay: reservationCount.per_day,
});

export const toSettingConfirm = ({
  reservation_count,
  expiry_date,
}: IConfirm.MerchantPurchaseConfirmData): IPurchases.SettingConfirmation => ({
  reservationCount: toReservationCount(reservation_count),
  expiryDate: expiry_date,
});

export const toSettingComplete = (
  { reservation_count, expiry_date }: IConfirm.MerchantPurchaseConfirmData,
  { booking_pages }: IUseCase.MerchantPurchasedUseCaseData,
): IPurchases.SettingCompletion => ({
  bookingPages: booking_pages.map((f) => ({
    publicId: f.public_id,
    name: f.name,
    status: f.status,
    tagNames: f.tag_names,
    prepaymentEnabled: f.prepayment_enabled,
    onsitePaymentEnabled: f.onsite_payment_enabled,
    descriptionText: f.description,
    primaryImage: f.primary_image,
    waitingListProvided: f.waiting_list_provided,
  })),
  reservationCount: toReservationCount(reservation_count),
  expiryDate: expiry_date,
});

export const toSettingsSchemeTypeSubscription = ({
  customFieldsData,
  confirmData,
  useCaseData,
}: {
  customFieldsData: ICustomFields.MerchantPurchaseCustomFieldsData;
  confirmData: IConfirm.MerchantPurchaseConfirmData;
  useCaseData: IUseCase.MerchantPurchasedUseCaseData;
}): IPurchases.SettingsSubscription => {
  const customer = toSettingCustomer(customFieldsData);
  const payment = {};
  const confirmation = toSettingConfirm(confirmData);
  const completion = toSettingComplete(confirmData, useCaseData);

  return {
    schemeType: IPurchases.SchemeType.Subscription,
    customer,
    payment,
    confirmation,
    completion,
  };
};

export const toSettingsSchemeTypeTicketBook = ({
  customFieldsData,
  confirmData,
  useCaseData,
}: {
  customFieldsData: ICustomFields.MerchantPurchaseCustomFieldsData;
  confirmData: IConfirm.MerchantPurchaseConfirmData;
  useCaseData: IUseCase.MerchantPurchasedUseCaseData;
}): IPurchases.SettingsTicketBook => {
  const customer = toSettingCustomer(customFieldsData);
  const payment = {};
  const confirmation = toSettingConfirm(confirmData);
  const completion = toSettingComplete(confirmData, useCaseData);

  return {
    schemeType: IPurchases.SchemeType.TicketBook,
    customer,
    payment,
    confirmation,
    completion,
  };
};

export const toStep = (args: {
  schemeType: IPurchases.SchemeType;
  loggedIn: boolean;
  subscribed: boolean;
  isEmailVerified: boolean;
}) => {
  const { schemeType, loggedIn, subscribed, isEmailVerified } = args;
  const isAuthenticated = loggedIn && isEmailVerified;
  if (schemeType === IPurchases.SchemeType.Subscription) {
    if (subscribed) {
      return 99;
    }
    return isAuthenticated ? 1 : 0;
  }
  if (schemeType === IPurchases.SchemeType.TicketBook) {
    return isAuthenticated ? 1 : 0;
  }
  return 1;
};

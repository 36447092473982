export enum ErrorType {
  Failed = 'failed',
  Invalid = 'invalid',
  Taken = 'taken',
  Blank = 'blank',
}

export const toErrorType = (code: number) => {
  if (code === 100) {
    return ErrorType.Failed;
  }
  if (code === 200) {
    return ErrorType.Invalid;
  }
  if (code === 201) {
    return ErrorType.Taken;
  }
  return ErrorType.Blank;
};

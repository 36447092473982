export enum BookingPageCoverContentType {
  UploadImage = 'upload_image',
  Embed = 'embed',
}

export enum BookingPageScheme {
  Availability = 'AVAILABILITY_SCHEME',
  Event = 'EVENT_SCHEME',
  Assign = 'ASSIGN_SCHEME',
  School = 'SCHOOL_SCHEME',
  Post = 'POST_SCHEME',
  Text = 'TEXT_SCHEME',
  Photo = 'PHOTO_SCHEME',
}

export enum BookingPageStatus {
  Unpublished = 'unpublished',
  Closed = 'closed',
  FullyBooked = 'fully_booked',
  Accepting = 'accepting',
}

export enum BookingPageVacancyVisibility {
  Hidden = 'hidden',
  NoCapacity = 'no_capacity',
}

export enum BookingPageTagName {
  Accepting = 'accepting',
  RequireAcceptance = 'require_acceptance',
  BankTransfer = 'bank_transfer',
  Onsite = 'onsite',
  CreditCard = 'credit_card',
  Ticket = 'ticket',
  Subscription = 'subscription',
}

interface BookingPageCoverContentInit {
  url: string;
  embed: string;
  thumbnail_url: string;
}

export class BookingPageCoverContent {
  public readonly url: string;
  public readonly embed: string;
  public readonly thumbnailUrl: string;
  constructor(init: BookingPageCoverContentInit) {
    this.url = init.url;
    this.embed = init.embed;
    this.thumbnailUrl = init.thumbnail_url;
  }
}

export interface BookingPageDescription {
  isJson: boolean;
  json?: string;
  text?: string;
}

export interface BookingPagePaymentMethods {
  onlinePaymentEnabled: boolean;
  onsitePaymentEnabled: boolean;
  payableWithPrepayment: boolean;
  payableWithTicket: boolean;
  payableWithSubscription: boolean;
}

export interface BookingPagePrimaryImage {
  thumb?: {
    url: string;
  };
  square?: {
    url: string;
  };
  c800x420?: {
    url: string;
  };
  p800x600?: {
    url: string;
  };
  p1280x1280?: {
    url: string;
  };
}

interface BookingPageDataInit {
  cover_contents_type: BookingPageCoverContentType;
  cover_content?: BookingPageCoverContentInit;
  description: string;
  description_text: string;
  is_json_description: boolean;
  lowest_price?: number;
  unique_pricing: boolean;
  model_type: BookingPageScheme;
  name: string;
  nickname: string;
  onsite_payment_enabled: boolean;
  password_protected: boolean;
  payable_with_subscription: boolean;
  payable_with_ticket: boolean;
  payment_settings_enabled: boolean;
  prepayment_enabled: boolean;
  primary_image?: BookingPagePrimaryImage;
  public_id: string;
  published_at: number;
  published_with_password_protected: false;
  rate?: number;
  rate_count: number;
  require_acceptance: boolean;
  status: BookingPageStatus;
  tag_names: BookingPageTagName[];
  vacancy_visibility: BookingPageVacancyVisibility;
  waiting_list_provided: boolean;
}

interface BookingPageInit {
  size: number;
  total_size: number;
  total_pages: number;
  current_page: number;
  data: BookingPageDataInit[];
}

/**
 * @deprecated 定義が分散しているので、MerchantBooking に統一したい
 */
export default class BookingPage {
  public readonly coverContent?: BookingPageCoverContent;
  public readonly coverContentType: BookingPageCoverContentType;
  public readonly description: BookingPageDescription;
  public readonly name: string;
  public readonly paymentMethods: BookingPagePaymentMethods;
  public readonly lowestPrice?: number;
  public readonly uniquePricing: boolean;
  public readonly publicId: string;
  public readonly primaryImage?: BookingPagePrimaryImage;
  public readonly scheme: BookingPageScheme;
  public readonly status: BookingPageStatus;
  public readonly tagNames: BookingPageTagName[];
  public readonly vacancyVisibility: BookingPageVacancyVisibility;
  public readonly waitingListProvided: boolean;
  public readonly thumbnailUrl: string;

  constructor(init: BookingPageDataInit) {
    if (init.cover_content) {
      this.coverContent = new BookingPageCoverContent(init.cover_content);
    }
    this.coverContentType = init.cover_contents_type;
    this.name = init.name;
    this.paymentMethods = {
      onlinePaymentEnabled: init.payment_settings_enabled,
      onsitePaymentEnabled: init.onsite_payment_enabled,
      payableWithPrepayment: init.prepayment_enabled,
      payableWithSubscription: init.payable_with_subscription,
      payableWithTicket: init.payable_with_ticket,
    };
    this.lowestPrice = init.lowest_price;
    this.uniquePricing = init.unique_pricing;
    this.publicId = init.public_id;
    this.primaryImage = init.primary_image;
    this.scheme = init.model_type;
    this.status = init.status;
    this.tagNames = init.tag_names;
    this.vacancyVisibility = init.vacancy_visibility;
    this.waitingListProvided = init.waiting_list_provided;
    this.description = {
      isJson: init.is_json_description,
      json: init.description,
      text: init.description_text,
    };
    this.thumbnailUrl = this.initThumbnailUrl(init);
  }

  private initThumbnailUrl = (init: BookingPageDataInit): string => {
    // image
    if (init.primary_image) {
      return init.primary_image.square!.url;
    }
    // video
    if (init.cover_content) {
      return init.cover_content.thumbnail_url;
    }
    // nothing
    return '';
  };
}

export interface BookingPageBookableInit {
  bookable: {
    accepting: boolean;
    protected: boolean;
  };
}

export class BookingPageBookable {
  public readonly accepting: boolean;
  public readonly protected: boolean;

  constructor(init: BookingPageBookableInit) {
    this.accepting = init.bookable.accepting;
    this.protected = init.bookable.protected;
  }
}

export class Pagination {
  public readonly size: number;
  public readonly totalSize: number;
  public readonly totalPages: number;
  public readonly currentPage: number;

  constructor(inits: BookingPageInit) {
    this.size = inits.size;
    this.totalSize = inits.total_size;
    this.totalPages = inits.total_pages;
    this.currentPage = inits.current_page;
  }
}

export class BookingPageList {
  public readonly pagination: Pagination;
  public readonly bookingPages: BookingPage[];

  constructor(inits: BookingPageInit) {
    this.pagination = new Pagination(inits);
    this.bookingPages = [];
    inits.data.map((init) => {
      this.bookingPages.push(new BookingPage(init));
    });
  }

  get tagNames() {
    const tagNames: Map<string, BookingPageTagName[]> = new Map();
    this.bookingPages.forEach((b) => {
      tagNames[b.publicId] = b.tagNames;
    });

    return tagNames;
  }
}

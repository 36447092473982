import { NextRouter } from 'next/router';
import { sanitizeResourcePublicId } from '../../utils/sanitizeResourcePublicId';
import { MerchantDetail } from '../../interfaces/merchants';

interface ReservationUrl {
  url: string;
  targetBlank: boolean;
}

export const reservationUrlBy = (
  merchant: MerchantDetail,
  router?: NextRouter,
): ReservationUrl => {
  // to setting url with explore setting.
  if (
    merchant.merchant_setting.is_explore_only &&
    merchant.merchant_setting.explore_only_merchant_reservation_page_url !== ''
  ) {
    return {
      url: merchant.merchant_setting.explore_only_merchant_reservation_page_url,
      targetBlank: true,
    };
  }
  // for book detail page.
  if (
    router &&
    router.query &&
    router.query.resource_id &&
    typeof router.query.resource_id === 'string'
  ) {
    const resourceId = sanitizeResourcePublicId(router.query.resource_id);
    const bookingPath = `/${merchant.public_id}/${resourceId}/book`;
    const queryString = router.asPath!.split(/\?/)[1];
    return {
      url: queryString ? `${bookingPath}?${queryString}` : bookingPath,
      targetBlank: false,
    };
  }
  // for others.
  return {
    url: `/${merchant.public_id}/booking_pages#pageContent`,
    targetBlank: false,
  };
};

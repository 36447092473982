// %が出現する場合行末まで切り取る
const urlEncodedLikeRegexp = /%.*$/g;

const nonNumRegexp = /\D/g;

// 不正なresourcePublicIdがパスに混入していたときにそれを取り除く処理をする
// 今は resource public idは numericであるのでそれ以外を消せばok
// urlEncodedされてそうなパターンも削除してしまってOK
export const sanitizeResourcePublicId = (resourcePublicId: string): string => {
  return resourcePublicId
    .replace(urlEncodedLikeRegexp, '')
    .replace(nonNumRegexp, '');
};

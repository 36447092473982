import React, { FC } from 'react';
import Link, { LinkProps } from 'next/link';

import { RSButtonAnchor, RSButtonAnchorProps } from '../RSButtonAnchor';

export type RSButtonLinkProps = Omit<
  LinkProps,
  'passHref' | 'legacyBehavior'
> & {
  children?: RSButtonAnchorProps['children'];
  variant?: RSButtonAnchorProps['variant'];
  isBlock?: RSButtonAnchorProps['isBlock'];
  isDisabled?: RSButtonAnchorProps['isDisabled'];
};

export const RSButtonLink: FC<RSButtonLinkProps> = ({
  children,
  variant,
  isBlock,
  isDisabled,
  ...props
}) => {
  return (
    <Link {...props} passHref legacyBehavior>
      <RSButtonAnchor
        variant={variant}
        isBlock={isBlock}
        isDisabled={isDisabled}
      >
        {children}
      </RSButtonAnchor>
    </Link>
  );
};

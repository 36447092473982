import { Schemas } from '../../../openapi/generated/types';
import useSWR from 'swr';
import { useClientSideApiClient } from 'src/openapi/axiosClient';

export const useBookingCalendarMeta = (merchantPublicId: string) => {
  const swrKey = `/merchants/${merchantPublicId}/booking_calendar_meta`;
  const client = useClientSideApiClient();

  const fetcher = async () => {
    const data = await client.getBookingCalendarMeta({
      parameter: { merchant_public_id: merchantPublicId },
    });
    return data;
  };
  const { data, error, isValidating } = useSWR(swrKey, fetcher);

  return {
    data,
    error,
    isValidating,
  };
};

export const useStaffBookingPageMapping = (
  merchantPublicId: string,
  // 埋め込みカレンダーで絞り込んでいる予約ページがある場合は、アサインされていないスタッフは表示しない
  option?: { bookingPageIds?: string[] },
) => {
  const { data } = useBookingCalendarMeta(merchantPublicId);
  const bookingPages = data?.booking_pages;
  const targetBookingPageIds = option?.bookingPageIds;

  // key: staff_canonical_id, value: booking_page ids
  const staffBookingPageIdsMap: { [staffId: string]: string[] } = {};
  const staffMembers: Omit<
    Schemas.BookingCalendarMetaEntity['booking_pages'][number]['assigned_staff_members'][number],
    'image_url'
  >[] = [];

  const targetBookingPages = targetBookingPageIds
    ? bookingPages?.filter((bookingPage) =>
        targetBookingPageIds.includes(bookingPage.public_id),
      )
    : bookingPages;

  targetBookingPages?.forEach((bookingPage) => {
    bookingPage.assigned_staff_members.forEach((staff) => {
      if (staffBookingPageIdsMap[staff.canonical_id]) {
        staffBookingPageIdsMap[staff.canonical_id].push(bookingPage.public_id);
      } else {
        staffMembers.push({
          name: staff.name,
          canonical_id: staff.canonical_id,
          sort_order: staff.sort_order,
          updated_at: staff.updated_at,
        });
        staffBookingPageIdsMap[staff.canonical_id] = [bookingPage.public_id];
      }
    });
  });
  // 並び順の関心を持ちすぎているが、暫定対応
  const sortedByOrderStaffMembers = staffMembers.sort((a, b) => {
    if (a.sort_order < b.sort_order) {
      return -1;
    }
    if (a.sort_order > b.sort_order) {
      return 1;
    }
    return 0;
  });
  const result = sortedByOrderStaffMembers.sort((a, b) => {
    if (a.updated_at > b.updated_at) {
      return 1;
    }
    return 0;
  });

  return {
    staffBookingPageIdsMap,
    staffMembers: result,
    bookingPages: bookingPages ?? [],
  };
};

import React, { FC, ReactNode } from 'react';

export interface RSDescriptionListItemProps {
  term: string;
  description: ReactNode;
}

export const RSDescriptionListItem: FC<RSDescriptionListItemProps> = ({
  term,
  description,
}) => {
  return (
    <div className="box-border border-b border-l-0 border-r-0 border-t-0 border-solid border-gray-50  p-1.5 last-of-type:border-b-0">
      <dt className="rs-font mb-[2px] text-16 font-bold text-gray-1000 pc:text-15">
        {term}
      </dt>
      <dd className="m-0">{description}</dd>
    </div>
  );
};

import React, { FC, ReactNode } from 'react';

export interface RSDescriptionListProps {
  children?: ReactNode;
}

export const RSDescriptionList: FC<RSDescriptionListProps> = ({ children }) => {
  return (
    <dl className="m-0 box-border border-x-0 border-y border-solid border-gray-50 bg-white">
      {children}
    </dl>
  );
};

import useSWR, { SWRResponse, Key, useSWRConfig } from 'swr';
import { AxiosError } from 'axios';

import {
  Parameter$getReservationFlowCourseSchemeStaffMembers,
  Response$getReservationFlowCourseSchemeStaffMembers$Status$200,
} from 'src/openapi/generated/apiClient';
import { useClientSideApiClient } from 'src/openapi/axiosClient';
import { useCallback } from 'react';

export interface UseGetReservationFlowCourseSchemeStaffMembersParams {
  parameter: Parameter$getReservationFlowCourseSchemeStaffMembers;
  isDisabled?: boolean;
}

export type UseGetReservationFlowCourseSchemeStaffMembersValue = SWRResponse<
  Response$getReservationFlowCourseSchemeStaffMembers$Status$200['application/json'],
  AxiosError
>;

export const getGetReservationFlowCourseSchemeStaffMembersKey: (params: {
  parameter: Parameter$getReservationFlowCourseSchemeStaffMembers;
}) => Key = ({ parameter }) => {
  return {
    path: '/api/reservation_flow/merchants/{merchant_public_id}/course_scheme/resources/{resource_public_id}/staff_members',
    parameter,
  };
};

export const useGetReservationFlowCourseSchemeStaffMembers: (
  params: UseGetReservationFlowCourseSchemeStaffMembersParams,
) => UseGetReservationFlowCourseSchemeStaffMembersValue = ({
  parameter,
  isDisabled,
}) => {
  const client = useClientSideApiClient();
  const key = !isDisabled
    ? getGetReservationFlowCourseSchemeStaffMembersKey({ parameter })
    : null;

  const fetcher: () => Promise<
    Response$getReservationFlowCourseSchemeStaffMembers$Status$200['application/json']
  > = async () => {
    if (isDisabled) {
      throw new Error('Never');
    }

    try {
      const result = await client.getReservationFlowCourseSchemeStaffMembers({
        parameter,
      });

      return result;
    } catch (error) {
      throw error;
    }
  };

  return useSWR(key, fetcher);
};

export interface UseRevalidateGetReservationFlowCourseSchemeStaffMembersValue {
  revalidateGetReservationFlowCourseSchemeStaffMembers: (
    parameter: Parameter$getReservationFlowCourseSchemeStaffMembers,
  ) => Promise<void>;
}

export const useRevalidateGetReservationFlowCourseSchemeStaffMembers: () => UseRevalidateGetReservationFlowCourseSchemeStaffMembersValue =
  () => {
    const { mutate } = useSWRConfig();

    const revalidateGetReservationFlowCourseSchemeStaffMembers = useCallback(
      async (
        parameter: Parameter$getReservationFlowCourseSchemeStaffMembers,
      ) => {
        await mutate(
          getGetReservationFlowCourseSchemeStaffMembersKey({ parameter }),
        );
      },
      [mutate],
    );

    return { revalidateGetReservationFlowCourseSchemeStaffMembers };
  };

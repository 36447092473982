import React from 'react';
import { useIntl } from 'react-intl';
import {
  MerchantDetail,
  TemporaryDate,
  TemporaryHour,
  TemporaryBusinessHour,
  TemporaryClosingDay,
} from '../../../interfaces/merchants';
import DateTimeUtils, {
  TemporaryBusinessHoursTextArray,
  TemporaryClosingDaysTextArray,
} from '../../../components/utils/DateTimeUtils';
import css from './MerchantProfile.module.scss';
import { format, getDay, addDays } from 'date-fns';
import { coubicLocale } from 'src/utils/i18n';
import dayjs from 'dayjs';

interface Props {
  merchantDetail: MerchantDetail;
}

function formatTemporaryDateWithWeekday(
  date: string | Date,
  locale: coubicLocale,
) {
  const dateText = format(new Date(date), 'YYYY/MM/DD');
  const weekday = DateTimeUtils.formatWeekday(getDay(new Date(date)), locale);

  return dateText + '（' + weekday + '）';
}

function formatTemporaryBusinessHour(
  temporaryDate: TemporaryBusinessHour,
  locale: coubicLocale,
  timeZone: string,
): TemporaryBusinessHoursTextArray {
  const temporaryBusinessHour: TemporaryBusinessHoursTextArray = {
    date: '',
    hours: [],
  };
  temporaryBusinessHour.date = formatTemporaryDateWithWeekday(
    temporaryDate.start_at,
    locale,
  );

  temporaryDate.temporary_hours.forEach((h: TemporaryHour) => {
    const startHour = dayjs(h.start_at).tz(timeZone).format('HH:mm');
    let endHour = dayjs(h.end_at).tz(timeZone).format('HH:mm');
    if (endHour === '00:00') {
      endHour = '24:00';
    }
    temporaryBusinessHour.hours.push(startHour + ' - ' + endHour);
  });

  return temporaryBusinessHour;
}

function formatTemporaryClosingDay(
  temporaryDate: TemporaryClosingDay,
  locale: coubicLocale,
): TemporaryClosingDaysTextArray {
  const temporaryClosingDays: TemporaryClosingDaysTextArray = {
    startDate: '',
    endDate: '',
    reason: '',
  };
  temporaryClosingDays.startDate = formatTemporaryDateWithWeekday(
    temporaryDate.start_at,
    locale,
  );
  temporaryClosingDays.endDate = formatTemporaryDateWithWeekday(
    addDays(temporaryDate.end_at, -1),
    locale,
  );
  if (temporaryDate.reason) {
    temporaryClosingDays.reason = temporaryDate.reason;
  }

  return temporaryClosingDays;
}

function temporaryDatesText(
  temporaryDates: TemporaryDate[],
  locale: coubicLocale,
  timeZone: string,
) {
  const result: {
    temporaryBusinessHours: TemporaryBusinessHoursTextArray[];
    temporaryClosingDays: TemporaryClosingDaysTextArray[];
  } = { temporaryBusinessHours: [], temporaryClosingDays: [] };
  temporaryDates.forEach(
    ({ type, start_at, end_at, temporary_hours, reason }) => {
      if (type === 'open') {
        const formatTemporaryBusinessHourProps: TemporaryBusinessHour = {
          start_at: start_at,
          end_at: end_at,
          temporary_hours: temporary_hours,
        };
        result.temporaryBusinessHours.push(
          formatTemporaryBusinessHour(
            formatTemporaryBusinessHourProps,
            locale,
            timeZone,
          ),
        );
      } else {
        formatTemporaryClosingDay;
        const formatTemporaryClosingDayProps: TemporaryClosingDay = {
          start_at: start_at,
          end_at: end_at,
          reason: reason,
        };
        result.temporaryClosingDays.push(
          formatTemporaryClosingDay(formatTemporaryClosingDayProps, locale),
        );
      }
    },
  );

  return result;
}

const MerchantProfile = ({ merchantDetail }: Props) => {
  const intl = useIntl();
  const addressLink = merchantDetail.address
    ? `https://maps.google.com/?q=${encodeURIComponent(
        merchantDetail.address.replace(/\r?\n/g, ' '),
      )}`
    : undefined;
  let businessHoursTexts: string[] = [];
  if (merchantDetail.business_hours.length > 0) {
    businessHoursTexts = DateTimeUtils.formatBusinessHours(
      merchantDetail.business_hours,
      intl.locale as coubicLocale,
    );
  }
  const temporaryHoursTexts = merchantDetail.temporary_dates
    ? temporaryDatesText(
        merchantDetail.temporary_dates,
        intl.locale as coubicLocale,
        merchantDetail.time_zone,
      )
    : undefined;

  return (
    <dl className={css.MerchantProfile}>
      {merchantDetail.address && (
        <div className={css.MerchantProfile__Row}>
          <dt className={css.MerchantProfile__RowHead}>
            {intl.formatMessage({ id: 'merchants.details.address' })}
          </dt>
          <dd className={css.MerchantProfile__RowContent}>
            <span>{merchantDetail.address}</span>
            <br />
            <a
              className={css.MerchantProfile__RowBodySubLink}
              href={addressLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage({ id: 'merchants.details.openMap' })}
            </a>
          </dd>
        </div>
      )}
      {merchantDetail.phone_number && (
        <div className={css.MerchantProfile__Row}>
          <dt className={css.MerchantProfile__RowHead}>
            {intl.formatMessage({ id: 'merchants.details.phoneNumber' })}
          </dt>
          <dd className={css.MerchantProfile__RowContent}>
            <a href={`tel:${merchantDetail.phone_number}`}>
              {merchantDetail.phone_number}
            </a>
          </dd>
        </div>
      )}
      {merchantDetail.merchant_setting.show_business_hours && (
        <>
          {businessHoursTexts.length !== 0 && (
            <div className={css.MerchantProfile__Row}>
              <dt className={css.MerchantProfile__RowHead}>
                {intl.formatMessage({ id: 'merchants.details.businessHours' })}
              </dt>
              <dd className={css.MerchantProfile__RowContent}>
                {businessHoursTexts.map((txt, i) => (
                  <p
                    className={css.MerchantProfile__BusinessHourText}
                    key={`${txt}${i}`}
                  >
                    {txt}
                  </p>
                ))}
              </dd>
            </div>
          )}
          {temporaryHoursTexts &&
            temporaryHoursTexts.temporaryBusinessHours.length !== 0 && (
              <div className={css.MerchantProfile__Row}>
                <dt className={css.MerchantProfile__RowHead}>
                  {intl.formatMessage({
                    id: 'merchants.details.temporaryBusinessHours',
                  })}
                </dt>
                <table className={css.MerchantProfile__RowContent}>
                  <tbody>
                    {temporaryHoursTexts.temporaryBusinessHours.map(
                      (
                        temporaryBusinessHour: TemporaryBusinessHoursTextArray,
                      ) => (
                        <tr key={temporaryBusinessHour.date}>
                          <td
                            className={
                              css.MerchantProfile__TemporaryDateTextCell
                            }
                          >
                            {temporaryBusinessHour.date}
                          </td>
                          <td
                            className={
                              css.MerchantProfile__TemporaryDateSubTextCell
                            }
                          >
                            {temporaryBusinessHour.hours.map((hour: string) => (
                              <div>{hour}</div>
                            ))}
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              </div>
            )}
          {temporaryHoursTexts &&
            temporaryHoursTexts.temporaryClosingDays.length !== 0 && (
              <div className={css.MerchantProfile__Row}>
                <dt className={css.MerchantProfile__RowHead}>
                  {intl.formatMessage({
                    id: 'merchants.details.temporaryClosingDays',
                  })}
                </dt>
                <table className={css.MerchantProfile__RowContent}>
                  <tbody>
                    {temporaryHoursTexts.temporaryClosingDays.map(
                      (temporaryClosingDay: TemporaryClosingDaysTextArray) => (
                        <tr key={temporaryClosingDay.startDate}>
                          <td
                            className={
                              css.MerchantProfile__TemporaryDateTextCell
                            }
                          >
                            {temporaryClosingDay.startDate ===
                            temporaryClosingDay.endDate ? (
                              temporaryClosingDay.startDate
                            ) : (
                              <>
                                {temporaryClosingDay.startDate} - <br />
                                {temporaryClosingDay.endDate}
                              </>
                            )}
                          </td>
                          <td
                            className={
                              css.MerchantProfile__TemporaryDateSubTextCell
                            }
                          >
                            {temporaryClosingDay.reason}
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              </div>
            )}
        </>
      )}
    </dl>
  );
};

export default MerchantProfile;

import { Action as _Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import * as IInputs from '../../../../../interfaces/merchants/products/purchases/inputs';

export type Result<R> = ThunkAction<R, IInputs.State, undefined, Actions>;
export type Dispatch = ThunkDispatch<IInputs.State, undefined, Actions>;

export enum Types {
  Init = 'merchant/services/products/purchases/inputs/init',
  InputUserInfo = 'merchant/services/products/purchases/inputs/inputuserinfo',
  InputPayment = 'merchant/services/products/purchases/inputs/inputpayment',
}

export interface InitAction extends _Action {
  type: Types.Init;
  payload: { productId: string };
}

export interface InputUserInfoAction extends _Action {
  type: Types.InputUserInfo;
  payload: { userInfo: IInputs.UserInfo };
}

export interface InputPaymentAction extends _Action {
  type: Types.InputPayment;
  payload: { payment: IInputs.Payment };
}

export type Actions = InitAction | InputUserInfoAction | InputPaymentAction;

export default (state: IInputs.State = {}, action: Actions): IInputs.State => {
  switch (action.type) {
    case Types.Init: {
      return { ...action.payload };
    }
    case Types.InputUserInfo: {
      const { userInfo } = action.payload;
      return { ...state, userInfo };
    }
    case Types.InputPayment: {
      const { payment } = action.payload;
      return { ...state, payment };
    }
    default:
      return state;
  }
};

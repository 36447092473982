import React, { HTMLProps, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Linkify from 'react-linkify';
import {
  EMBED,
  MerchantBookingPageApiResponse,
  UPLOAD_IMAGE,
} from '../../../../../../interfaces/merchants/bookings';

import implBookingPageRepository from '../../../../../../infrastructure/repositories/BookingPageRepository';
import Button, {
  ButtonColor,
  ButtonSize,
} from '../../../../../../atoms/buttons/Button';
import StringUtils from '../../../../../../components/utils/StringUtils';
import MerchantTag from '../../../../common/tags/MerchantTag';
import css from './MerchantResourceSummaryModal.module.scss';
import Heading from '../../../../../../atoms/Heading';
import MerchantCourseDetailsStaffList from '../../../../resources/book/MerchantCourseDetailsStaffList';
import usePerformableStaffMembers from '../../../../../../hooks/api/usePerformableStaffMembers';
import { CalendarLoading } from '../../../CalendarLoading';
import dynamic from 'next/dynamic';
import { format } from 'date-fns';
import * as I from '../../../../../../interfaces/merchants/services';

interface Props extends HTMLProps<{}> {
  handleClose: () => void;
  handleBook: (event: I.Service) => void;
  event: I.Service;
  merchantId: string;
  sameDayServices: I.Service[];
}

const RichViewer = dynamic(
  () => import('../../../../../../components/RichViewer'),
  {
    ssr: false,
  },
);

// src/molecules/merchant/calendar/pc/MerchantResourceSummaryModal.tsx を元に同日程のtime_slotを表示する
export const SpMerchantResourceSummarizeTimeSlotModal: React.FC<Props> = ({
  handleBook,
  handleClose,
  event,
  merchantId,
  sameDayServices,
}) => {
  const intl = useIntl();
  const [bookingPage, setBookingPage] = useState<
    MerchantBookingPageApiResponse | undefined
  >(undefined);

  const { data } = usePerformableStaffMembers({
    merchantPublicId: merchantId,
    bookingPageId: event.publicId,
  });
  const performableStaffMembers = data?.data; // dataのdataとかややこしい...
  const containerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const fetcher = () => {
      const bookingPageId = event.publicId;

      const fetchers = [
        implBookingPageRepository.Find(merchantId, bookingPageId),
      ] as const;

      Promise.all(fetchers)
        .then((results) => {
          const newBookingPage = results[0];

          setBookingPage(newBookingPage);
        })
        .catch((err) => {
          throw err;
        });
    };
    fetcher();

    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef &&
        !containerRef.current?.contains(event.target as HTMLElement)
      ) {
        handleClose();
      }
    };

    document.body.style.overflow = 'hidden';
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.body.style.overflow = 'initial';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [event.publicId, handleClose, merchantId]);

  const intlPref = 'merchants.services.merchantFullCalendar.summary.';

  const [selectedEvent, setSelectedEvent] = useState(
    format(sameDayServices[0].startAt, 'YYYYMMDDHHmm'),
  );

  const BookingButton: React.FC<{ children: string }> = ({ children }) => {
    const handleClickBookingButton = () => {
      handleBook(
        sameDayServices.filter(
          (e) => format(e.startAt, 'YYYYMMDDHHmm') === selectedEvent,
        )[0],
      );
      return;
    };
    return (
      <Button
        buttonSize={ButtonSize.Medium}
        buttonColor={ButtonColor.Primary}
        onClick={handleClickBookingButton}
      >
        {children}
      </Button>
    );
  };

  if (!bookingPage) {
    return (
      <div className={css.MerchantResourceSummaryModal}>
        {!bookingPage && <CalendarLoading />}
      </div>
    );
  }

  let lowestPrice: { isVisible: true; price: number } | { isVisible: false } = {
    isVisible: false,
  };

  if (bookingPage.lowest_price != null && bookingPage.lowest_price > 0) {
    lowestPrice = { isVisible: true, price: bookingPage.lowest_price };
  }

  return (
    <div className={css.MerchantResourceSummaryModal}>
      <section
        className={css.MerchantResourceSummaryModal__Container}
        ref={containerRef}
      >
        <section className={css.MerchantResourceSummaryModal__Main}>
          {bookingPage.cover_contents_type === UPLOAD_IMAGE &&
            bookingPage.primary_image &&
            bookingPage.primary_image.c800x420 && (
              <section className={css.MerchantResourceSummaryModal__CoverImage}>
                <img
                  src={bookingPage.primary_image.c800x420.url}
                  alt="merchant-primary"
                />
              </section>
            )}
          {bookingPage.cover_contents_type === EMBED &&
            bookingPage.cover_content &&
            bookingPage.cover_content.thumbnail_url && (
              <section
                className={css.MerchantResourceSummaryModal__Embed}
                dangerouslySetInnerHTML={{
                  __html: bookingPage.cover_content.embed,
                }}
                style={{
                  backgroundImage: `url('${bookingPage.cover_content.thumbnail_url}')`,
                }}
              />
            )}

          <section className={css.MerchantResourceSummaryModal__Body}>
            <div className={css.MerchantResourceSummaryModal__Head}>
              <div className={css.MerchantResourceSummaryModal__HeadDate}>
                {intl.formatDate(event.startAt, {
                  month: 'narrow',
                  day: 'numeric',
                  weekday: 'short',
                })}
              </div>
            </div>
            <span
              className={css.MerchantResourceSummaryModal__ColorPalette}
              style={{ backgroundColor: event.color }}
            />
            <div className={css.MerchantResourceSummaryModal__Title}>
              {bookingPage.name}
            </div>
            <div className={css.MerchantResourceSummaryModal__Description}>
              {bookingPage.description ? (
                <RichViewer value={bookingPage.description} />
              ) : (
                bookingPage.description_text && (
                  <Linkify>
                    {StringUtils.simpleFormat(bookingPage.description_text)}
                  </Linkify>
                )
              )}
            </div>
            {performableStaffMembers && performableStaffMembers.length > 0 && (
              <div className={css.staffMembersWrap}>
                <Heading className={css.staffMemberSectionHeading}>
                  {intl.formatMessage({
                    id: 'books.formsLabel.staff',
                  })}
                </Heading>
                <MerchantCourseDetailsStaffList
                  staff={performableStaffMembers}
                />
              </div>
            )}
            {event.modelType === 'EVENT_SCHEME' && (
              <div className={css.marginTop40}>
                <Heading>
                  {intl.formatMessage({
                    id: intlPref + 'timeSelectionHeading',
                  })}
                </Heading>
                <p className={css.summarizeDateText}>
                  {format(event.startAt, 'MM月DD日')}
                </p>
                <table className={css.summarizeDateTable}>
                  <thead>
                    <tr>
                      <th>
                        {intl.formatMessage({
                          id: intlPref + 'time',
                        })}
                      </th>
                      <th>
                        {intl.formatMessage({
                          id: intlPref + 'capacity',
                        })}
                      </th>
                    </tr>
                  </thead>
                  <tbody className={css.summarizeDateTableBody}>
                    {sameDayServices.map((e) => {
                      const accepted =
                        e.capacity != null && e.vacancy != null
                          ? e.capacity - e.vacancy
                          : 0;
                      return (
                        <tr className={e.full ? css.cellDisabled : ''}>
                          <td>
                            <label>
                              <input
                                type="radio"
                                value={format(e.startAt, 'YYYYMMDDHHmm')}
                                onChange={(e) => {
                                  setSelectedEvent(e.target.value);
                                }}
                                checked={
                                  selectedEvent ===
                                  format(e.startAt, 'YYYYMMDDHHmm')
                                }
                                style={{
                                  width: 24,
                                  height: 24,
                                  marginRight: 8,
                                }}
                                disabled={e.full ?? false}
                              />
                              {format(e.startAt, 'HH:mm')}
                            </label>
                          </td>
                          <td>
                            <>
                              {accepted}/{e.capacity}{' '}
                              {e.full &&
                                intl.formatMessage({
                                  id: intlPref + 'full',
                                })}
                            </>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
            <div className={css.marginTop40}>
              {lowestPrice.isVisible && (
                <div className={css.MerchantResourceSummaryModal__Price}>
                  <Heading>
                    {intl.formatMessage({ id: intlPref + 'priceHeading' })}
                  </Heading>
                  <p>
                    {intl.formatMessage(
                      { id: intlPref + 'priceRange' },
                      {
                        price: intl.formatNumber(lowestPrice.price),
                      },
                    )}
                  </p>
                </div>
              )}
            </div>
            {bookingPage.tag_names.length > 0 && (
              <div className={css.MerchantResourceSummaryModal__Tags}>
                <p>
                  {bookingPage.tag_names.map((tag, i) => (
                    <MerchantTag key={i} status={tag} />
                  ))}
                </p>
              </div>
            )}
          </section>
        </section>
        <section className={css.MerchantResourceSummaryModal__Footer}>
          <Button
            buttonSize={ButtonSize.Medium}
            buttonColor={ButtonColor.White}
            onClick={handleClose}
          >
            {intl.formatMessage({ id: intlPref + 'actions.close' })}
          </Button>
          <BookingButton>
            {intl.formatMessage({ id: intlPref + 'actions.book' })}
          </BookingButton>
        </section>
      </section>
    </div>
  );
};

import classNames from 'classnames';
import React, { HTMLProps } from 'react';
import { useIntl } from 'react-intl';
import Button, { ButtonColor, ButtonSize } from '../../../atoms/buttons/Button';
import css from './GlobalNavModalMenu.module.scss';

interface Props extends HTMLProps<{}> {
  handleClose: () => void;
}

const GlobalNavModalMenu: React.FC<Props> = (props) => {
  const { className } = props;
  const intl = useIntl();
  const intlPref = 'components.common.layouts.globalHeader.';

  return (
    <nav className={classNames(css.GlobalNavModalMenu, className)}>
      <Button
        buttonColor={ButtonColor.White}
        buttonSize={ButtonSize.Large}
        className_Deprecated={css.GlobalNavModalMenu__Button}
        isGhost={true}
        forceATag
        onClick={() =>
          (location.href = `/signin/user?return_to=${location.pathname}`)
        }
      >
        {intl.formatMessage({ id: intlPref + 'buttons.signin' })}
      </Button>
      <svg
        className={css.GlobalNavModalMenu__Close}
        onClick={props.handleClose}
      >
        <use xlinkHref="#close" />
      </svg>
    </nav>
  );
};

export default GlobalNavModalMenu;

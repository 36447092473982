import * as React from 'react';
import { useIntl } from 'react-intl';
import { MerchantDetail } from '../../../interfaces/merchants';
import AdsBottom from '../../../components/adsense/AdsBottom';
import Button, { ButtonSize } from '../../../atoms/buttons/Button';
import BaseLayoutMerchant from '../../../components/layouts/BaseLayoutMerchant';
import MerchantHeader from '../MerchantHeader';
import css from './BaseTemplate.module.scss';
import { MerchantNavOrganism } from '../MerchantNav';

interface Props extends React.HTMLProps<{}> {
  merchantDetail: MerchantDetail;
  bookingPageIsBookable?: boolean;
  showCoverImage?: boolean;
  isCoubicApp: boolean;
}

/**
 * Templete component for merchant show & child page.
 */
const BaseTemplate = ({
  merchantDetail,
  bookingPageIsBookable,
  showCoverImage,
  isCoubicApp,
  ...props
}: Props) => {
  const intl = useIntl();
  const isSitePreview = !merchantDetail.site_published;

  return (
    <BaseLayoutMerchant
      className={css.BaseTemplate}
      isSitePreview={isSitePreview}
      logoImage={merchantDetail.logo_image}
      merchantPublicId={merchantDetail.public_id}
      displayName={merchantDetail.display_name}
      isCoubicApp={isCoubicApp}
    >
      <MerchantHeader
        className={css.BaseTemplate__Header}
        merchantDetail={merchantDetail}
        bookingPageIsBookable={bookingPageIsBookable}
        showCoverImage={showCoverImage}
      />
      <div
        id="pageContent"
        className={
          showCoverImage
            ? css.BaseTemplateWithCoverImage__Body
            : css.BaseTemplateWithoutCoverImage__Body
        }
      >
        <MerchantNavOrganism merchantDetail={merchantDetail} />
        <div className={css.BaseTemplate__Content}>{props.children}</div>
      </div>
      {!merchantDetail.feature.is_ad_hiding && !isCoubicApp && (
        <div className={css.BaseTemplate__AdsSection}>
          <AdsBottom />
          <div className={css.BaseTemplate__UpgradeSection}>
            <Button
              className_Deprecated={css.BaseTemplate__Button}
              isGhost={true}
              isFull={true}
              href={
                '/subscriptions?utm_source=premium_upgrade&utm_medium=ads&utm_campaign=premium_upgrade'
              }
              buttonSize={ButtonSize.Medium}
            >
              {intl.formatMessage({
                id: 'merchants.baseTemplate.upgradeButton',
              })}
            </Button>
          </div>
        </div>
      )}
    </BaseLayoutMerchant>
  );
};

export default BaseTemplate;

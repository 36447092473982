import classNames from 'classnames';
import { times } from 'lodash-es';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useIntl } from 'react-intl';

import css from './SpMerchantCalendarBody.module.scss';

import * as I from '../../../../../interfaces/merchants/services';
import {
  dateTimeFromFormat,
  dateTimeFromISO,
  DISPLAY_DATES,
  eachDay,
} from '../../calendarHelper';
import { MerchantCalendarBodyItem } from './components/SpMerchantCalendarBodyItem';

const wrapToScrollLoading = (
  contents: JSX.Element[],
  fetchNext?: () => void,
  list?: boolean,
  doneLoading?: boolean,
): JSX.Element | JSX.Element[] => {
  if (!list) {
    return contents;
  }
  if (!fetchNext) {
    return contents;
  }
  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={fetchNext}
      hasMore={!doneLoading}
      loader={
        <div className={css.MerchantCalendarBody__LoaderWrapper} key={0}>
          <div className={css.MerchantCalendarBody__Loader} />
        </div>
      }
    >
      {contents}
    </InfiniteScroll>
  );
};
interface Props {
  merchantPublicId: string;
  isList: boolean;
  doneLoading?: boolean; // only work on list mode
  baseDate: string;
  services: I.ServiceMap;
  meta?: I.Meta;
  timeZone: string;
  fetchNext?: () => void;
}

export const SpMerchantCalendarBody = ({
  merchantPublicId,
  baseDate,
  isList,
  doneLoading,
  meta,
  timeZone,
  services,
  fetchNext,
}: Props) => {
  const classes = classNames(css.MerchantCalendarBody, {
    [css.isList]: isList,
  });
  const intl = useIntl();

  const lBaseDate = dateTimeFromISO(baseDate);

  const displayDates = isList
    ? Object.keys(services)
        .filter((d) =>
          lBaseDate.hasSame(
            dateTimeFromFormat(d, 'yyyyMMdd', lBaseDate.zone),
            'month',
          ),
        )
        .map((d) =>
          dateTimeFromFormat(d, 'yyyyMMdd', lBaseDate.zone).toJSDate(),
        )
    : eachDay(lBaseDate, DISPLAY_DATES - 1)
        .filter((d) => d.hasSame(lBaseDate, 'month'))
        .map((d) => d.toJSDate());

  const itemClasses = classNames(css.MerchantCalendarBody__Item, {
    [css.isList]: isList,
  });

  const displayLength = isList ? displayDates.length : DISPLAY_DATES;

  if (doneLoading && Object.keys(displayDates).length === 0) {
    return (
      <div className={classes}>
        <div className={css.MerchantCalendarBody__Empty}>
          {intl.formatMessage({ id: 'merchants.services.empty' })}
        </div>
      </div>
    );
  }

  const contents = times(displayLength).map((index) => (
    <MerchantCalendarBodyItem
      merchantPublicId={merchantPublicId}
      key={`calendarBodyItem${index}`}
      itemClasses={itemClasses}
      date={displayDates[index]}
      isList={isList}
      services={services}
      closingDay={meta && meta.closingDay}
      timeZone={timeZone}
    />
  ));

  return (
    <ul className={classes}>
      {wrapToScrollLoading(contents, fetchNext, isList, doneLoading)}
    </ul>
  );
};

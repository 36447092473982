import classNames from 'classnames';
import * as React from 'react';

import css from './Heading.module.scss';

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const Heading = (props: Props) => {
  const { children, className, tag, ...rest } = props;
  const Tag = tag || 'h2';
  const classes = classNames(css.Heading, className);

  return (
    <Tag className={classes} {...rest}>
      {children}
    </Tag>
  );
};

export default Heading;

import React, { ChangeEventHandler, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SelectBox } from '../../../../common/SelectBox';
import css from './SpCalendarHeader.module.scss';
import CalendarType from './SpCalendarTypeSelect';
import CalendarController from './SpCalendarController';
import { Schemas } from '../../../../../openapi/generated/types';

interface Props {
  baseDate: string;
  isList: boolean;
  hasPrev: boolean;
  onClickCalendar: () => void;
  onClickList: () => void;
  onClickNext: () => void;
  onClickPrev: () => void;
  onSelectStaff: (staffId: string) => void;
  onSelectBookingPage: (publicId: string) => void;
  staffMembers: Pick<Schemas.Staff, 'name' | 'canonical_id'>[];
  bookingPages: Schemas.BookingCalendarMetaEntity['booking_pages'];
}

const SpAllBookingPagesCalendarHeader = ({
  baseDate,
  isList,
  hasPrev,
  onClickCalendar,
  onClickList,
  onClickNext,
  onClickPrev,
  onSelectStaff,
  onSelectBookingPage,
  staffMembers,
  bookingPages,
}: Props) => {
  const intl = useIntl();
  const handleSelectStaff = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    (e) => {
      e.preventDefault();
      onSelectStaff(e.target.value);
    },
    [onSelectStaff],
  );
  const handleSelectBookingPage = useCallback<
    ChangeEventHandler<HTMLSelectElement>
  >(
    (e) => {
      e.preventDefault();
      onSelectBookingPage(e.target.value);
    },
    [onSelectBookingPage],
  );

  return (
    <div className={css.root}>
      <CalendarType
        isList={isList}
        onClickCalendar={onClickCalendar}
        onClickList={onClickList}
      />
      <div className={css.mx_16}>
        <SelectBox onChange={handleSelectBookingPage}>
          <option value={''}>
            {intl.formatMessage({
              id: 'merchants.services.allBookingPages',
            })}
          </option>
          {bookingPages &&
            bookingPages.map((bookingPage) => (
              <option key={bookingPage.public_id} value={bookingPage.public_id}>
                {bookingPage.name}
              </option>
            ))}
        </SelectBox>
      </div>
      {staffMembers.length > 0 && (
        <div className={css.mx_16}>
          <SelectBox onChange={handleSelectStaff}>
            <option value="">
              {intl.formatMessage({
                id: 'merchants.services.assignedStaffMembers',
              })}
            </option>
            {staffMembers.map((staff) => {
              return (
                <option key={staff.canonical_id} value={staff.canonical_id}>
                  {staff.name}
                </option>
              );
            })}
          </SelectBox>
        </div>
      )}
      <CalendarController
        baseDate={baseDate}
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
        hasPrev={hasPrev}
      />
    </div>
  );
};

export default SpAllBookingPagesCalendarHeader;

import React from 'react';
import { MerchantBookingStaffApi } from '../../../../../interfaces/merchants/bookings/staff/index';
import css from './MerchantCourseDetailsStaffList.module.scss';

interface Props {
  staff: MerchantBookingStaffApi[];
}

const MerchantCourseDetailsStaffList: React.FC<Props> = ({ staff }) => {
  return (
    <div className={css.wrapper}>
      {staff.map((s) => (
        <div key={s.id} className={css.listItem}>
          <div className={css.listItemThumb}>
            <img
              className={css.listItemImg}
              src={s.image.url}
              alt={s.name}
              width="80"
              height="80"
            />
          </div>
          <div>
            <div className={css.listItemName}>{s.name}</div>
            <div className={css.listItemDescription}>{s.description}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MerchantCourseDetailsStaffList;

import classNames from 'classnames';
import { includes } from 'lodash-es';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { DateTime } from 'luxon';

import css from '../SpMerchantCalendarBody.module.scss';

import * as I from '../../../../../../interfaces/merchants/services';

import { MerchantCalendarBodyItemCard } from './SpMerchantCalendarBodyItemCard';

type Props = {
  merchantPublicId: string;
  itemClasses: string;
  date?: Date;
  isList: boolean;
  services: I.ServiceMap;
  closingDay?: number[];
  timeZone: string;
};

export const MerchantCalendarBodyItem: FC<Props> = ({
  merchantPublicId,
  itemClasses,
  date,
  isList,
  services,
  closingDay,
  timeZone,
}) => {
  const intl = useIntl();
  if (!date) {
    // 月末の余白
    const blankHeaderClasses = classNames(css.MerchantCalendarBody__ItemHeader);
    return (
      <li className={itemClasses}>
        <div className={blankHeaderClasses} />
        <div className={css.MerchantCalendarBody__ItemBody} />
      </li>
    );
  }
  const dateTime = DateTime.fromJSDate(date).setZone(timeZone);
  const mapServiceKey = dateTime.toFormat('yyyyMMdd');
  const weekday = intl.formatDate(dateTime.toJSDate(), {
    weekday: 'short',
    timeZone,
  });
  const headerClasses = classNames(css.MerchantCalendarBody__ItemHeader, {
    [css.isSat]: dateTime.weekday === 6,
    [css.isSun]: dateTime.weekday === 7,
  });

  return (
    <li className={itemClasses}>
      <div className={headerClasses}>
        {dateTime.day}
        <span className={css.MerchantCalendarBody__Weekday}>
          {intl.formatMessage(
            { id: 'merchants.services.weekday' },
            { weekday },
          )}
          {closingDay && includes(closingDay, dateTime.weekday) && (
            <span>
              {intl.formatMessage({ id: 'merchants.services.closed' })}
            </span>
          )}
        </span>
      </div>
      <MerchantCalendarBodyItemCard
        merchantPublicId={merchantPublicId}
        timeZone={timeZone}
        isList={isList}
        serviceList={services[mapServiceKey]}
      />
    </li>
  );
};

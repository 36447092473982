import React from 'react';
import css from './index.module.scss';
import SiteFooter from './SiteFooter';

const MerchantGlobalFooter: React.FC<React.HTMLProps<{}>> = () => {
  return (
    <footer className={css.MerchantGlobalFooter}>
      <SiteFooter />
    </footer>
  );
};

export default MerchantGlobalFooter;

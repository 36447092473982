import { Action, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import reducers, { ReduxState } from './reducers';

export const makeStore = (initialState?: Partial<ReduxState>) => {
  return configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV === 'development',
    preloadedState: initialState,
  });
};

const store = makeStore();
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch<T> = ThunkDispatch<T, undefined, Action>;
export const useAppDispatch: <T>() => AppDispatch<T> = useDispatch;

export default store;

import { formatToTimeZone } from 'date-fns-timezone';
import * as I from '../../../interfaces/merchants/services';
import * as T from '../../../redux/types/merchants/services';
import { DateTime, Interval, Zone } from 'luxon';

export const DISPLAY_DATES = 4;
export const DEFAULT_TZ = 'Asia/Tokyo';

// Data Convertor
export const convertApiResponseToApplicationInterface = (
  service: T.Service,
): I.Service => ({
  publicId: service.public_id,
  allDay: service.all_day,
  bookingUrl: service.booking_url,
  capacity: service.capacity,
  color: service.color,
  digest: service.digest,
  endAt: new Date(service.end * 1000),
  modelType: service.model_type,
  reservable: service.reservable,
  startAt: new Date(service.start * 1000),
  submit: service.submit,
  title: service.title,
  vacancy: service.vacancy,
  waitingListProvided: service.waiting_list_provided,
  full: service.full,
  isRegisteredWaitingList: service.is_registered_waiting_list,
});

const alignedOnBaseDate = (baseDate: DateTime, dates: number): DateTime => {
  const lastDate = baseDate.endOf('month').startOf('day');
  const countUntilLastDate = lastDate.diff(baseDate, 'day').days;
  const containableDaysInLastWeek = countUntilLastDate % dates;
  return lastDate.minus({ day: containableDaysInLastWeek });
};

export const buildCalendarDate = (service: T.Service, meta?: I.Meta): string =>
  formatToTimeZone(
    // unixtimeに変換
    service.start * 1000,
    'YYYYMMDD',
    { timeZone: meta && meta.timeZone ? meta.timeZone : DEFAULT_TZ },
  );

export const convertApiMetaInfoToApplicationInterface = (
  meta: T.Meta,
): I.Meta => {
  return {
    closingDay: meta.business_hours
      .filter((b) => !b.is_open)
      .map((b) => (b.weekday !== 0 ? b.weekday : 7)), // luxonのweekに合わせる 日曜日は luxonだと7, coubic-railsでは0
    timeZone: meta.time_zone,
  };
};

// Date Logics
export const dateTimeFromISO = (isostr: string): DateTime =>
  DateTime.fromISO(isostr, { setZone: true });

export const dateTimeFromFormat = (
  text: string,
  format: string,
  tz: Zone,
): DateTime => DateTime.fromFormat(text, format, { zone: tz });

export const today = (timeZone: string): DateTime => {
  const now = DateTime.utc().setZone(timeZone);
  return now.startOf('day');
};

export const yesterday = (timeZone: string): DateTime =>
  today(timeZone).minus({ day: 1 });

export const dateStringToZonedTime = (date: string, tz: string): DateTime => {
  return DateTime.fromISO(date).setZone(tz);
};

export const nextStartOfMonth = (baseDate: string): DateTime =>
  dateTimeFromISO(baseDate).plus({ month: 1 }).startOf('month');

export const prevStartOfMonth = (baseDateStr: string): DateTime => {
  const baseDate = dateTimeFromISO(baseDateStr);
  const _today = today(baseDate.zone.name);
  const newStart = baseDate.minus({ month: 1 });
  // 今日より以前のデータは必要ないので
  return newStart < _today ? _today : newStart;
};

export const nextBaseDate = (
  baseDate: string,
  dates: number = DISPLAY_DATES,
): DateTime => {
  const origin = dateTimeFromISO(baseDate);
  const next = origin.plus({ day: dates }).startOf('day');

  return next.hasSame(origin, 'month') ? next : next.startOf('month');
};

export const hasPrevDate = (baseDateStr: string): boolean => {
  const baseDate = dateTimeFromISO(baseDateStr);
  const dates = 1;
  const _today = today(baseDate.zone.name);
  const prev = baseDate.minus({ day: dates });

  return prev >= _today;
};

export const prevBaseDate = (
  baseDateStr: string,
  dates: number = DISPLAY_DATES,
): DateTime => {
  const baseDate = dateTimeFromISO(baseDateStr);
  const prev = baseDate.minus({ day: dates });
  // 月をまたぐ移動の時は今日を基準として`dates`日の区切りとなる日付にする
  // ex) datesが4日、移動後の月が12月（31日が末日）、今日が22日の場合。
  //     本来であれば[28,29,30,31]なので28を返すが、
  //     [22,23,24,25],[26,27,28,29],[30,31,x,x,]としたいので30を返す
  const _today = today(baseDate.zone.name);

  if (prev < _today) {
    return _today;
  }
  if (baseDate.hasSame(prev, 'month')) {
    return prev;
  }

  return _today.hasSame(prev, 'month')
    ? alignedOnBaseDate(_today, dates)
    : alignedOnBaseDate(prev.startOf('month'), dates);
};

export const stopFetch = (
  baseDate: string,
  nextDate: DateTime,
  fetchedUntil: string,
): boolean => {
  const pBaseDate = dateTimeFromISO(baseDate);
  const pFetchedUntil = dateTimeFromISO(fetchedUntil);
  return !pBaseDate.hasSame(nextDate, 'month') || pBaseDate > pFetchedUntil;
};

export const nextFetchStartDate = (fetchedUntil: string): DateTime => {
  return dateTimeFromISO(fetchedUntil).plus({ day: 1 }).startOf('day');
};

export const nextFetchEndDate = (baseDateStr: string): DateTime => {
  const baseDate = dateTimeFromISO(baseDateStr);

  const endDate = baseDate.plus({ day: DISPLAY_DATES - 1 }).endOf('day');

  return baseDate.hasSame(endDate, 'month') ? endDate : baseDate.endOf('month');
};

// get end date of fetch range for Fullcalendar
export const nextFetchEndDateFC = (date: DateTime): DateTime => {
  return date.minus({ day: 1 }).endOf('day');
};

export const doneLoading = (
  baseDateStr: string,
  fetchedUntilStr?: string,
): boolean => {
  if (!fetchedUntilStr) {
    return false;
  }
  const endOfMonth = dateTimeFromISO(baseDateStr).endOf('month');
  const fetchedUntil = dateTimeFromISO(fetchedUntilStr);
  return fetchedUntil >= endOfMonth;
};

export const eachDay = (date: DateTime, count: number): DateTime[] => {
  return Interval.fromDateTimes(
    date.startOf('day'),
    date.startOf('day').plus({ day: count }).endOf('day'),
  )
    .splitBy({ day: 1 })
    .map((d) => d.start);
};

export const eachDayBetween = (
  startDate: DateTime,
  endDate: DateTime,
): DateTime[] => {
  return Interval.fromDateTimes(startDate.startOf('day'), endDate.endOf('day'))
    .splitBy({ day: 1 })
    .map((d) => d.start);
};

import React from 'react';
import css from './index.module.scss';
import SiteFooter from './SiteFooter';

const GlobalFooter: React.FC<React.HTMLProps<{}>> = () => {
  return (
    <footer className={css.GlobalFooter}>
      <SiteFooter />
    </footer>
  );
};

export default GlobalFooter;

import React, { FC, ReactNode } from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';

import { useIsMobile } from '../../hooks/helpers/useIsMobile';
import { useReactModalAnimationWorkaround } from '../../hooks/helpers/useReactModalAnimationWorkaround';

import {
  RSDetailHeader,
  RSDetailHeaderProps,
} from '../../detail-header/RSDetailHeader';
import { RSDetailHeaderButton } from '../../detail-header/RSDetailHeaderButton';

import styles from './index.module.css';

export interface RSModalProps {
  title?: RSDetailHeaderProps['title'];
  isOpen: boolean;
  closeText?: string;
  onRequireClosing: () => void;
  rightButton?: RSDetailHeaderProps['rightButton'];
  isDetailHeaderNoBorder?: boolean;
  children?: ReactNode;
}

export const RSModal: FC<RSModalProps> = ({
  title,
  isOpen,
  closeText,
  onRequireClosing,
  rightButton,
  isDetailHeaderNoBorder,
  children,
}) => {
  const isMobile = useIsMobile();
  const { afterOpenModal, afterClose, wasOpen } =
    useReactModalAnimationWorkaround(false);

  return (
    <ReactModal
      appElement={
        typeof document !== 'undefined' &&
        document.getElementById('__next') !== null
          ? document.getElementById('__next')!
          : undefined
      }
      className={{
        base: classnames(
          isMobile ? 'w-full' : 'max-w-[560px]',
          isMobile ? 'rounded-t-20' : 'rounded-15',
          isMobile ? 'mt-2' : 'mt-[80px]',
          isMobile && 'bottom-0',
          !isMobile && 'mx-auto',
          'bg-white',
          'overflow-hidden',
          isMobile ? styles.spContentBase : styles.contentBase,
        ),
        afterOpen: classnames(
          wasOpen && styles.contentBaseAfterOpen,
          isMobile && styles.spContentAfterOpen,
        ),
        beforeClose: classnames(
          styles.contentBaseBeforeClose,
          isMobile && styles.spContentBeforeClose,
        ),
      }}
      overlayClassName={{
        base: classnames(
          'fixed',
          'z-8',
          !isMobile && 'px-4',
          styles.overlayBase,
        ),
        afterOpen: classnames(wasOpen && styles.overlayBaseAfterOpen),
        beforeClose: styles.overlayBaseBeforeClose,
      }}
      closeTimeoutMS={150}
      onRequestClose={onRequireClosing}
      onAfterOpen={afterOpenModal}
      onAfterClose={afterClose}
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      bodyOpenClassName="overflow-hidden"
    >
      <RSDetailHeader
        title={title}
        leftButton={
          closeText && (
            <RSDetailHeaderButton variant="close" onClick={onRequireClosing}>
              {closeText}
            </RSDetailHeaderButton>
          )
        }
        rightButton={rightButton}
        isNoBorder={isDetailHeaderNoBorder}
      />
      <div
        className={classnames(
          'overflow-y-auto',
          isMobile
            ? styles.mobileHeightRestriction
            : styles.pcHeightRestriction,
        )}
      >
        {children}
      </div>
    </ReactModal>
  );
};

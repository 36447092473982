import React, { FC } from 'react';
import css from './style.module.scss';
import GlobalNav from '../../components/layouts/GlobalNav/GlobalNav';
import MerchantGlobalFooter from '../../components/layouts/MerchantGlobalFooter';

// @svgr/webpackを使用してsvgをReactコンポーネントとして読み込みたいが、現状raw-loaderでdangerouslySetInnerHTMLしていて影響範囲が大きいのでベタがきしている
const Icon = () => {
  return (
    <svg
      width="216"
      height="157"
      viewBox="0 0 216 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M170.5 105.9C158.6 105.9 148.7 102.4 141.4 95.8001C133.8 97.4001 125.6 97.9001 117.6 96.7001C112.2 99.2001 105.7 101.1 97.9999 102H97.8999C89.2999 103 73.4999 104.8 60.0999 99.5001C56.8999 100.9 53.5999 102 50.0999 102.8C38.4999 105.4 26.9999 103.6 17.8999 97.8001C0.299942 86.4001 -3.20006 62.3001 2.49994 44.4001C4.99994 36.5001 13.4999 32.2001 21.3999 34.7001C29.2999 37.2001 33.5999 45.7001 31.0999 53.6001C28.6999 61.1001 30.0999 70.0001 34.1999 72.6001C35.5999 73.5001 37.1999 73.9001 38.6999 74.0001C34.0999 53.8001 44.1999 30.1001 51.4999 18.4001C54.6999 13.3001 66.2999 -3.09987 81.0999 1.30013C84.4999 2.30013 91.5999 5.60013 93.5999 15.9001C98.3999 9.60013 106.2 2.00013 116.4 0.300129C125.9 -1.19987 134.8 2.90013 140.2 11.5001C140.9 12.6001 141.5 13.8001 142.1 15.0001C143 13.9001 143.9 12.8001 144.8 11.8001C156.8 -0.799871 168.2 1.70013 172.5 3.40013C182.1 7.10013 187.8 17.2001 188.2 30.9001C188.6 45.8001 184.1 62.9001 174.8 75.6001C175.4 75.5001 176 75.4001 176.6 75.3001C181.5 74.5001 186.8 71.7001 189.8 68.4001C195.4 62.3001 204.8 61.8001 211 67.3001C217.1 72.9001 217.6 82.3001 212.1 88.5001C204.5 96.9001 193.1 103 181.5 104.9C177.7 105.6 174 105.9 170.5 105.9ZM116.6 35.2001C113.5 39.5001 110.3 45.9001 109.5 52.7001C108.7 59.8001 111.1 62.2001 112.2 63.1001C113.1 61.5001 113.7 59.6001 114.2 57.7001C116.2 48.6001 116.8 40.7001 116.6 35.2001Z"
        fill="#EAF0F6"
      />
      <path
        d="M159.6 156.8H56.3998C52.4998 156.8 49.2998 153.6 49.2998 149.7V148.6C49.2998 144.7 52.4998 141.5 56.3998 141.5H159.5C163.4 141.5 166.6 144.7 166.6 148.6V149.7C166.6 153.6 163.5 156.8 159.6 156.8Z"
        fill="#4F667D"
      />
      <path
        d="M100.3 24.1L66 141.6H150.2L115.9 24.1C113.6 16.3 102.6 16.3 100.3 24.1Z"
        fill="#4F667D"
      />
      <mask
        id="mask0_3521_21455"
        maskUnits="userSpaceOnUse"
        x="66"
        y="18"
        width="85"
        height="124"
      >
        <path
          d="M100.3 24.1L66 141.6H150.2L115.9 24.1C113.6 16.3 102.6 16.3 100.3 24.1Z"
          fill="#4F667D"
        />
      </mask>
      <g mask="url(#mask0_3521_21455)">
        <path
          d="M171.4 36.1998L50.7998 67.9998V50.0998L171.4 18.2998V36.1998Z"
          fill="#C2D1E0"
        />
        <path
          d="M171.4 71.6998L50.7998 103.5V85.5998L171.4 53.7998V71.6998Z"
          fill="#C2D1E0"
        />
        <path
          d="M171.4 107.3L50.7998 139V121.1L171.4 89.2998V107.3Z"
          fill="#C2D1E0"
        />
      </g>
      <path d="M75.3998 141.6H140.6" stroke="#002E4E" />
    </svg>
  );
};

type Props = {
  isCoubicApp: boolean;
  logoImage: { url: string } | null;
  merchantPublicId: string | null;
  displayName: string | null;
};

export const PreparingMerchantSite: FC<Props> = ({
  isCoubicApp,
  logoImage,
  merchantPublicId,
  displayName,
}) => {
  return (
    <div className={css.base_layout}>
      {!isCoubicApp && (
        <GlobalNav
          logoImage={logoImage}
          merchantPublicId={merchantPublicId}
          displayName={displayName}
        />
      )}
      <div className={css.container}>
        <div className={css.icon}>
          <Icon />
        </div>
        <div className={css.preparing_text}>予約サイトは準備中です</div>
      </div>
      {!isCoubicApp && (
        <div className={css.footer}>
          <MerchantGlobalFooter />
        </div>
      )}
    </div>
  );
};

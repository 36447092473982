import { BookingPageTagName } from '../../../../domain/entities/BookingPage';
import * as ICustomer from '../../../customer';
import {
  MerchantBookingPageApiResponse,
  MerchantBookingPrimaryImage,
} from '../../bookings';
import { FirstCharge } from '../firstCharge';
import { Order } from '../orders';

interface ProductImage {
  thumb?: {
    url: string;
  };
  small?: {
    url: string;
  };
  medium?: {
    url: string;
  };
}

export interface MerchantProductData {
  public_id: string;
  scheme: number;
  name: string;
  description?: string;
  price: number;
  image: ProductImage;
  wait_first_anchor: boolean;
  ticket_count?: number;
  expiration?: number;
  booking_pages: MerchantBookingPageApiResponse[];
  available_months_period?: number;
}

export interface BookingPage {
  publicId: string;
  name: string;
  tagNames: BookingPageTagName[];
  status: string;
  prepaymentEnabled: boolean;
  onsitePaymentEnabled: boolean;
  waitingListProvided: boolean;
  descriptionText?: string;
  primaryImage?: MerchantBookingPrimaryImage;
}

export enum EntireErrorType {
  Unexpected = 'unexpected',
  CustomerFailed = 'customerFailed',
  PaymentFailed = 'paymentFailed',
  JCBBrandDisabled = 'jcbBrandDisabled',
}

export interface SettingCompletion {
  bookingPages: BookingPage[];
  reservationCount: ReservationCount;
  customerPublicId?: string;
  verifiedUser?: boolean;
  expiryDate?: string;
  entireErrors?: EntireErrorType[];
}

export interface ReservationCount {
  upcoming?: number;
  perMonth?: number;
  perWeek?: number;
  perDay?: number;
}

export interface SettingConfirmation {
  reservationCount: ReservationCount;
  expiryDate?: string;
}

export enum PaymentSelection {
  UsedCreditCard = 'used_credit_card',
  AnotherCreditCard = 'another_credit_card',
}

export type CreditCardError =
  | { type: 'invalid_number'; field: 'number' }
  | { type: 'invalid_expiry_month'; field: 'month' }
  | { type: 'invalid_expiry_year'; field: 'year' }
  | { type: 'invalid_cvc'; field: 'cvc' }
  | { type: 'incorrect_number'; field: 'number' }
  | { type: 'expired_card'; field: 'exp' }
  | { type: 'incorrect_cvc'; field: 'cvc' }
  | { type: 'card_declined'; field: 'card' }
  | { type: 'missing'; field: 'card' }
  | { type: 'processing_error'; field: 'card' }
  | { type: 'script_load_failed'; field: 'card' };

export interface SettingPayment {
  errors?: { creditCard: CreditCardError };
}

enum ErrorType {
  Blank = 'blank',
  Invalid = 'invalid',
}

export interface CustomerField {
  id: number;
  inputType: string;
  validationType: string;
  label: string;
  valueOptions?: string[];
  description: string;
  required: boolean;
  defaultValue?: string;
  errors?: ErrorType[];
}

export interface SettingCustomer {
  customerFields?: CustomerField[];
}

export enum SchemeType {
  Subscription = 'subscription',
  TicketBook = 'ticket_book',
}

export type Settings = SettingsSubscription | SettingsTicketBook;

export interface SettingsSubscription {
  schemeType: SchemeType.Subscription;
  customer: SettingCustomer;
  payment?: SettingPayment;
  confirmation: SettingConfirmation;
  completion: SettingCompletion;
}

export interface SettingsTicketBook {
  schemeType: SchemeType.TicketBook;
  customer: SettingCustomer;
  payment?: SettingPayment;
  confirmation: SettingConfirmation;
  completion: SettingCompletion;
}

export interface State {
  settings: Settings | null;
  step: number;
  postable: boolean;
  publicId: string;
  productId: string;
  merchantProduct: MerchantProduct | null;
  renew?: boolean;
  jcbEnabled: boolean;
  firstCharge: FirstCharge | null;
  lastOrder: Order | null;
  availableMonthsPeriod?: number;
}

export interface MerchantProduct {
  publicId: string;
  name: string;
  schemeType: string;
  price: number;
  image: ProductImage;
  description?: string;
  ticketCount?: number;
  waitFirstAnchor: boolean;
  expiration?: number;
  availableMonthsPeriod?: number;
}

interface UseAnotherCard {
  method: 'credit_card';
  restore_credit_card: false;
  ctok?: string;
}

interface UseUsedCard {
  method: 'credit_card';
  restore_credit_card: true;
}

interface UseTotallyNew {
  method: 'credit_card';
  ctok?: string;
}

export type PurchasingRequestPaymentMethod =
  | UseAnotherCard
  | UseUsedCard
  | UseTotallyNew;

export interface PurchasingRequestCustomerCustomFieldsAttribute {
  binder_id: string;
  value: string;
}

export interface PurchasingRequestCustomerCustomFields {
  customer_custom_field_responses_attributes: PurchasingRequestCustomerCustomFieldsAttribute[];
}

export interface PurchasingRequestInput {
  customer_custom_fields?: PurchasingRequestCustomerCustomFields;
  payment_method?: PurchasingRequestPaymentMethod;
}

export interface PurchasingRequestData {
  product_id: string;
  input: PurchasingRequestInput;
}

export interface PurchasingCustomerCustomFieldsError {
  [id: string]: ErrorType[];
}

export enum PurchasingPaymentMethodError {
  StripeErrorOccurred = 'stripe_error_occurred',
}

export interface PurchasingErrors {
  customer_custom_fields: PurchasingCustomerCustomFieldsError;
  payment_method: PurchasingPaymentMethodError;
  jcb_brand_disabled: boolean;
}

export interface PurchasingResponseData {
  success: boolean;
  customer?: ICustomer.CustomerResponse;
  last_order?: Order;
  verified_user?: boolean;
  errors?: PurchasingErrors;
}

import React, {
  DetailedHTMLProps,
  ButtonHTMLAttributes,
  forwardRef,
  useMemo,
} from 'react';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import classnames from 'classnames';
import SpinnerIcon from '@heyinc/stand-ui-icons/svg/spinner.svg';

import styles from './index.module.css';

export type RSButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  variant?: 'large' | 'basic' | 'outline' | 'destructive';
  isBlock?: boolean;
  isLoading?: boolean;
};

export const RSButton = forwardRef<HTMLButtonElement, RSButtonProps>(
  (
    {
      variant = 'basic',
      isBlock = false,
      isLoading = false,
      children,
      ...props
    },
    ref,
  ) => {
    const { disabled } = props;
    const { isFocusVisible, focusProps } = useFocusRing();

    const color = '#0066FF';

    const wrapperHeightClasses = useMemo(() => {
      switch (variant) {
        case 'large':
          return ['h-56'];
        case 'basic':
        case 'outline':
        case 'destructive':
          return ['h-48', 'pc:h-44'];
      }
    }, [variant]);

    const buttonSizeClasses = useMemo(() => {
      switch (variant) {
        case 'large':
          return ['px-[20px]'];
        case 'basic':
        case 'outline':
        case 'destructive':
          return ['px-2'];
      }
    }, [variant]);

    const buttonColorClasses = useMemo(() => {
      switch (variant) {
        case 'large':
          return [
            (disabled || isLoading) && 'bg-gray-10',
            !disabled && !isLoading ? 'text-white' : 'text-gray-200',
            (disabled || isLoading) && 'border-gray-10',
          ];
        case 'basic':
          return [
            (disabled || isLoading) && 'bg-gray-10',
            !disabled && !isLoading ? 'text-white' : 'text-gray-200',
            (disabled || isLoading) && 'border-gray-10',
          ];
        case 'outline':
          return [
            !disabled && !isLoading ? 'bg-white' : 'bg-gray-10',
            (disabled || isLoading) && 'text-gray-200',
            !disabled && !isLoading ? 'border-gray-200' : 'border-gray-10',
          ];
        case 'destructive':
          return [
            !disabled && !isLoading ? 'bg-white' : 'bg-gray-10',
            !disabled && !isLoading ? 'text-red-700' : 'text-gray-200',
            !disabled && !isLoading ? 'border-gray-200' : 'border-gray-10',
          ];
      }
    }, [variant, disabled, isLoading]);

    const isButtonBackgroundColorPrimary = useMemo(() => {
      if (disabled || isLoading) {
        return false;
      }

      if (variant !== 'large' && variant !== 'basic') {
        return false;
      }

      return true;
    }, [variant, disabled, isLoading]);

    const isButtonTextColorPrimary = useMemo(() => {
      if (disabled || isLoading) {
        return false;
      }

      if (variant !== 'outline') {
        return false;
      }

      return true;
    }, [variant, disabled, isLoading]);

    const isButtonBorderColorPrimary = useMemo(() => {
      if (disabled || isLoading) {
        return false;
      }

      if (variant !== 'large' && variant !== 'basic') {
        return false;
      }

      return true;
    }, [variant, disabled, isLoading]);

    return (
      <>
        <div
          className={classnames(
            'wrapper',
            isFocusVisible && 'focus-visible',
            !isBlock ? 'inline-block' : ['block', 'w-full'],
            'relative',
            wrapperHeightClasses,
            'rounded-15',
            'after:transition',
          )}
        >
          <button
            {...mergeProps(props, focusProps)}
            ref={ref}
            className={classnames(
              'button',
              isButtonBackgroundColorPrimary && 'bg-primary',
              isButtonTextColorPrimary && 'text-primary',
              isButtonBorderColorPrimary && 'border-primary',
              'relative',
              buttonSizeClasses,
              'w-full',
              'h-full',
              'flex',
              'justify-center',
              'items-center',
              'border',
              'border-solid',
              'rounded-15',
              'outline-none',
              'focus:outline-none',
              buttonColorClasses,
              'z-1',
              'transition',
              !disabled ? 'cursor-pointer' : 'cursor-not-allowed',
              !disabled && !isLoading && styles['button--default'],
            )}
          >
            {isLoading && (
              <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
                <div className={styles['icon-wrapper']}>
                  <SpinnerIcon
                    width="24"
                    height="24"
                    viewBox="0 0 48 48"
                    className="fill-gray-200"
                  />
                </div>
              </div>
            )}
            <div
              className={classnames(
                'rs-font',
                'text-16',
                'pc:text-15',
                'font-bold',
                'whitespace-nowrap',
                isLoading && 'invisible',
              )}
            >
              {children}
            </div>
          </button>
        </div>
        <style jsx>{`
          .wrapper::after {
            content: '';
            box-sizing: border-box;
            position: absolute;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            top: -4px;
            left: -4px;
            border-style: solid;
            border-width: 2px;
            border-color: transparent;
            border-radius: 18px;
          }

          .wrapper.focus-visible::after {
            border-color: ${color};
          }

          .button.bg-primary {
            background-color: ${color};
          }

          .button.text-primary {
            color: ${color};
          }

          .button.border-primary {
            border-color: ${color};
          }
        `}</style>
      </>
    );
  },
);

RSButton.displayName = 'RSButton';

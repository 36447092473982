import classNames from 'classnames';
import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useIntl } from 'react-intl';

import { MerchantDetail } from '../../../../../interfaces/merchants';

import css from './MerchantNav.module.scss';

interface MerchantNavProps {
  merchantDetail: MerchantDetail;
  coupons: { publicId: string; name: string }[];
}

type Props = MerchantNavProps;

const MerchantNav = ({ merchantDetail, coupons }: Props) => {
  const setting = merchantDetail.merchant_setting;
  const router = useRouter();
  const intl = useIntl();

  // router自体injectされずnullで来る場合がある.
  // このコンポーネントを単体テストする際などに不便なので下記はそのワークアラウンド
  const pathname = router ? router.pathname : '';

  const linkClasses = (paths: string[]) =>
    classNames(
      css.MerchantNav__Link,
      paths.includes(pathname) ? css.isActive : '',
    );

  const bookingPageNavVisible = merchantDetail.booking_page_count > 0;
  const isCouponNavVisible = coupons.length > 0;
  const productPageNavVisible = merchantDetail.product_count > 0;
  const postPageNavVisible = merchantDetail.post_count > 0;
  const calendarPageNavVisible =
    !setting.is_explore_only && bookingPageNavVisible;

  // 他のすべてのliが非表示の場合、「詳細」タブだけ見せるのがデザイン的に良くないので、モバイルでのみ非表示にする
  const hideNavOnMobile = !(
    bookingPageNavVisible ||
    productPageNavVisible ||
    postPageNavVisible ||
    calendarPageNavVisible
  );

  const baseClasses = classNames(css.MerchantNav, {
    [css.MerchantNav__HideOnMobile]: hideNavOnMobile,
  });

  return (
    <ul className={baseClasses}>
      <li className={css.MerchantNav__Item}>
        <Link
          scroll={false}
          href={`/${merchantDetail.public_id}#pageContent`}
          prefetch={false}
          className={linkClasses(['/[publicId]'])}
        >
          {intl.formatMessage({ id: 'merchants.nav.details' })}
        </Link>
      </li>
      {bookingPageNavVisible && (
        <li className={css.MerchantNav__Item}>
          <Link
            scroll={false}
            href={`/${merchantDetail.public_id}/booking_pages#pageContent`}
            prefetch={false}
            className={linkClasses([
              '/[publicId]/booking_pages',
              '/[publicId]/[resource_id]',
            ])}
          >
            {intl.formatMessage({ id: 'merchants.nav.bookingPages' })}
          </Link>
        </li>
      )}
      {isCouponNavVisible && (
        <li className={css.MerchantNav__Item}>
          <Link
            scroll={false}
            href={`/${merchantDetail.public_id}/coupons#pageContent`}
            prefetch={false}
            className={linkClasses([
              '/[publicId]/coupons',
              '/[publicId]/coupons/[coupon_id]',
            ])}
          >
            {intl.formatMessage({ id: 'merchants.nav.coupons' })}
          </Link>
        </li>
      )}
      {productPageNavVisible && (
        <li className={css.MerchantNav__Item}>
          <Link
            scroll={false}
            href={`/${merchantDetail.public_id}/products#pageContent`}
            prefetch={false}
            className={linkClasses([
              '/[publicId]/products',
              '/[publicId]/products/[productId]',
            ])}
          >
            {intl.formatMessage({ id: 'merchants.nav.products' })}
          </Link>
        </li>
      )}
      {postPageNavVisible && (
        <li className={css.MerchantNav__Item}>
          <Link
            scroll={false}
            href={`/${merchantDetail.public_id}/blogs#pageContent`}
            prefetch={false}
            className={linkClasses([
              '/[publicId]/blogs',
              '/[publicId]/blogs/[blogId]',
            ])}
          >
            {intl.formatMessage({ id: 'merchants.nav.blogs' })}
          </Link>
        </li>
      )}
      {calendarPageNavVisible && (
        <li className={css.MerchantNav__Item}>
          <Link
            scroll={false}
            href={`/${merchantDetail.public_id}/services#pageContent`}
            prefetch={false}
            className={linkClasses(['/[publicId]/services'])}
          >
            {intl.formatMessage({ id: 'merchants.nav.calendar' })}
          </Link>
        </li>
      )}
    </ul>
  );
};

export default MerchantNav;

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/ja';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/ko';
// tslint:enable: no-submodule-imports
import locales from '../locales/locales.json';

import accept from 'accept-language';

export type coubicLocale = 'ja' | 'en' | 'ko';

const availableLocales = ['ja', 'en', 'ko'];

class I18n {
  public static readonly defaultLocale = 'ja';

  public static messages(l: coubicLocale): { [key: string]: string } {
    const baseLocale = locales['ja-base'];
    const locale = locales[l];
    const messages = {
      ...baseLocale,
      ...locale,
    };
    return messages;
  }

  public static validLocale(locale: string): coubicLocale {
    return this.isAvailableCoubicLocale(locale) ? locale : this.defaultLocale;
  }

  public static isAvailableCoubicLocale(
    locale: string,
  ): locale is coubicLocale {
    return availableLocales.includes(locale);
  }
}

const acceptLanguageHeaderMap = {
  'ja-JP': 'ja',
  ja: 'ja',
  en: 'en',
  ko: 'ko',
  'en-US': 'en',
  'ko-KR': 'ko',
};

export default I18n;

export const detectLocaleByAcceptLanguage = (
  acceptLanguage: string | undefined,
): coubicLocale => {
  // google クローラ対策 (jaページを優先する)
  // https://support.google.com/webmasters/answer/6144055?hl=ja
  // > クローラはリクエスト ヘッダーに Accept-Language を設定せずに HTTP リクエストを送信しています。
  if (!acceptLanguage) {
    return 'ja';
  }

  const ac = accept.create();

  // クービックとして想定している言語 (対応状況に関わらず)
  // 複数設定されていたり、ここに定義されていない場合は先頭のen-USになる
  ac.languages(['en-US', 'ja-JP', 'ja', 'en', 'ko-KR', 'ko', 'zh-CN']);
  const detected = ac.get(acceptLanguage);

  // missing or invalid Accept-Language header
  if (!detected) {
    return 'en';
  }

  // ja-JPなどの形式を coubicのロケール名 (ja, enなど)にして返す
  return acceptLanguageHeaderMap[detected] || 'en';
};

import React, { FC } from 'react';
import MerchantNav from './components/MerchantNav';
import { useMerchantCoupons } from '../../../hooks/api/coupon';
import { MerchantDetail } from '../../../interfaces/merchants';

type Props = {
  merchantDetail: MerchantDetail;
};

export const MerchantNavOrganism: FC<Props> = ({ merchantDetail }) => {
  const { data: coupons } = useMerchantCoupons({
    merchantPublicId: merchantDetail.public_id,
  });
  const couponsData = coupons
    ? coupons.map((coupon) => {
        return { publicId: coupon.public_id, name: coupon.name };
      })
    : [];

  return <MerchantNav merchantDetail={merchantDetail} coupons={couponsData} />;
};

import { coubicLocale } from 'src/utils/i18n';
import { MerchantBusinessHour } from '../../interfaces/merchants';

export type TemporaryBusinessHoursTextArray = {
  date: string;
  hours: Array<string>;
};

export type TemporaryClosingDaysTextArray = {
  startDate: string;
  endDate: string;
  reason: string;
};

class DateTimeUtils {
  public static formatWeekday(dayNumber: number, locale: coubicLocale): string {
    switch (dayNumber) {
      case 0:
        return locale == 'ja' ? '日' : 'Sun';
      case 1:
        return locale == 'ja' ? '月' : 'Mon';
      case 2:
        return locale == 'ja' ? '火' : 'Tue';
      case 3:
        return locale == 'ja' ? '水' : 'Wed';
      case 4:
        return locale == 'ja' ? '木' : 'Thu';
      case 5:
        return locale == 'ja' ? '金' : 'Fri';
      case 6:
        return locale == 'ja' ? '土' : 'Sat';
      default:
        return '';
    }
  }

  public static formatBusinessHours(
    businessHours: MerchantBusinessHour[],
    locale: coubicLocale,
  ): string[] {
    const result: string[] = [];
    const weekdays = DateTimeUtils.getWeekdays(businessHours);
    const monday = weekdays[0];
    if (DateTimeUtils.isWeekdayEverySameTime(weekdays, monday)) {
      // eslint-disable-next-line no-irregular-whitespace
      result.push(
        DateTimeUtils.formatBizHourString(
          locale == 'ja' ? '月〜金' : 'Mon〜Fri',
          monday.opening_hour,
          monday.closing_hour,
        ),
      );
    } else {
      weekdays.forEach((d: MerchantBusinessHour) => {
        if (d.is_open) {
          result.push(DateTimeUtils.formatOpenBusinessHour(d, locale));
        } else {
          result.push(DateTimeUtils.formatCloseBusinessHour(d, locale));
        }
      });
    }

    const weekend = DateTimeUtils.getWeekend(businessHours);
    const sunday = weekend[0];
    if (DateTimeUtils.isWeekendEverySameTime(weekend, sunday)) {
      // eslint-disable-next-line no-irregular-whitespace
      result.push(
        DateTimeUtils.formatBizHourString(
          locale == 'ja' ? '土・日' : 'Sat・Sun',
          sunday.opening_hour,
          sunday.closing_hour,
        ),
      );
    } else {
      weekend.reverse().forEach((d: MerchantBusinessHour) => {
        if (d.is_open) {
          result.push(DateTimeUtils.formatOpenBusinessHour(d, locale));
        } else {
          result.push(DateTimeUtils.formatCloseBusinessHour(d, locale));
        }
      });
    }

    return result;
  }

  public static getWeekdays(businessHours: MerchantBusinessHour[]) {
    return businessHours.filter((v) => v.weekday > 0 && v.weekday < 6);
  }

  public static getWeekend(businessHours: MerchantBusinessHour[]) {
    return businessHours.filter((v) => v.weekday === 0 || v.weekday === 6);
  }

  public static isWeekdayEverySameTime(
    weekdays: MerchantBusinessHour[],
    monday: MerchantBusinessHour,
  ) {
    return weekdays.every(
      (d: MerchantBusinessHour) =>
        d.is_open &&
        monday.opening_hour === d.opening_hour &&
        monday.closing_hour === d.closing_hour,
    );
  }

  public static isWeekendEverySameTime(
    weekdays: MerchantBusinessHour[],
    sunday: MerchantBusinessHour,
  ) {
    return weekdays.every(
      (d: MerchantBusinessHour) =>
        d.is_open &&
        sunday.opening_hour === d.opening_hour &&
        sunday.closing_hour === d.closing_hour,
    );
  }

  public static formatOpenBusinessHour(
    date: MerchantBusinessHour,
    locale: coubicLocale,
  ): string {
    const weekday = DateTimeUtils.formatWeekday(date.weekday, locale);
    const open = date.opening_hour;
    const close = date.closing_hour;
    // eslint-disable-next-line no-irregular-whitespace
    return DateTimeUtils.formatBizHourString(weekday, open, close);
  }

  public static formatCloseBusinessHour(
    date: MerchantBusinessHour,
    locale: coubicLocale,
  ): string {
    const weekday = DateTimeUtils.formatWeekday(date.weekday, locale);
    // eslint-disable-next-line no-irregular-whitespace
    return locale == 'ja' ? `${weekday}　定休日\n` : `${weekday}　Closed\n`;
  }

  private static formatBizHourString(
    week: string,
    open: string,
    close: string,
  ): string {
    return `${week}　${open.substring(0, 5)} - ${close.substring(0, 5)}\n`;
  }
}

export default DateTimeUtils;

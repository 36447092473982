import { IntlShape } from 'react-intl';

export const getDayWithSuffix = (day: number, intl: IntlShape) => {
  const ordinal = intl.formatPlural(day, { type: 'ordinal' });

  const ordinalSuffixes = {
    one: intl.formatMessage({
      defaultMessage: '日',
      id: 'SRzCeu',
      description: 'src/utils/intl.ts one',
    }),
    two: intl.formatMessage({
      defaultMessage: '日',
      id: 'C/rj/P',
      description: 'src/utils/intl.ts two',
    }),
    few: intl.formatMessage({
      defaultMessage: '日',
      id: 'BVOFon',
      description: 'src/utils/intl.ts few',
    }),
    other: intl.formatMessage({
      defaultMessage: '日',
      id: 'J3E7R4',
      description: 'src/utils/intl.ts other',
    }),
  };
  const suffix = ordinalSuffixes[ordinal];
  return `${day}${suffix}`;
};

import { GetServerSidePropsContext } from 'next';
import { newSecuredAxios } from '../utils/secured-axios';
import { Client, QueryParameters } from './generated/apiClient';
import { Schemas } from './generated/types';
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import getConfig from 'next/config';
import nookies from 'nookies';

const { publicRuntimeConfig } = getConfig();

/**
 * @deprecated 基本的に`useClientSideApiClient`を使用し、hooksが利用できない場合に限り`getClientSideApiClient`を使用してください。
 */
export const getClientSideApiClient = () => {
  return getApiClient();
};

export const useClientSideApiClient = () => {
  return getApiClient();
};

export const getServerSideApiClient = (
  ctx: GetServerSidePropsContext & { requestId?: string },
) => {
  const { cb, token } = nookies.get(ctx);

  return getApiClient({
    requestId: ctx.requestId ?? '',
    session: cb ?? '',
    token: token ?? '',
    merchantPublicId: ctx.query.publicId as string,
  });
};

const getApiClient = (securedAxiosParams?: {
  session?: string;
  token?: string;
  requestId?: string;
  merchantPublicId?: string;
}) => {
  const { apiUrl, apiExternalUrl } = publicRuntimeConfig;
  const origin = typeof window !== 'undefined' ? apiExternalUrl : apiUrl;

  return new Client<
    AxiosRequestConfig & {
      onRawResponse?: (rawResponse: AxiosResponse) => void;
    }
  >(
    {
      request: async (
        { httpMethod, url, headers, requestBody, queryParameters },
        options,
      ) => {
        if (httpMethod === 'TRACE') throw new Error('can not use TRACE method');

        const axiosClient = newSecuredAxios(securedAxiosParams ?? {});
        const res = await axiosClient.request({
          method: httpMethod,
          url,
          headers,
          data: requestBody,
          params: getAxiosParams(queryParameters),
          ...options,
        });
        options?.onRawResponse?.(res);
        return res.data;
      },
    },
    origin,
  );
};

export const getAxiosParams: (
  queryParameters: QueryParameters | undefined,
) => { [key: string]: unknown } | undefined = (queryParameters) => {
  if (!queryParameters) {
    return undefined;
  }

  const paramsArray = Object.entries(queryParameters).filter(
    ([, value]) => typeof value !== 'undefined',
  );

  if (paramsArray.length === 0) {
    return undefined;
  }

  return paramsArray.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value.value,
    };
  }, {});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAxiosError(error: any): error is AxiosError {
  return error.isAxiosError;
}

// AxiosErrorのresponse.bodyの形式がAPIErrorになっているかを確認する
export function isAPIError(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorResponseBody: any,
): errorResponseBody is Schemas.APIError {
  return (
    typeof errorResponseBody === 'object' &&
    errorResponseBody !== null &&
    typeof errorResponseBody.code === 'string' &&
    typeof errorResponseBody.message === 'string' &&
    Array.isArray(errorResponseBody.details) &&
    typeof errorResponseBody.error === 'object'
  );
}

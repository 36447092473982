import * as React from 'react';
import User from '../../../domain/entities/User';
import css from './UserMenu.module.scss';

interface Props extends React.HTMLProps<{}> {
  isActive: boolean;
  user: User;
  toggle: (flag: boolean) => void;
  logout: () => void;
}

const UserMenu = (props: Props) => {
  const { user, isActive, toggle, logout } = props;
  return (
    <div className={css.UserMenu}>
      <a
        href=""
        className={css.UserMenu__IconLink}
        onClick={(e) => {
          e.preventDefault();
          toggle(!isActive);
        }}
      >
        <img
          src={user.thumbnailUrl.c120x120}
          alt="user-thumbnail"
          className={css.UserMenu__Icon}
        />
      </a>
      {isActive && (
        <ul className={css.UserMenu__DropMenu}>
          <li className={css.UserMenu__DropMenuListItem}>
            <a href="/_" className={css.UserMenu__DropMenuProfile}>
              <img
                src={user.thumbnailUrl.c120x120}
                alt="user-thumbnail"
                className={css.UserMenu__Icon}
              />
              <span className={css.UserMenu__DropMenuProfileName}>
                {user.displayName}
              </span>
            </a>
          </li>
          <li className={css.UserMenu__DropMenuListItem}>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                toggle(!isActive);
                logout();
              }}
              className={css.UserMenu__DropMenuItem}
            >
              ログアウト
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default UserMenu;

import { Action as _Action } from 'redux';
import { UserMeReduxState, UserReduxState } from '../../../interfaces/users';

export enum Types {
  Me = 'users/me',
}

export interface MeAction extends _Action {
  type: Types.Me;
  payload: UserMeReduxState;
}

export type Actions = MeAction;

const INITIAL_STATE = {
  me: {
    displayName: '',
    email: '',
    firstname: '',
    id: 0,
    isEmailVerified: false,
    lastname: '',
    publicId: '',
  },
};

export default (state: UserReduxState = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case Types.Me: {
      return { me: { ...action.payload } };
    }
    default:
      return state;
  }
};
